import React, {Component} from 'react'
import {Link} from 'react-router-dom'
import {connect} from 'react-redux'
import axios from 'axios'
import {HomeHelper} from "./HomeHelper"
import {getNewsItems} from "./NewsItems";
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Pagination from 'react-bootstrap/Pagination'
import Dropdown from 'react-bootstrap/Dropdown'
import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'
import concert from '../concert.jpg'
import guitarcloseup from '../guitar-closeup.jpg'
import guitarcloseup_bw from '../guitar-closeup-bw.jpg'
import guitars_bw from '../guitars-bw.jpg'
import newspapers from '../newspapers.jpg'
import the_beatles from '../the-beatles.jpg'
import waxahatcheesaintcloud from '../waxahatcheesaintcloud.jpg'
import '../index.css'
import BalanceText from 'react-balance-text'


class WaxahatcheeSaintCloud extends Component {
  constructor() {
    super()
  }

  render() {
    const card_style = {
      // width: '18em',
      display: 'inline-block',
      margin: '1em',
    };

    const card_style_double = {
      // width: '38em',
      display: 'inline-block',
      margin: '1em',
    };

    const img_style = {
      height: '4em',
    };

    const img_style_double = {
      /* height: '8em', */
    };

    const home_button = {
      backgroundColor: '#555555',
    };

    const align_left = {
      textAlign: 'left',
    }

    const review_sub_title = {
      color: '#555555',
      fontWeight: 'bold',
      fontSize: '1em',
      margin: '15px 0px 3px 0px',
    }

    return (
          <Col sm={6}>
            <Row>
              <Col sm={12}>
                  <Card style={card_style_double}>
                    <Card.Img variant="top" src={waxahatcheesaintcloud} alt="Waxahatchee Saint Cloud album cover" style={img_style_double}/>
                    <Card.Body style={align_left}>
                      <Card.Title style={align_left}>Review: <i>Saint Cloud</i> by Waxahatchee</Card.Title>
                      <Card.Text>
                        <BalanceText style={align_left}><b>Overall:</b>  She says she is influenced by Lucinda Williams and it shows but I hear other elements as well. This is on the whole a country album, but there are aspects that remind me of bands from the 90s. I feel like if Tanya Donnelly were from the South she may make an album like this.</BalanceText>

                        <div style={review_sub_title}>Oxbow</div>
                        <div>
                          <BalanceText style={align_left}>
                            <p>The opening sounds and drumbeat sound like how an Elbow song would begin. When she sings it seems like she could be an early 90s band from Boston - not Throwing Muses but she would fit in with that scene with this song. I also hear her Southern twang a little which gives it a hint of country.</p>
                            <p>If this song is any indication this will be a tough album to figure out meanings of lyrics. I'll say "Bama in white" represents her innocent youth in Alabama. "Married the night" means she lost some innocence - she didn't like how much she drank before becoming sober. "What dreams become concrete, they may feel trite" is a concise way of saying that achieving a dream may lower its value. An unachieved goal does not get the "speck on the oxbow" of dirt that reality applies. Perhaps a dream of romance can be devalued as well when she achieves it: "If I go along with it, am I lying to you?"</p>
                          </BalanceText>
                        </div>


                        <div style={review_sub_title}>Can't Do Much</div>
                        <div>
                          <BalanceText style={align_left}>
                            <p>Here comes her Lucinda Williams influence in the guitar sound starting this song. This could be a Jayhawks song the way she sings.</p>
                            <p>She is not just going along with it like on Oxbow. She is deep into it. "My mind turns to something useless and trite" - wait, there's that word trite again but it has a different meaning this time. In the first song she achieved a romance and it became unimportant - this time she's the one in love and she herself has become unimportant - quite the opposite of the first song. It's a helpless feeling to give someone your heart: "Love you that much anyhow can't do much about it now."</p>
                          </BalanceText>
                        </div>


                        <div style={review_sub_title}>Fire</div>
                        <div>
                          <BalanceText style={align_left}>
                            <p>A high falsetto comes out on this one over some quiet electric keyboards. She sounds downright country when her voice comes down after the falsetto.</p>
                            <p>She wrote this while driving with her boyfriend past Memphis. Now she's back on top: "Give me something it ain't enough...I take it for granted." Something "on fire in the light of day" is not as bright - it's light out anyway. Maybe any kindness her boyfriend shows doesn't matter because she already has him. If he takes it seriously (by crying) should she respect it like it's a good thing? I don't think so: "It's not as if we cry a river, call it rain."</p>
                          </BalanceText>
                        </div>


                        <div style={review_sub_title}>Lilacs</div>
                        <div>
                          <BalanceText style={align_left}>
                            <p>She sings like she could be on the Highwomen album that came out last year. The guitar and drums sound like something Richard Ashcroft would do.</p>
                            <p>Now she is back to being the needy one. She's taking words he says very seriously and works her way through the day and maybe thinks of why she is right to be hurt and what a good response would be: "I run it like a silent movie." Being sweet and vulnerable ("my bones are made of delicate sugar") is not a position of power. Lilacs need water to live just like "I need your love too." </p>
                          </BalanceText>
                        </div>


                        <div style={review_sub_title}>The Eye</div>
                        <div>
                          <BalanceText style={align_left}>
                            <p>When I hear a Southern voice on top of Velvet Underground style music I think early REM covers. The music definitely would get Lou Reed's approval.</p>
                            <p>Love is powerful in the early stages - we are "powerless against the calling of the eye." Getting or giving the eye is the earliest stage of a romance. When you're in that stage your "feet don't ever touch the ground." But will it last? "Where love will land nobody knows." It's great now but she's realistic when she asks "will I beg you to set me free?"</p>
                          </BalanceText>
                        </div>


                        <div style={review_sub_title}>Hell</div>
                        <div>
                          <BalanceText style={align_left}>
                            <p>She said she is greatly influenced by Lucinda Williams and it shows here both in singing and the music. It is also like Highwomen.</p>
                            <p>"He wants to have it all and who am I?" This isn't just emotions she's talking about - she's talking about a "flowery demise." She may use sex as a weapon - the guy clearly wants it - "he's a fire burning fast and so bright." Is she going to take advantage of this? "I'll put you through hell." </p>
                          </BalanceText>
                        </div>


                        <div style={review_sub_title}>Witches</div>
                        <div>
                          <BalanceText style={align_left}>
                            <p>She can remind me of Sam Phillips at times in this song. When she raises her voice at the end of some lines it's like "Laid" by James.</p>
                            <p>She goes from "Hell" to "Witches" - things may not be talking kindly about how women treat men: "If you wanna buy a round we might hang out give us all something to talk about." A woman who loves you like no other and is always fair weather is a "myth." Marlee doesn't want any big changes - she may be unavailable - "there's nothing here to gain." Although Lindsey has "faith about...tomorrow" there's nothing to gain with her either for some reason. Allison doesn't want a one night stand - she has a "disdain for every link in that old chain." These witches are way ahead of you, "babe", so just know: "you can't bluff or outsmart."</p>
                          </BalanceText>
                        </div>


                        <div style={review_sub_title}>War</div>
                        <div>
                          <BalanceText style={align_left}>
                            <p>The music and sometimes even the melody can sound like "Me In Honey" by REM. </p>
                            <p>I think she means it when she says "I'm in a war with myself it's got nothing to do with you." Sometimes on the outside she hears a "happiness swelling landslide" and other times on the outside she hears "stagnant air on the front lines." So externally she is perceiving different things. Internally? "I'll fill up the room" so apparently she can be the life of the party but other times "I mostly keep to myself." She's all over the place.</p>
                          </BalanceText>
                        </div>


                        <div style={review_sub_title}>Arkadelphia</div>
                        <div>
                          <BalanceText style={align_left}>
                            <p>This has a calm feeling about it with a slow beat, acoustic guitar and occasional quiet slow Western guitar coming in sometimes. The kind of song that works well at 2am on a road trip in the American West. The music is like some of Yo La Tengo's slower songs.</p>
                            <p>Life can go many ways. "If you get real close to the ending I hope you know I did what I could." That's a pretty simple line that may sum up life. When you get old I hope I'm still ok in your eyes. This is the song of an older person although she's barely past 30. She's looking back on tap dancing when she was a kid. She's also picturing having children and worrying that she'll regret how fun life could have been had it taken another direction: "I watch the baby run around the yard - get lonely for what I'll never know." But she hasn't locked herself into that yet: "If we luck out, free as air...we'll weigh what's good and get real old." The person she's talking to is more honorable than she is "hope you can't see what's burning in me" - the person is so honest "you count the rings for truth you'll never cheat" - tree rings don't lie.</p>
                          </BalanceText>
                        </div>


                        <div style={review_sub_title}>Ruby Falls</div>
                        <div>
                          <BalanceText style={align_left}>
                            <p>She starts right out singing and does not let up for a while. It's a melancholy song as it's about a drug addict.</p>
                            <p>It starts out talking about what drugs can do to you: "If you cross over tonight you see beyond the darkest sky." But it's not all fun and games: "it's a squalid way to live." You may regret the life you've been missing while in a haze: "You might mourn all that you wasted." And then you die: "I'll sing a song at your funeral." There's a line about love in general in here as well: "Real love don't follow a straight line." I'd love to meet someone who has a partner with whom everything is always fine.</p>
                          </BalanceText>
                        </div>

                        <div style={review_sub_title}>St. Cloud</div>
                        <div>
                          <BalanceText style={align_left}>
                            <p>A pleasant way to end the album. It starts with a very quiet guitar, then singing with no drums. This could be a folk song from the hippie era. I like the "doo doo doo" parts.</p>
                            <p>This is her dad's home town in Florida. This state is famous for retirees - they lose their minds then die: "where do you go when your mind starts to lose its perfected shape?" I guess you go to St Cloud, Florida. But St Cloud has changed from his childhood: "Watch the new world project a rousing image." Even more changes is she may longer be afraid to get married: "I might show up in a white dress, turn reluctance on its ear." She ends by thinking about death again. I've reviewed some older people's albums lately - she's too young to think about death but it ends with "When I go." </p>
                          </BalanceText>
                        </div>



                        <div><i>Released: 27 March, 2020</i></div>


                      </Card.Text>
                    </Card.Body>
                  </Card>
              </Col>
            </Row>

          </Col>
    )
  }
}

const getNewsItem = (title, link) => {
  return (
    <a href = {link}><div class="newslinks">{title}</div></a>
  )
}

const mapStateToProps = (state, ownProps) => {
  // let page_num = ownProps.match.params.page_num;
  return {
    posts: state.posts,
    // page_num: page_num,
  }
}

export default connect(mapStateToProps)(WaxahatcheeSaintCloud)