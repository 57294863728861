import React, {Component} from 'react'
import {Link} from 'react-router-dom'
import {connect} from 'react-redux'
import axios from 'axios'
import {HomeHelper} from "./HomeHelper"
import {getNewsItems} from "./NewsItems";
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Pagination from 'react-bootstrap/Pagination'
import Dropdown from 'react-bootstrap/Dropdown'
import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'
import concert from '../concert.jpg'
import guitarcloseup from '../guitar-closeup.jpg'
import guitarcloseup_bw from '../guitar-closeup-bw.jpg'
import guitars_bw from '../guitars-bw.jpg'
import newspapers from '../newspapers.jpg'
import the_beatles from '../the-beatles.jpg'
import sorry925 from '../sorry.925.jpg'
import hmltdwestofeden from '../hmltdwestofeden.jpg'
import '../index.css'
import BalanceText from 'react-balance-text'


class HMLTDWestOfEden extends Component {
  constructor() {
    super()
  }

  render() {
    const card_style = {
      // width: '18em',
      display: 'inline-block',
      margin: '1em',
    };

    const card_style_double = {
      // width: '38em',
      display: 'inline-block',
      margin: '1em',
    };

    const img_style = {
      height: '4em',
    };

    const img_style_double = {
      /* height: '8em', */
    };

    const home_button = {
      backgroundColor: '#555555',
    };

    const align_left = {
      textAlign: 'left',
    }

    const review_sub_title = {
      color: '#555555',
      fontWeight: 'bold',
      fontSize: '1em',
      margin: '15px 0px 3px 0px',
    }

    return (
          <Col sm={6}>
            <Row>
              <Col sm={12}>
                  <Card style={card_style_double}>
                    <Card.Img variant="top" src={hmltdwestofeden} alt="HMLTD west of eden album cover" style={img_style_double}/>
                    <Card.Body style={align_left}>
                      <Card.Title style={align_left}>Review: <i>West of Eden</i> by HMLTD</Card.Title>
                      <Card.Text>
                        <BalanceText style={align_left}><b>Overall:</b> Henry Spychalski, in an interview with <a href="https://www.youtube.com/watch?v=v5gLdZkNNtc">Leeds Student Television</a> says that the band is just expressing themselves and not playing characters when they dress differently. If it's true they caught some slack for being heterosexual and appropriating LGBTQ culture, he's probably saying this to address such criticism.  I mention this because after watching the video for The West is Dead and Loaded and after looking at some press photos, my question is: These guys are heterosexual? God bless them.  If you want to be offended I have no problem with that. If you like it, that is fine too. Everyone can do what they want. This is a good album - seems greatly influenced by Franz Ferdinand. They look like Spiders From Mars Bowie or Roxy Music but don't sound like them.</BalanceText>

                        <div style={review_sub_title}>The West Is Dead</div>
                        <div>
                          <BalanceText style={align_left}>
                            <p>The intro is sort of like the intro to "Killing In The Name." Henry reminds me of Franz Ferdinand on this one and, speaking of something being dead, sounds a little like Bela Lugosi's Dead. </p>
                            <p>The lyrics broadly cover, in a few words, some aspects of the West which sometimes overlap with those of non-Western cultures. "A sick pod of dophins lap at the tide" - clearly we are destroying the Earth for our own convenience and profit. "Two girls spin around a mobius strip club" - we treat others as objects for our own pleasure. But why mobius? Perhaps it is just fun to say - sounds like a Before and After Jeopardy answer. "Football fans with their head in their hands" - we prioritize trivial things as the world crumbles. "When I looked inside myself I found the dream of a lifeless glass desert and all of this is lovely graspable" - he has no depth and everything he finds lovely is materialistic (graspable). "The apocalypse will be so compassionate" - all the pain and suffering in the world will be relieved thanks to the apocalypse. Whatever the meaning it's a funny line.</p>
                          </BalanceText>
                        </div>

                        <div style={review_sub_title}>Loaded</div>
                        <div>
                          <BalanceText style={align_left}>
                            <p>I can see people thinking this is a Depeche Mode song but the subject may be a little more violent than they would sing about. </p>
                            <p>Someone is having a breakdown living with their parents (apparently Sony since Spychalski said this song is written about them) - "voices say kill the things you love the most." The devil steps in to assist because this is easy pickins. A simple deal - "I'm gonna be on the TV tonight" - and blammo he kills his parents. He is free from all his bonds - "My home and my possessions, they rendered me a slave so I set my heart free." Is NYC the center of the soulless materialism that drove him to this? Is that where Sony has offices? - "I can see Central Park reflected in your eyes but there's no passion." Kids out there - there is a better way to escape materialism and misguided parents! Maybe try yoga or something. Or sign to a smaller label.</p>
                          </BalanceText>
                        </div>

                        <div style={review_sub_title}>The Ballad of Calamity James</div>
                        <div>
                          <BalanceText style={align_left}>
                            <p>A spaghetti western song. The title is a perhaps more a play on the androgyny that seems to be part of their image - instead of Calamity Jane it's Calamity James.</p>
                          </BalanceText>
                        </div>

                        <div style={review_sub_title}>To the Door</div>
                        <div>
                          <BalanceText style={align_left}>
                            <p>This one starts with a little continuation of the western music of the previous song. I hear Franz Ferdinand in this one as well. The section where he sings "The streets are lined with filth..." is very Foxygen.</p>
                            <p>Some people keep control over other people by saying that the whole world is awful and as bad as I am I'll protect you from those rotten people. "Don't leave this house, rats run the streets." But the person wants a new start - they're "moving to Leipzig" which itself got a new start by going from post-communist industrial filth to Germany's most livable city.</p>
                          </BalanceText>
                        </div>

                        <div style={review_sub_title}>Satan, Luella & I</div>
                        <div>
                          <BalanceText style={align_left}>
                            <p>This songs feels like Orville Peck, especially when he starts singing "Soft, gentle stranger." They've been liking the cowboy atmosphere in these past few songs - this starts out tinny and dusty - then it bursts into the Franz Ferdinand disco.</p>
                            <p>Did he fall in love with a Satan worshipper? If so he is not painting her with that brush - there are exceptions to every rule - you can be a Satan worshipper and still be "soft and gentle." Things in this world are not black and white - things that have bad reputations are not always bad ("Not every war's unjust") and vise versa - "Not every filth is art" is suggesting it's ok to say something is worthless. </p>
                          </BalanceText>
                        </div>

                        <div style={review_sub_title}>Mikey's Song</div>
                        <div>
                          <BalanceText style={align_left}>
                            <p>It sounds 80s sometimes - like OMD. Then it sounds like Girls in Peacetime-era Belle and Sebastian. When the group cheers "Hey Mikey" toward the end it sounds like an homage to "Hey Mickey." </p>
                            <p>Mikey is a man's name. He is singing about having a relationship with Mikey - "It's been three years and even months and I'm still in love with you." He keeps hearing Mikey's song on the radio and as much as it hurts he needs to hear it - "I turn it off but always turn it back on." Mikey sings some painful details about their relationship - "every lyric was too close to home, how could you be so cold?" As pained as he is in throughout the song, he ends it with "I want you to know that I'm okay." This is the opposite of "Circling" by Porridge Radio.</p>
                          </BalanceText>
                        </div>

                        <div style={review_sub_title}>Why?</div>
                        <div>
                          <BalanceText style={align_left}>
                            <p>I can't compare this song to too many things - it's Japanese I guess - maybe Cibo Matto could make something like this but they haven't as far as I know.</p>
                            <p>The video shows a girl and she's alone in a crowd - Tokyo is huge but you can feel lonely there really fast. The music is pleasant going from what sounds like some sort of traditional music to a more dreamlike sound.</p>
                          </BalanceText>
                        </div>

                        <div style={review_sub_title}>149 (feat. Tallulah Eden)</div>
                        <div>
                          <BalanceText style={align_left}>
                            <p>The chorus by Tallulah Eden harkens to Belle and Sebastian again, but when he sings about the Chrysanthemum Hotel it could be a Nine Inch Nails song. </p>
                            <p>Luella is back - he's thinking about her in his cell - something happened on July 12th - the Satan worshipper threw away his engagement rings and issued restraining orders. He tried religion but that didn't work - "I wrote her name on every page of the Holy Bible." Then he turns to Methadone. Then he killed their first born - he calls himself "schizo" and this would qualify if voices told him to do it as voices were in his head in "Loaded." Is it just a metaphor for him killing something they cherished? Dunno - is the jail cell just a metaphor? Jailed by regrets of the relationship?</p>
                          </BalanceText>
                        </div>

                        <div style={review_sub_title}>Joanna</div>
                        <div>
                          <BalanceText style={align_left}>
                            <p>It starts with a baby bell sound and then comes the bouncy piano with almost a twenties sound but with singing of a post-punk band like maybe Violent Femmes.</p>
                            <p>More schizophrenia? He "had a friend Father said she was make-pretend." Doctors tried to fix him with pills. Kids picked on him and threatened to "beat her out." Maybe just logic told him Joanna can't exist since the teachers "bludgeoned her to death." He still fears she'll come back and he thinks that addressing it in the song will overcome his fears, "but as I sing it now I hear your voice so loud." He may be trying to push down feelings from childhood or an earlier time and failing - the thoughts come back. </p>
                          </BalanceText>
                        </div>

                        <div style={review_sub_title}>Where's Joanna</div>
                        <div>
                          <BalanceText style={align_left}>
                            <p>When he's screaming at the end he sounds like David Byrne from some early Talking Heads and the music sounds like it could be from <i>More Songs About Buildings and Food</i>.</p>
                            <p>In the past song his imaginary friend was bludgeoned to death by his teachers. All the graphic imagery of her body parts all over the place are hopefully imaginary or else he'll be back in the jail he was in in 149. The description of the decaying "snatch" is not for children. When her mother comes he kills her too - "Now she will never ask again Where's Joanna?" Great - now his imaginary friend has a mother - or he's killed 2 real people.</p>
                          </BalanceText>
                        </div>

                        <div style={review_sub_title}>Death Drive</div>
                        <div>
                          <BalanceText style={align_left}>
                            <p>It sometimes sounds like Jon Spencer Blues Explosion and with the "I'm On A Death Drive" outburst it sounds like it would be the chorus of a 21st Century Perry Farrell project.</p>
                            <p>You read about wrong way accidents all the time - is it only in America this happens? This is written from the point of view of someone who is going to do it intentionally - I was always under the impression these were drunken accidents - but I also ask how drunk do you have to be to go down the wrong way of a highway - it almost seems intentional. He's got it out for Americans in this song - "You got automatic souls, automatic cars, American Idol superstars." As an American I take great offense - I don't watch American Idol - but to be honest I can't drive stick and my soul might in fact be automatic.</p>
                          </BalanceText>
                        </div>

                        <div style={review_sub_title}>Nobody Stays in Love</div>
                        <div>
                          <BalanceText style={align_left}>
                            <p></p>
                            <p>He likes continuing a theme from a previous song - right away he will "Take my car, smash it up." It sounds like a reasonable yet simple conclusion - something horrible will happen, or maybe not even horrible, and the love ends. But she argues against it as she lies cold under the hospital light - "Lovers, not love, are the first ones to die." Maybe it's possible for love to continue but I don't think Henry is buying it.</p>
                          </BalanceText>
                        </div>

                        <div style={review_sub_title}>MMXX A.D.</div>
                        <div>
                          <BalanceText style={align_left}>
                            <p>This has the sound of The Schmooze by The Supersuckers.</p>
                            <p>There is something hopeful in their songs even with all the acknowledgment of the negative things you hear about in our times. Here is a list of them: BP oil spill, disgraced Hollywood mogul, revenge porn, etc. Yet at the end there is a "lovingly graspable future." Sarcasm? Only if you think it is.</p>
                          </BalanceText>
                        </div>

                        <div style={review_sub_title}>Blank Slate</div>
                        <div>
                          <BalanceText style={align_left}>
                            <p>He sings "we lost everythang" like a country singer. The "Throw away your books" section could be a Pulp song. The changes between parts remind me of Father John Misty - if they cabaret kick in concert like he does during the upbeat parts that would be awesome.</p>
                            <p>I'm thinking he's mocking Brexit in this one and not buying the notion that leaving the EU will be a truly fresh start: "The world is a blank slate." He may be talking about the EU when he says "There are cops in our heads and we're killing them day-by-day." They're only in "our heads" perhaps because the EU is relatively unimportant and any problems EU membership caused will probably be replaced by something else. But until a new system is established and accepted - which may never really happen - the UK will be "in limbo." </p>
                          </BalanceText>
                        </div>

                        <div style={review_sub_title}>War Is Looming</div>
                        <div>
                          <BalanceText style={align_left}>
                            <p>The fiddle makes me think of Andrew Bird. He sings the line "I know it's different for you" the way Pete Doherty would sing it, at least the first time. Again I hear Orville Peck in this song.</p>
                            <p>There's something about Germany - earlier someone was going to the door and right on through to Leipzig. Now he's meeting someone in Hamburg. You may think this means he wants to be in the EU, but I have another take. Henry doesn't know it but subconsciously he knows The Beatles got their start in Hamburg and Sony told him he needs to be popular in the North of England and that's why he wrote Hamburg. Some day he'll thank me. Lucifer rears his ugly head again in this song  - showing up in dole queues. People can get some unjust ideas when they are poor and desperate. The limbo from "Blank Slate" still worries him as he and children sing "I hope someday we'll know who we are." It ends with the world ending - but "it's fine 'cause all we ever want and ever were is lost in time." Nothing to stress about - whenever you think something is horrific, pain heals all wounds or you realize something major now will not be major later. Let's interpret it that way and face a bright new day. Or maybe the future is horrible but it's not a big deal because nothing is important. </p>
                          </BalanceText>
                        </div>

                        <div><i>Released: 9 February, 2020</i></div>

                      </Card.Text>
                    </Card.Body>
                  </Card>
              </Col>
            </Row>

          </Col>
    )
  }
}

const getNewsItem = (title, link) => {
  return (
    <a href = {link}><div class="newslinks">{title}</div></a>
  )
}

const mapStateToProps = (state, ownProps) => {
  // let page_num = ownProps.match.params.page_num;
  return {
    posts: state.posts,
    // page_num: page_num,
  }
}

export default connect(mapStateToProps)(HMLTDWestOfEden)