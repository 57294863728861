import React, {Component} from 'react'
import {Link} from 'react-router-dom'
import {connect} from 'react-redux'
import axios from 'axios'
import {HomeHelper} from "./HomeHelper"
import {getNewsItems} from "./NewsItems";
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Pagination from 'react-bootstrap/Pagination'
import Dropdown from 'react-bootstrap/Dropdown'
import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'
import concert from '../concert.jpg'
import guitarcloseup from '../guitar-closeup.jpg'
import guitarcloseup_bw from '../guitar-closeup-bw.jpg'
import guitars_bw from '../guitars-bw.jpg'
import newspapers from '../newspapers.jpg'
import the_beatles from '../the-beatles.jpg'
import sparksasteadydripdripdrip from '../sparksasteadydripdripdrip.jpg'
import '../index.css'
import BalanceText from 'react-balance-text'


class Template extends Component {
  constructor() {
    super()
  }

  render() {
    const card_style = {
      // width: '18em',
      display: 'inline-block',
      margin: '1em',
    };

    const card_style_double = {
      // width: '38em',
      display: 'inline-block',
      margin: '1em',
    };

    const img_style = {
      height: '4em',
    };

    const img_style_double = {
      /* height: '8em', */
    };

    const home_button = {
      backgroundColor: '#555555',
    };

    const align_left = {
      textAlign: 'left',
    }

    const review_sub_title = {
      color: '#555555',
      fontWeight: 'bold',
      fontSize: '1em',
      margin: '15px 0px 3px 0px',
    }

    return (
          <Col sm={6}>
            <Row>
              <Col sm={12}>
                  <Card style={card_style_double}>
                    <Card.Img variant="top" src={sparksasteadydripdripdrip} alt="Template album cover" style={img_style_double}/>
                    <Card.Body style={align_left}>
                      <Card.Title style={align_left}>Review: <i>Title</i> by Artist</Card.Title>
                      <Card.Text>
                        <BalanceText style={align_left}><b>Overall:</b>  </BalanceText>

                        <div style={review_sub_title}>Shangri</div>
                        <div>
                          <BalanceText style={align_left}>
                            <p>Sound</p>
                            <p>Meaning</p>
                          </BalanceText>
                        </div>


                        <div style={review_sub_title}>Shangri</div>
                        <div>
                          <BalanceText style={align_left}>
                            <p>Sound</p>
                            <p>Meaning</p>
                          </BalanceText>
                        </div>


                        <div style={review_sub_title}>Shangri</div>
                        <div>
                          <BalanceText style={align_left}>
                            <p>Sound</p>
                            <p>Meaning</p>
                          </BalanceText>
                        </div>


                        <div style={review_sub_title}>Shangri</div>
                        <div>
                          <BalanceText style={align_left}>
                            <p>Sound</p>
                            <p>Meaning</p>
                          </BalanceText>
                        </div>


                        <div style={review_sub_title}>Shangri</div>
                        <div>
                          <BalanceText style={align_left}>
                            <p>Sound</p>
                            <p>Meaning</p>
                          </BalanceText>
                        </div>


                        <div style={review_sub_title}>Shangri</div>
                        <div>
                          <BalanceText style={align_left}>
                            <p>Sound</p>
                            <p>Meaning</p>
                          </BalanceText>
                        </div>


                        <div style={review_sub_title}>Shangri</div>
                        <div>
                          <BalanceText style={align_left}>
                            <p>Sound</p>
                            <p>Meaning</p>
                          </BalanceText>
                        </div>


                        <div style={review_sub_title}>Shangri</div>
                        <div>
                          <BalanceText style={align_left}>
                            <p>Sound</p>
                            <p>Meaning</p>
                          </BalanceText>
                        </div>


                        <div style={review_sub_title}>Shangri</div>
                        <div>
                          <BalanceText style={align_left}>
                            <p>Sound</p>
                            <p>Meaning</p>
                          </BalanceText>
                        </div>


                        <div style={review_sub_title}>Shangri</div>
                        <div>
                          <BalanceText style={align_left}>
                            <p>Sound</p>
                            <p>Meaning</p>
                          </BalanceText>
                        </div>



                        <div><i>Released: 17 April, 2020</i></div>


                      </Card.Text>
                    </Card.Body>
                  </Card>
              </Col>
            </Row>

          </Col>
    )
  }
}

const getNewsItem = (title, link) => {
  return (
    <a href = {link}><div class="newslinks">{title}</div></a>
  )
}

const mapStateToProps = (state, ownProps) => {
  // let page_num = ownProps.match.params.page_num;
  return {
    posts: state.posts,
    // page_num: page_num,
  }
}

export default connect(mapStateToProps)(Template)