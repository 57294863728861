import React, {Component} from 'react'
import {Link} from 'react-router-dom'
import {connect} from 'react-redux'
import axios from 'axios'
import {HomeHelper} from "./HomeHelper"
import {getNewsItems} from "./NewsItems";
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Pagination from 'react-bootstrap/Pagination'
import Dropdown from 'react-bootstrap/Dropdown'
import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'
import concert from '../concert.jpg'
import guitarcloseup from '../guitar-closeup.jpg'
import guitarcloseup_bw from '../guitar-closeup-bw.jpg'
import guitars_bw from '../guitars-bw.jpg'
import newspapers from '../newspapers.jpg'
import the_beatles from '../the-beatles.jpg'
import lauramarlingsongforourdaughter from '../lauramarlingsongforourdaughter.jpg'
import '../index.css'
import BalanceText from 'react-balance-text'


class LauraMarlingSongForOurDaughter extends Component {
  constructor() {
    super()
  }

  render() {
    const card_style = {
      // width: '18em',
      display: 'inline-block',
      margin: '1em',
    };

    const card_style_double = {
      // width: '38em',
      display: 'inline-block',
      margin: '1em',
    };

    const img_style = {
      height: '4em',
    };

    const img_style_double = {
      /* height: '8em', */
    };

    const home_button = {
      backgroundColor: '#555555',
    };

    const align_left = {
      textAlign: 'left',
    }

    const review_sub_title = {
      color: '#555555',
      fontWeight: 'bold',
      fontSize: '1em',
      margin: '15px 0px 3px 0px',
    }

    return (
          <Col sm={6}>
            <Row>
              <Col sm={12}>
                  <Card style={card_style_double}>
                    <Card.Img variant="top" src={lauramarlingsongforourdaughter} alt="Song For Our Daughter by Laura Marling album cover" style={img_style_double}/>
                    <Card.Body style={align_left}>
                      <Card.Title style={align_left}>Review: <i>Song For Our Daughter</i> by Laura Marling</Card.Title>
                      <Card.Text>
                        <BalanceText style={align_left}><b>Overall:</b>  While not a mother herself, she is singing to an idea of a young person, like Maya Angelou writing to a ficticious daughter in "Letters to My Daughter." The songs throughout the album are pleasant and catchy and she reminds me of many American singers (Amy Mann, Chrissy Hynde, Lucinda Williams, Michelle Shocked). Some of these songs strangely could easily be Travis (the Scottish band, not country singer) songs.</BalanceText>

                        <div style={review_sub_title}>Alexandra</div>
                        <div>
                          <BalanceText style={align_left}>
                            <p>Mainly an acoustic guitar and a woman singing. This is a beautiful song and bodes well for the rest of the album. I can't believe she's British. I live in Chicago and she could be a folk singer from downstate Illinois or really anywhere else in America. This sound is not done as often in the UK.</p>
                            <p>While the music bodes well, I'm nervous about the lyrics on the rest of the album. This song is a reaction to Leonard Cohen's Alexandra Leaving, which itself was a reaction to C.P. Cavafy's "The God Abandons Antony." The amount of research to get the meaning of anything is overwhelming. Is she talking to Leonard when she asks "What kind of woman gets to love you?" Laura says you don't get to get inside Alexandra's head in Cohen's song. Here Alexandra sounds like a strong person who makes the most out of life: "Alexandra had no fear" and she "finds diamonds in the drain." It sounds like she could see the good in anyone. </p>
                          </BalanceText>
                        </div>

                        <div style={review_sub_title}>Held Down</div>
                        <div>
                          <BalanceText style={align_left}>
                            <p>She has hints of Sam Phillips in the 90s on this one. Also Amy Mann. It also sounds like it could be a Travis song - I can picture Fran Healy singing this.</p>
                            <p>Instead of her partner she discovers a note at 4am ending the relationship. She took it for granted before: "How many times before have you seen me run? It's a cruel kinda twist that you'd leave like this." You don't know what you got until it's gone.</p>
                          </BalanceText>
                        </div>

                        <div style={review_sub_title}>Strange Girl</div>
                        <div>
                          <BalanceText style={align_left}>
                            <p>Her voice sometimes sounds like Chrissy Hynde on this song (to me that's the highest honor I can give a woman singer.) But fast paced acoustic sound reminds me more of some 60s folk singers.</p>
                            <p>She's talking to her younger self on this one: "Working hard and getting on still not getting paid." In an <a href="https://www.npr.org/transcripts/832052963">NPR</a> interview she says in songs she takes on the character of a "forsaken, hardworking woman." And she lambastes herself for phases (cutting off relations, announcing herself a socialist): "Don't bullshit to me." Good advice to give someone at any age: "Roll like a tidal wave." Things aren't always easy but just keep going.</p>
                          </BalanceText>
                        </div>

                        <div style={review_sub_title}>Only the Strong</div>
                        <div>
                          <BalanceText style={align_left}>
                            <p>She sounds like Michelle Shocked a little here - and again Amy Mann. A little Lucinda Williams. Everyone she reminds me of are people I like.</p>
                            <p>This can relate to the previous song's advice to "roll like a tidal wave" because love "bruises all, end up benign." Keep going - bumps in the road probably aren't going to ruin you. You can't get beat by some bad feelings - I guess that makes you weak - so only the strong survive. "Love is a sickness killed by time, I hope you can change my mind, had to leave this crying all behind." Has she left someone who made her feel "love is a sickness killed by time" for someone who will change her mind?</p>
                          </BalanceText>
                        </div>

                        <div style={review_sub_title}>Blow By Blow</div>
                        <div>
                          <BalanceText style={align_left}>
                            <p>Maybe Joni Mitchell this time?</p>
                            <p>It starts by seeming like she was the victim of a crime - her mom is talking to the press. "Tell them that I'm doing fine - underplay distress." She's moving on after a horrible encounter. But it could be a metaphor for the knocks she took starting out as a performer: "No one was prepared but we all performed like we'd done it all before." She is feeling foolish "for believing it could work out like some things do." Could she still be talking about her career? She seems to get good reviews but maybe the bucks ain't rolling in. Musicians are often the last to get paid in the business - maybe she can  make more money with her masters in psychoanalysis.</p>
                          </BalanceText>
                        </div>

                        <div style={review_sub_title}>Song for Our Daughter</div>
                        <div>
                          <BalanceText style={align_left}>
                            <p>A pretty song with nice harmonies as well as piano and strings. This would have fit nicely on Return of the Grievous Angel even though Gram Parsons didn't write it.</p>
                            <p>I'm guessing it's not easy being a woman trying to break into the entertainment business - especially at the young age she started: "Taking advice from some old balding bore you'll ask yourself 'Did I want this at all?'" In the #metoo age, these words have some meaning: "There's blood on the floor, maybe now you'll believe her for sure." After covering difficulties while talking to her younger self, it ends with a similar theme to other songs: "You'll get your way through it somehow."</p>
                          </BalanceText>
                        </div>

                        <div style={review_sub_title}>Fortune</div>
                        <div>
                          <BalanceText style={align_left}>
                            <p>With the strings and singing style it sometimes sounds like it could be from a musical. And sometimes she sounds like Joan Baez.</p>
                            <p>The album is Song for OUR Daughter. This one may be from the father's point of view to the daughter. The mother told him the money was for running away. There has been great pain: "Release me from this unbearable pain." Is he going to die? "Some love is ancient and it live on in your soul." Love is passed from parent to child.  </p>
                          </BalanceText>
                        </div>

                        <div style={review_sub_title}>The End of the Affair</div>
                        <div>
                          <BalanceText style={align_left}>
                            <p>A nice quiet song with acoustic guitar with a faint hit of almost a choir harmonizing during "if you were mine."</p>
                            <p>As the title says, the affair has to end "now we must make good on words to God" - the promises made in a church during a marriage. Criminals sometimes say they feel better after they confess - if you're not going to confess, then at least end the affair so you can "live your life."</p>
                          </BalanceText>
                        </div>

                        <div style={review_sub_title}>Hope We Meet Again</div>
                        <div>
                          <BalanceText style={align_left}>
                            <p>It's almost an American West feel to the music here. It belongs in a dusty town although she talks about "eastern woods." I wish she said "western dirt" - then I'd look a little smarter.</p>
                            <p>She had a relationship with a guy but it's done - "left my heart with a man in those eastern woods." Did he move in with her? "this is not a home anymore - you just threw your pieces - they washed up on my shore." Sounds like it was serious but not serious. She is remembering the good parts as some do of relationships past: "I hope we meet again - hope you never change."</p>
                          </BalanceText>
                        </div>

                        <div style={review_sub_title}>For You</div>
                        <div>
                          <BalanceText style={align_left}>
                            <p>I hear male voices for the first time on the album - just some background humming. Maybe a hint of a father figure to the child. When she says "Love is not the AHnswer" that's the first time I noticed a British accent. When she sings "I thank a God I've never met" she has a very nice lilt in her voice.  Again a very pleasant song.</p>
                            <p>Here is another speech to her daughter, given right when she is born, to close out the album. "I drew pictures of you long before I met you" - she imagined, probably as a kid, having a baby and drew pictures. She's going to adore the kid: "I wear a picture of you just to keep you safe" and "what a miracle you are." And she will not put any pressure on: "No childish expectation." </p>
                          </BalanceText>
                        </div>



                        <div><i>Released: 10 April, 2020</i></div>


                      </Card.Text>
                    </Card.Body>
                  </Card>
              </Col>
            </Row>

          </Col>
    )
  }
}

const getNewsItem = (title, link) => {
  return (
    <a href = {link}><div class="newslinks">{title}</div></a>
  )
}

const mapStateToProps = (state, ownProps) => {
  // let page_num = ownProps.match.params.page_num;
  return {
    posts: state.posts,
    // page_num: page_num,
  }
}

export default connect(mapStateToProps)(LauraMarlingSongForOurDaughter)