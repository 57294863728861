import React, {Component} from 'react'
import {Link} from 'react-router-dom'
import {connect} from 'react-redux'
import axios from 'axios'
import {HomeHelper} from "./HomeHelper"
import {getNewsItems} from "./NewsItems";
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Pagination from 'react-bootstrap/Pagination'
import Dropdown from 'react-bootstrap/Dropdown'
import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'
import concert from '../concert.jpg'
import guitarcloseup from '../guitar-closeup.jpg'
import guitarcloseup_bw from '../guitar-closeup-bw.jpg'
import guitars_bw from '../guitars-bw.jpg'
import newspapers from '../newspapers.jpg'
import the_beatles from '../the-beatles.jpg'
import badlydrawnboybananaskinshoes from '../badlydrawnboybananaskinshoes.jpg'
import '../index.css'
import BalanceText from 'react-balance-text'


class Home extends Component {
  constructor() {
    super()
  }

  render() {
    const card_style = {
      // width: '18em',
      display: 'inline-block',
      margin: '1em',
    };

    const card_style_double = {
      // width: '38em',
      display: 'inline-block',
      margin: '1em',
    };

    const img_style = {
      height: '4em',
    };

    const img_style_double = {
      // height: '8em',
    };

    const home_button = {
      backgroundColor: '#555555',
    };

    const align_left = {
      textAlign: 'left',
    }

    const card_link = {
      color: '#555555',
    }

    return (
      <Row className="justify-content-md-center">

        <Col sm={3}>
          {getNewsItems()}
        </Col>

        <Col sm={6}>
            <Row>
              <Col sm={12}>
                <a href="/reviews/badly-drawn-boy-banana-skin-shoes" style={card_link}>
                  <Card style={card_style_double}>
                    <Card.Img variant="top" src={badlydrawnboybananaskinshoes} alt="Badly Drawn Boy Banana Skin Shoes album cover" style={img_style_double}/>
                    <Card.Body style={align_left}>
                      <Card.Title style={align_left}>Latest: <i>Banana Skin Shoes</i> by Badly Drawn Boy</Card.Title>
                      <Card.Text>
                        <BalanceText style={align_left}>After a long wait we finally have a new album by Badly Drawn Boy.
                          It was worth the wait. It's fun right from the opening seconds.
                        </BalanceText>
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </a>
              </Col>
            </Row>

            <Row>
              <Col sm={6}>
                <a href="/venues" style={card_link}>
                  <Card style={card_style}>
                    <Card.Img variant="top" src={guitars_bw} alt="guitars" style={img_style}/>
                    <Card.Body style={align_left}>
                      <Card.Title style={align_left}>Venues</Card.Title>
                      <Card.Text>
                        <BalanceText style={align_left}>See our collection of over 1000 venues in the UK to see great music. Big or small we have it!</BalanceText>
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </a>
              </Col>

              <Col sm={6}>
                <a href="/reviews" style={card_link}>
                  <Card style={card_style}>
                    <Card.Img variant="top" src={concert} alt="concert image"  style={img_style}/>
                    <Card.Body style={align_left}>
                      <Card.Title style={align_left}>Reviews</Card.Title>
                      <Card.Text>
                        <BalanceText style={align_left}>See our album reviews. We have covered Ben Watt, Ed O'Brien and more.</BalanceText>
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </a>
              </Col>
            </Row>
          </Col>
      </Row>
    )
  }
}

const getNewsItem = (title, link) => {
  return (
    <a href = {link}><div class="newslinks">{title}</div></a>
  )
}

const mapStateToProps = (state, ownProps) => {
  // let page_num = ownProps.match.params.page_num;
  return {
    posts: state.posts,
    // page_num: page_num,
  }
}

export default connect(mapStateToProps)(Home)