import React, {Component} from 'react'
import {Link} from 'react-router-dom'
import {connect} from 'react-redux'
import axios from 'axios'
import {HomeHelper} from "./HomeHelper"
import {getNewsItems} from "./NewsItems";
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Pagination from 'react-bootstrap/Pagination'
import Dropdown from 'react-bootstrap/Dropdown'
import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'
import concert from '../concert.jpg'
import guitarcloseup from '../guitar-closeup.jpg'
import guitarcloseup_bw from '../guitar-closeup-bw.jpg'
import guitars_bw from '../guitars-bw.jpg'
import newspapers from '../newspapers.jpg'
import the_beatles from '../the-beatles.jpg'
import '../index.css'
import BalanceText from 'react-balance-text'


class Feature extends Component {
  constructor() {
    super()
  }

  render() {
    const card_style = {
      // width: '18em',
      display: 'inline-block',
      margin: '1em',
    };

    const card_style_double = {
      // width: '38em',
      display: 'inline-block',
      margin: '1em',
    };

    const img_style = {
      height: '4em',
    };

    const img_style_double = {
      height: '8em',
    };

    const home_button = {
      backgroundColor: '#555555',
    };

    const align_left = {
      textAlign: 'left',
    }

    const feature_sub_title = {
      color: '#555555',
      fontWeight: 'bold',
      fontSize: '1.1em',
      margin: '15px 0px 3px 0px',
    }

    return (
          <Col sm={6}>
            <Row>
              <Col sm={12}>
                  <Card style={card_style_double}>
                    <Card.Img variant="top" src={the_beatles} alt="concert" style={img_style_double}/>
                    <Card.Body style={align_left}>
                      <Card.Title style={align_left}>Featured: The Beatles Underrated Songs</Card.Title>
                      <Card.Text>
                        <BalanceText style={align_left}>The Beatles may be the most famous band of all time, but with so
                          many songs, there are bound to be those not known by the casual music fan. Which are the best ones?
                          You can replace these with another six and not be wrong, but in no particular order, here are some
                          suggestions for the non-hardcore Beatles fan.
                        </BalanceText>
                        <div style={feature_sub_title}>I'm Down</div>
                        <div>
                          <BalanceText style={align_left}>This rocker was the B-side to the "Help!" single. The Beatles would close their
                            concerts with this song and often laugh while playing it as John Lennon sometimes played keyboards
                            with his elbows. Even on the recorded version it sounds like he's swiping the keys with his elbows
                            during his solo and Paul McCartney laughs.
                            It is fast-paced from the get-go with Paul McCartney screaming "You tell lies thinking
                            I can't see!" before any instruments come in and it never slows down.
                          </BalanceText>
                        </div>
                        <div style={feature_sub_title}>Dear Prudence</div>
                        <div>
                          <BalanceText style={align_left}>This has to be one of the best songs ever written about Mia
                            Farrow's sister. The Beatles were with her in India on a meditation retreat and she apparently
                            took it very seriously and would not come out of seclusion. It starts with a hauntingly beautiful
                            simple guitar and a very subtle bass that eventually kicks in with one of Paul McCartney's best
                            bass lines as John Lennon sings "The sun is up, the sky is blue" to coax Prudence out of her isolation.
                            The smooth entrance of guitar and bass had to have influenced The Verve in a lot of their songs.
                          </BalanceText>
                        </div>
                        <div style={feature_sub_title}>I Call Your Name</div>
                        <div>
                          <BalanceText style={align_left}>John Lennon's voice sounds great when it's double-tracked, meaning
                            he records the song and then sings along with himself so his voice sounds twice as powerful. There are many
                            catchy early songs but this one makes the list because it was thrown off the Hard Day's Night LP
                            even though it has so much wonderful cowbell.
                          </BalanceText>
                        </div>
                        <div style={feature_sub_title}>Act Naturally</div>
                        <div>
                          <BalanceText style={align_left}>This is one of the Beatles' best cover songs. If you are an
                            old country music fan you know this song because it reached number one on the US Country charts
                            in 1963 when recorded by Buck Owens. Ringo sings it for the Beatles and the bouncy guitar that
                            accompanies his singing is nothing but fun as he sings about winning an Oscar in a film about a man who is
                            sad and lonely because all he has to do is act naturally. This is a rock site but sometimes you have to
                            tip your cap to simple country sad/funny songs.
                          </BalanceText>
                        </div>
                        <div style={feature_sub_title}>Two Of Us</div>
                        <div>
                          <BalanceText style={align_left}>John and Paul harmonizing sound like friends who really enjoy
                            being around each other even though signs of an imminent breakup may have been obvious when
                            Paul sings "You and I have memories longer than the road that stretches out ahead." The melodic
                            bass line (a Paul specialty) runs through the song and the pause before the guitar picking at the
                            end just makes you want to walk a country road as Doris gets her oats.
                          </BalanceText>
                        </div>
                        <div style={feature_sub_title}>Love You To</div>
                        <div>
                          <BalanceText style={align_left}>George Harrison casually introduced a sitar into pop music with
                          "Norwegian Wood" but he threw a more traditional and overwhelming sitar into people's faces with
                          this song and it works. There is no gimmick here - it just sounds catchy and his double-tracked
                          voice belting out "A lifetime is so short/A new one can't be bought" sets the tone that artists can sing
                            about deep things like wrestling with success and what it can and can't get you.
                          </BalanceText>
                        </div>
                      </Card.Text>
                    </Card.Body>
                  </Card>
              </Col>
            </Row>

          </Col>
    )
  }
}

const getNewsItem = (title, link) => {
  return (
    <a href = {link}><div class="newslinks">{title}</div></a>
  )
}

const mapStateToProps = (state, ownProps) => {
  // let page_num = ownProps.match.params.page_num;
  return {
    posts: state.posts,
    // page_num: page_num,
  }
}

export default connect(mapStateToProps)(Feature)