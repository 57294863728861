import React, {Component} from 'react'
import {Link} from 'react-router-dom'
import {connect} from 'react-redux'
import axios from 'axios'
import {HomeHelper} from "./HomeHelper"
import {getNewsItems} from "./NewsItems";
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Pagination from 'react-bootstrap/Pagination'
import Dropdown from 'react-bootstrap/Dropdown'
import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'
import concert from '../concert.jpg'
import guitarcloseup from '../guitar-closeup.jpg'
import guitarcloseup_bw from '../guitar-closeup-bw.jpg'
import guitars_bw from '../guitars-bw.jpg'
import newspapers from '../newspapers.jpg'
import the_beatles from '../the-beatles.jpg'
import benwattstormdamage from '../benwattstormdamage.jpg'
import '../index.css'
import BalanceText from 'react-balance-text'


class Reviews2 extends Component {
  constructor() {
    super()
  }

  render() {
    const card_style = {
      // width: '18em',
      display: 'inline-block',
      margin: '1em',
    };

    const card_style_double = {
      // width: '38em',
      display: 'inline-block',
      margin: '1em',
    };

    const img_style = {
      height: '4em',
    };

    const img_style_double = {
      /* height: '8em', */
    };

    const home_button = {
      backgroundColor: '#555555',
    };

    const align_left = {
      textAlign: 'left',
    }

    const review_sub_title = {
      color: '#555555',
      fontWeight: 'bold',
      fontSize: '1em',
      margin: '15px 0px 3px 0px',
    }

    return (
          <Col sm={6}>
            <Row>
              <Col sm={12}>
                  <Card style={card_style_double}>
                    <Card.Img variant="top" src={benwattstormdamage} alt="ben watt storm damage album cover" style={img_style_double}/>
                    <Card.Body style={align_left}>
                      <Card.Title style={align_left}>Review: <i>Storm Damage</i> by Ben Watt</Card.Title>
                      <Card.Text>
                        <BalanceText style={align_left}><b>Overall:</b> Sounds like 21st century Neil Finn and Thom Yorke. He's getting close to 60 and covers distant memories, getting old and love changing over time. Despite those normally sad topics, I love it. Here's a description of the songs.</BalanceText>

                        <div style={review_sub_title}>Balanced on a Wire</div>
                        <div>
                          <BalanceText style={align_left}>It's not often that an album starts right away with singing - almost talking. The piano is simple and works - and the chord changes and synths later sound great. I'm also a sucker for simple lyrics which somehow aren't cliche: "People say live in the moment but the moment seems so narrow sometimes". Whoever he asks "Do you risk it all right here?" should consult the song Fruitless by Poi Dog Pondering which says "that tale of your one big chance is a lie told by consumed men".</BalanceText>
                        </div>

                        <div style={review_sub_title}>Summer Ghosts</div>
                        <div>
                          <BalanceText style={align_left}>
                            <p>It starts with a kindly American dad leaving a phone message probably to a grown kid - he must represent all parents since Watt is British.</p>
                            <p>How British is he? Well, because this song talks about parents and grandparents, it took me down a fun rabbit hole of Wikipedia research. His dad was a Scottish jazz band leader. His mother wrote for She magazine and the Daily Mail, and before marrying Ben's father, she was married to a theater critic (John Gielgud led a tribute at his funeral) with whom she had 4 kids, one being Roly Poly the priest clown. I don't mean it like "priests are freaking clowns" - I mean he was literally a circus-trained clown and a priest.</p>
                            <p>His parents sound like they should have been better - "wrapped up in themselves" - but when you're away from it, or "shot of it", it's easier to find some "Zen" peace. Still, people are shaped by their childhood experiences and a trip around Hull reminds him he's not too different from how he was as a kid who I'm guessing visited Hull in the summertime based on the name of the song.</p>
                            <p>The lyrics say his grandad was born on "Anlaby Road" which is a main thoroughfare in Hull. In these pandemic times I took a fun Google Maps voyage around a Hull which has changed from when he walked it "years ago." He sings that a Hilton has gone up on Ferensway but I only found a Doubletree by Hilton on Ferensway - I guess Hilton sounds a little more poetic than Doubletree by Hilton, but doubletree would actually be a pretty poetic word if it weren't a hotel brand. He walks past a cutesy road named Land of Green Ginger and then goes through Hepsworth's Arcade - I walked through something similar in Cardiff called The Royal Arcade. As an
                            American I eat up places like that since we don't have many here. He finishes down Whitefriargate, a pedestrian only street, which is actually backtracking if he passed Land of Green Ginger before getting to Hepsworth's.</p>
                            <p>He sings the line "But look at me seeking assistance" like Andrew Bird. The song sounds like 21st century Crowded House (based on my love of Neil Finn this is a huge compliment). It also has a Radiohead-ish vibe which isn't a bad thing either.</p>
                          </BalanceText>
                        </div>

                        <div style={review_sub_title}>Retreat to Find</div>
                        <div>
                          <BalanceText style={align_left}>Pleasant song about escaping, perhaps to a park in Bristol (Old Down) where you can eventually find a place in your heart to eventually outface death. He had a scary disease in 1992 which he wrote a book about which may have inspired this.</BalanceText>
                        </div>

                        <div style={review_sub_title}>Figures in the Landscape</div>
                        <div>
                          <BalanceText style={align_left}>When I heard the line "Everyone's a karaoke star" I was afraid it was a dime-a-dozen criticism of the instagram era of people who don't really produce anything trying to be famous. But in the context of the rest of the song, I interpret it as we are all ephemeral and just small parts of a bigger whole and can easily be replaced so I take back my initial knee-jerk reaction. I must have heard this song on the radio because he released it as a single and the chorus sounded familiar to me.</BalanceText>
                        </div>

                        <div style={review_sub_title}>Knife in the Drawer</div>
                        <div>
                          <BalanceText style={align_left}>
                            <p>I'll interpret this as him having an ex who is bipolar - who starts out happy and then drinks and gets angry. The night is completely opposite of day - "the temperature drops" and "no lights from the houses" - cold and completely dark. I think it's a "she" because the hair was unruly and that doesn't sound like a description of a male's hair but what do I know? She is "laughing at first" at the party but later gave people looks "as cold as a stone." But he sees past the mood swings, "so much underneath the veneer."</p>
                            <p>What does she need a knife for? "Are you in need of it still?" Is she suicidal? Why is it a "mark of respect" not to use it. Is he not going to kill himself as a mark of respect to her? Perhaps she's not the only one with problems.</p>
                          </BalanceText>
                        </div>

                        <div style={review_sub_title}>Irene</div>
                        <div>
                          <BalanceText style={align_left}>He sings a little like Hamilton Leithauser on this one. The lyrics are a little John Prine - or maybe I just have him fresh in my head since he just died. Remembering specifics from a past time. A place with red lampshades in the booths - sounds a little like a jazz club - sounds like a memory from the past. Things change: "You were a queen". Note: my wife said "What's that? I like that" and made Alexa play it in the living room.</BalanceText>
                        </div>

                        <div style={review_sub_title}>Sunlight Follows the Night</div>
                        <div>
                          <BalanceText style={align_left}>He must be going way back for this one - he's been with his wife a long time - and the lyrics seem like he's saying get on with your life and don't dwell on a past romance: "you won't win if you box yourself in." The sunlight is Tracey Thorn and the night was the end of a previous romance?</BalanceText>
                        </div>

                        <div style={review_sub_title}>Hand</div>
                        <div>
                          <BalanceText style={align_left}>The first verse he sounds a little like an older Paul McCartney which is appropriate since this is like a sequel to When I'm 64. A much sadder sequel - sounds like his wife died. And he's not sure he provided her the life he should have: "Did you ever get to build your dream forever home where your dream feelings lived fixed in stone?"</BalanceText>
                        </div>

                        <div style={review_sub_title}>You've Changed, I've Changed</div>
                        <div>
                          <BalanceText style={align_left}>These lyrics relate to Summer Ghost and Sunlight Follows Night. "Through a door to stay the same" is another way of saying you can still have some same thoughts no matter where you go. "Love will bend in high wind" is a little nicer than it being completely lost however as it seems to have been in Sunlight Follows Night</BalanceText>
                        </div>

                        <div style={review_sub_title}>Festival Song</div>
                        <div>
                          <BalanceText style={align_left}>A good way to end an album - just piano and singing. A little violin thrown in maybe. You would think with a name like Festival Song it would be a rager and crowd pleaser to get everyone jumping at a festival - nope! 	He's by himself in a crowd ("I lost all my friends hours ago") and reflecting on how you need to "let go for a while" to get away from problems. Being that the album talks so much about end of life type things "I lost all my friends hours ago" can have a deeper meaning, but I'll leave it at the simple interpretation.</BalanceText>
                        </div>

                      </Card.Text>
                    </Card.Body>
                  </Card>
              </Col>
            </Row>

          </Col>
    )
  }
}

const getNewsItem = (title, link) => {
  return (
    <a href = {link}><div class="newslinks">{title}</div></a>
  )
}

const mapStateToProps = (state, ownProps) => {
  // let page_num = ownProps.match.params.page_num;
  return {
    posts: state.posts,
    // page_num: page_num,
  }
}

export default connect(mapStateToProps)(Reviews2)