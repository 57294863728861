import React, {Component} from 'react'
import {Link} from 'react-router-dom'
import {connect} from 'react-redux'
import axios from 'axios'
import {HomeHelper} from "./HomeHelper"
import {getNewsItems} from "./NewsItems";
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Pagination from 'react-bootstrap/Pagination'
import Dropdown from 'react-bootstrap/Dropdown'
import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'
import concert from '../concert.jpg'
import guitarcloseup from '../guitar-closeup.jpg'
import guitarcloseup_bw from '../guitar-closeup-bw.jpg'
import guitars_bw from '../guitars-bw.jpg'
import newspapers from '../newspapers.jpg'
import the_beatles from '../the-beatles.jpg'
import sparksasteadydripdripdrip from '../sparksasteadydripdripdrip.jpg'
import '../index.css'
import BalanceText from 'react-balance-text'


class SparksASteadyDripDripDrip extends Component {
  constructor() {
    super()
  }

  render() {
    const card_style = {
      // width: '18em',
      display: 'inline-block',
      margin: '1em',
    };

    const card_style_double = {
      // width: '38em',
      display: 'inline-block',
      margin: '1em',
    };

    const img_style = {
      height: '4em',
    };

    const img_style_double = {
      /* height: '8em', */
    };

    const home_button = {
      backgroundColor: '#555555',
    };

    const align_left = {
      textAlign: 'left',
    }

    const review_sub_title = {
      color: '#555555',
      fontWeight: 'bold',
      fontSize: '1em',
      margin: '15px 0px 3px 0px',
    }

    return (
          <Col sm={6}>
            <Row>
              <Col sm={12}>
                  <Card style={card_style_double}>
                    <Card.Img variant="top" src={sparksasteadydripdripdrip} alt="Sparks A Steady Drip Drip Drip album cover" style={img_style_double}/>
                    <Card.Body style={align_left}>
                      <Card.Title style={align_left}>Review: <i>A Steady Drip Drip Drip</i> by Sparks</Card.Title>
                      <Card.Text>
                        <BalanceText style={align_left}><b>Overall:</b>  This band has been around since the 60s and it shows - you can hear styles from the different decades on this. I hear a lot of Paul McCartney 70s music here and I hear a lot of 70s-80s art rock which Sparks themselves helped shape back then. Really there are a lot of different styles on this album. It ends with a pretty song about saving the environment for kids. </BalanceText>

                        <div style={review_sub_title}>All That</div>
                        <div>
                          <BalanceText style={align_left}>
                            <p>The acoustic guitar and singing sometimes sounds like Spirits by the Strumbellas. The verses sound a little 60s and 70s. I have a feeling, being around over 50 years, they are going to be like a train going through time which has collected sounds along the way. The little guitar solo could be George Harrison.</p>
                            <p>This is the song of an older person reflecting on a long and strong relationship. He has no eyes for anyone else: "I don't need to fool around and I don't need to drool around." He doesn't need material things as long as he has a good relationship: "new cheap chairs will always be our thrones."</p>
                          </BalanceText>
                        </div>


                        <div style={review_sub_title}>I'm Toast</div>
                        <div>
                          <BalanceText style={align_left}>
                            <p>This sounds like their 70s and 80s art rock sound. Devo or Buggles.</p>
                            <p>The message here is the opposite of the first song. He "took a drop...better bring a mop." After going to bat for the person, "now I'm not your scene." To bring the theme to modern times he says "Alexa, get me out of this place." There is only so much technology can do.</p>
                          </BalanceText>
                        </div>


                        <div style={review_sub_title}>Lawnmower</div>
                        <div>
                          <BalanceText style={align_left}>
                            <p>It's a humorous sound - sometimes it sounds like New Pornographers or like Blur's sillier songs. It also has some harmonies which are almost Beach Boys-like.</p>
                            <p>The lawnmower represents trying to impress the neighbors. He's impressing them with the lawnmower itself: "the neighbors look...with jealous and awe at my lawnmower." But he doesn't stop there. He's impressing them with the lawn itself. He's picturing them saying: "Your lawn will be a jaw-dropper." He says his girlfriend is putting up with his lawnmower, but is she? She's "packing up the Land Rover...it's me or it's that lawnmower." </p>
                          </BalanceText>
                        </div>


                        <div style={review_sub_title}>Sainthood Is Not in Your Future</div>
                        <div>
                          <BalanceText style={align_left}>
                            <p>Sometimes the music sounds like Safety Dance. </p>
                            <p>It sounds like they have a problem with an actor - "wherefore art thou so nasty" and but "cheer up you may just win a BAFTA." Were they actually punched in the face by this person? "Your left hook I found most stunning." Considering they seem to have serious issues with how nice this person is, it's a very happy sounding song.</p>
                          </BalanceText>
                        </div>


                        <div style={review_sub_title}>Pacific Standard Time</div>
                        <div>
                          <BalanceText style={align_left}>
                            <p>It's a pleasant song with harmonies. The synthesizers sometimes give it an 80s feel. It sounds like a Paul McCartney chorus.</p>
                            <p>As ridiculous as Southern California can be, at least many realize that "we don't know it all at all." There are places where people may feel they know best and others are not as wise - and this is the most ignorant stance you can take. "I tried a foreign place...they thought they knew it all." For as much criticism as LA gets, "Once they're here they're staying here."</p>
                          </BalanceText>
                        </div>


                        <div style={review_sub_title}>Stravinsky's Only Hit</div>
                        <div>
                          <BalanceText style={align_left}>
                            <p>Short staccato singing in a high voice - almost sounds like a mocking song Frank Zappa would make. The bridge sounds pretty cool in between the verses. Maybe just because it sort of rhymes, this reminds me of Kinski Assasin by Ariel Pink.</p>
                            <p>Stravinsky is a complex character in this song. After winning a Grammy (he actually did win one posthumously for Lifetime Achievement) he "shunned all the party people." But he was the king of partiers: "Party party rum and women" and  "two pills and now he's on the dance floor." He gives away all his royalties from the hit song (that the narrator wrote words for) to "NAACP and many charities." Any mention of this song has been hidden from history. On his resume "they don't list his hit - I guess it doesn't fit - heard it at the mall - I don't see it as an object of shame." This is a fictional song (I think.) </p>
                          </BalanceText>
                        </div>


                        <div style={review_sub_title}>Left Out in the Cold</div>
                        <div>
                          <BalanceText style={align_left}>
                            <p>Again this sounds like late 70s Paul McCartney. But it also has an 80s pop sound and a touch of Abba.</p>
                            <p>He is the head of research for Uniqlo in Winnipeg testing jackets, overcoats, ski caps, etc "long before they're sold." Winnipeg's cold is "unforgiving" but "there were times when things were better...things went south fast - once I had a mammoth mansion...all that's now past." I guess he's drinking and thinking of getting out: "Someday I'll be warm again...have another shot."</p>
                          </BalanceText>
                        </div>


                        <div style={review_sub_title}>Self-Effacing</div>
                        <div>
                          <BalanceText style={align_left}>
                            <p>Oddly, as geeky as the music in this song is, if it were heavier with heavy guitars instead of synthesizers it has the pace (except for the na-na parts) of a Supersuckers song. The theme is close to something they would write as well.</p>
                            <p>It sounds like he's mocking self-effacing people but I don't know if he is. At the end he tells the person he's talking to "we're tight me and you...we're both self-effacing." That might be such a nice thing to say after you call yourself a "minivan" instead of a "Rolls Royce."</p>
                          </BalanceText>
                        </div>


                        <div style={review_sub_title}>One for the Ages</div>
                        <div>
                          <BalanceText style={align_left}>
                            <p>The keyboards and singing and the bass coming in - this feels like progressive rock. Sometimes they remind me of Rush. The falsetto singing is pretty impressive for someone who's been doing it over 50 years. They sound like Roxy Music in this one too.</p>
                            <p>The guy is dreaming of sticking it to the people who don't respect his work. He's just a quiet accountant. Someday people will claim "I knew all along he'd be something great." People will still need to praise themselves for his success. But successful he will be: "Experts they will swarm" and "when the statues come I hope they look like me." He knows people will try to knock him down "Soon the backlash comes but I don't care because it's one for the ages." His tome is going to be the final say on all matters accounting.</p>
                          </BalanceText>
                        </div>


                        <div style={review_sub_title}>Onomato Pia</div>
                        <div>
                          <BalanceText style={align_left}>
                            <p>It jumps into something that could be from The Sound of Music. But then it becomes like Abba or Wings. It's a fun song.</p>
                            <p>"Otomato Pia came from Rome - had a head of angel hair - couldn't speak a word of English no one cared." This opening line sung in a falsetto to a bouncy tune tells me this is going to be one of their story songs. "Pia had a real communication flare." Could she get by just my making sounds?  Back in Rome with "not one word Italian she cast a spell with oohs and awws pregnant pause." Sometimes there are people who just have an unspoken ability to be likable. "Learning disorder or fantastic skill?" </p>
                          </BalanceText>
                        </div>

                        <div style={review_sub_title}>iPhone</div>
                        <div>
                          <BalanceText style={align_left}>
                            <p>This is another one of their prog rock songs. This seems like it could be from a rock opera.</p>
                            <p>Adam tells Eve he wishes that snake would go away but "it appears that you have something else to do...put your fucking iPhone down and listen to me." So apparently people have been distracted for a while - if the iPhone were there instead of the apple Eve would go for it. When giving the Gettysburg Address the crowd "seemed to be distracted." But Abe could be mean: "Put your fucking iPhone down and listen to me." Mrs. Jobs didn't know what to do either - her friends would "often hear her sobs." </p>
                          </BalanceText>
                        </div>

                        <div style={review_sub_title}>The Existential Threat</div>
                        <div>
                          <BalanceText style={align_left}>
                            <p>The opening sounds like it could be a game show theme but then it sounds like Ariel Pink. Every time I say something like that I really mean Ariel Pink sounds like them since they've been around forever. This is also one of their post-punk art rock sounds. Another happy sounding song about a dark topic.</p>
                            <p>He is "turning left turning right" to try to get away but the existential threat is always there. His doctor gave him something to "cure an existential type disease" but "insurance won't cover existential meds."  The fear is looming like "a semi-automatic weapon ready to discharge." Then he drops some philosophical names: "Sartre and Camus seemed to understand something close to what I'm feeling though they were in France." I'm picturing him breaking into a stranger's house to escape the existential threat: "Guess I woke you up and also woke your little kid." He drives away but the existential threat is always on its way.</p>
                          </BalanceText>
                        </div>

                        <div style={review_sub_title}>Nothing Travels Faster Than the Speed of Light</div>
                        <div>
                          <BalanceText style={align_left}>
                            <p>This is a prog rock song but it feels contemporary.</p>
                            <p>This is the kind of song that would cast any fan of Sparks into the nerdy Rush set. "Nothing travels faster than the speed of light but what they're telling you is wrong." Is someone so impatient that the speed of light isn't good enough? "You're not the kind who will wait." Some say what makes science so powerful is its ability to admit when it is wrong and question all of its theories. Who's to say nothing travels faster than the speed of light?</p>
                          </BalanceText>
                        </div>{`


`}

                        <div style={review_sub_title}>Please Don't F**k up My World</div>
                        <div>
                          <BalanceText style={align_left}>
                            <p>This is a pretty song - it sounds like it could be an Elbow song. The kids singing it is such a simple idea - I can't believe other people haven't thought of having kids sing this before. </p>
                            <p>This one doesn't need any explanation - kids singing the title is pretty much it. Some words about rivers, mountains and seas but you get it.</p>
                          </BalanceText>
                        </div>

                        <div><i>Released: 15 May, 2020</i></div>


                      </Card.Text>
                    </Card.Body>
                  </Card>
              </Col>
            </Row>

          </Col>
    )
  }
}

const getNewsItem = (title, link) => {
  return (
    <a href = {link}><div class="newslinks">{title}</div></a>
  )
}

const mapStateToProps = (state, ownProps) => {
  // let page_num = ownProps.match.params.page_num;
  return {
    posts: state.posts,
    // page_num: page_num,
  }
}

export default connect(mapStateToProps)(SparksASteadyDripDripDrip)