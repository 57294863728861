import React, {Component} from 'react'
import {Link} from 'react-router-dom'
import {connect} from 'react-redux'
import axios from 'axios'
import {HomeHelper} from "./HomeHelper"
import {getNewsItems} from "./NewsItems";
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Pagination from 'react-bootstrap/Pagination'
import Dropdown from 'react-bootstrap/Dropdown'
import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'
import concert from '../concert.jpg'
import guitarcloseup from '../guitar-closeup.jpg'
import guitarcloseup_bw from '../guitar-closeup-bw.jpg'
import guitars_bw from '../guitars-bw.jpg'
import newspapers from '../newspapers.jpg'
import the_beatles from '../the-beatles.jpg'
import publicpracticegentlegrip from '../publicpracticegentlegrip.jpg'
import '../index.css'
import BalanceText from 'react-balance-text'


class PublicPracticeGentleGrip extends Component {
  constructor() {
    super()
  }

  render() {
    const card_style = {
      // width: '18em',
      display: 'inline-block',
      margin: '1em',
    };

    const card_style_double = {
      // width: '38em',
      display: 'inline-block',
      margin: '1em',
    };

    const img_style = {
      height: '4em',
    };

    const img_style_double = {
      /* height: '8em', */
    };

    const home_button = {
      backgroundColor: '#555555',
    };

    const align_left = {
      textAlign: 'left',
    }

    const review_sub_title = {
      color: '#555555',
      fontWeight: 'bold',
      fontSize: '1em',
      margin: '15px 0px 3px 0px',
    }

    return (
          <Col sm={6}>
            <Row>
              <Col sm={12}>
                  <Card style={card_style_double}>
                    <Card.Img variant="top" src={publicpracticegentlegrip} alt="Public Practice Gentle Grip album cover" style={img_style_double}/>
                    <Card.Body style={align_left}>
                      <Card.Title style={align_left}>Review: <i>Gentle Grip</i> by Public Practice</Card.Title>
                      <Card.Text>
                        <BalanceText style={align_left}><b>Overall:</b>  Bass - the bass is prevalent. I couldn't keep writing
                          about the bass so I just started out each review with "Bass" toward the end. These people are big
                          Talking Heads fans based on this album and the fact they are from NYC. But they do other styles too.
                          I dig this album. And I dig the bass.</BalanceText>

                        <div style={review_sub_title}>Moon</div>
                        <div>
                          <BalanceText style={align_left}>
                            <p>I just reviewed The Wants who had a futuristic industrial sound throughout the album and this
                            starts out in that ballpark. When she talks it's like Kim Gordon. When the drum starts banging
                            you feel like you're in a dark basement club with a lot of people wearing black.</p>
                            <p>"Take me to the moon I wanna see all the bright lights." But does she really? No! Happiness
                            is not out there - happiness is internal she discovers. It turns out she is the moon. "I am the light."
                            The moon theme goes well with the futuro-industrial sound.</p>
                          </BalanceText>
                        </div>


                        <div style={review_sub_title}>Cities</div>
                        <div>
                          <BalanceText style={align_left}>
                            <p>It starts with a late 70s Talking Heads guitar. She sings like Debbie Harry too - clearly
                            a band from New York. Sometimes they remind me of Pavement here - which makes me realize something
                            I never realized before: Pavement can remind me of Talking Heads.</p>
                            <p>NME says this song is about Berlin - whut? I don't get that - at least David Bowie said "I
                            remember standing by the wall" to give me a clue he was singing about Berlin in Heroes. "Green
                            grass leading to barren squares" is something that can refer to many or all cities. "Casting
                            shadows where bombs lay" - now we're talking - but I think Dresden more than Berlin but ok.
                            "Shake the other hand" - a reference to reunification? "Only with the light can we see history
                            shining down so brightly" - you can see history, especially in East Berlin, with its cutesy
                            little Prenzlauer Berg. East Berlin where the artists of Europe moved in the early 90s because
                            it was pretty much free - the result - tons of great coffee houses and artists priced out of
                            the neighborhood - you do see history there.</p>
                          </BalanceText>
                        </div>


                        <div style={review_sub_title}>Disposable</div>
                        <div>
                          <BalanceText style={align_left}>
                            <p>It starts with a beat and a fuzzy low guitar. It gets into a little disco beat like Franz
                            Ferdinand. Then it breaks mid song into a suddenly quieter section building back up to Franz.
                            The parts with the guitar and funky beat could be part of a Cake song.</p>
                            <p>We got news for you - "You have it or you don't, disposable." Don't get your dreams built
                            too high - we're all disposable. Is she going to get dumped - is she disposable? "You say you're
                            going to walk out...I want to see you talk it out." Does the person have too much confidence? Do
                            they not realize what they are missing? "It may be too late to save you from the undertow."</p>
                          </BalanceText>
                        </div>


                        <div style={review_sub_title}>Each Other</div>
                        <div>
                          <BalanceText style={align_left}>
                            <p>This sounds like an early 80s British band - maybe something from Buzzcocks, Jam or Specials
                              era.</p>
                            <p>Her partner was "wrapped up in someone else's eyes" last night. But they're justifying it -
                            "saying it's just...social life" and "just a flirt." What can you do? You hope to sway them.
                            "You and I are out tonight" but things aren't going as well as planned. Although she wishes
                            they would "choose each other lose the other" it's not happening: "Your mind's all wrapped up
                            but it's not wrapped up with mine."</p>
                          </BalanceText>
                        </div>


                        <div style={review_sub_title}>Underneath</div>
                        <div>
                          <BalanceText style={align_left}>
                            <p>This has an awesome Duran Duran sounding bass line. I like the harmonic singing and I like
                            that they don't overdo it on the album so you appreciate it when you hear it.</p>
                            <p>The words are pretty confident. She's "chasing but holding my dream" but she's not losing touch
                            with reality - "roots are taking hold" and she's fine with not knowing everything - "it feels better
                            to be ok with what you can't see." </p>
                          </BalanceText>
                        </div>


                        <div style={review_sub_title}>See You When I Want To</div>
                        <div>
                          <BalanceText style={align_left}>
                            <p>Pretty much a bass line and drums and talking. They take their bass lines seriously on this
                            album and I have no problem with that. </p>
                            <p>As confident as she was in the last song, here it sounds like she feels better after a cry:
                            "The worry washed out of my eyes." Again she is fine not knowing everything: "I don't have to
                            understand...their way." She is thinking back to childhood - "drawers of dust covered memories."
                            Something is reminding her of her "mother's embrace." Is her mother gone, but she conjures her
                            up in her memory when she wants to?</p>
                          </BalanceText>
                        </div>


                        <div style={review_sub_title}>My Head</div>
                        <div>
                          <BalanceText style={align_left}>
                            <p>She sings like an 80s soul/pop singer for parts and then like a British pop singer or Blondie.
                              I'm sick of saying it but the funky bass is great.</p>
                            <p>Time moves so slowly when you're young but things are speeding up here at the end. "Time
                              like water once thick but now she's slipping through my hand." And the pressure you feel to
                              get things done when time moves quickly: "Can we keep up the work?" For a second she wonders
                              "Am I over my head?" but her confidence from earlier comes back: "we're going to make it." Any
                              pressure or doubts are all in her head.</p>
                          </BalanceText>
                        </div>


                        <div style={review_sub_title}>Compromised</div>
                        <div>
                          <BalanceText style={align_left}>
                            <p>Music could be early 80s mod music again. The singing sometimes rings of Elastica maybe?</p>
                            <p>Ideally you only value what money can't buy - but it's hard not valuing some things. Good
                            dinners are awesome and so are drinks - and it's nice sometimes to drive a nice car - I don't
                            but I rented a new Audi last year on a vacation and it was good. Sometimes you may feel you
                              have to compromise a little and give yourself a little materialistic break. But don't overdo
                            it people - the  stuff money can't buy is more necessary.</p>
                          </BalanceText>
                        </div>


                        <div style={review_sub_title}>Understanding</div>
                        <div>
                          <BalanceText style={align_left}>
                            <p>Bass. Staccato guitar chords a la Talking Heads. She sings like Kristin Hersh on a good
                            chunk of this song. They break into some suddenly simple and slow guitar notes like Courtney
                            Barnett does sometimes. Then it builds up like Throwing Muses. </p>
                            <p>She's dealing with someone with baggage. "I can't mend your broken past." Were they too wrapped
                            up in their own problems to notice her? "You had to have it all your way...you don't want to
                            see my face." She herself has dealt with problems in the past, but she rewrites her memories
                            to come out on higher ground but "does it count as history if it's only in my memory?"</p>
                          </BalanceText>
                        </div>


                        <div style={review_sub_title}>Shangri</div>
                        <div>
                          <BalanceText style={align_left}>
                            <p>Bass. This sounds like it could be a John Lennon song from the early 70s.</p>
                            <p>This one isn't too complicated. She did her time, she made her rounds, but now: "Leave
                            me alone that's all I want" even though they want her to stick around.</p>
                          </BalanceText>
                        </div>


                        <div style={review_sub_title}>How I Like It</div>
                        <div>
                          <BalanceText style={align_left}>
                            <p>Bass. Adrien Belew could be playing that guitar at the beginning - the high wailing sort
                            of distorted guitar. Or The Pixies or, therefore, Nirvana.</p>
                            <p>Walking down the street he's questioned by an old man then an old lady about his shirt
                            and his shoes. He likes it - he must be breaking new ground. The young kid understands him
                            though: "I wanna be just like you." </p>
                          </BalanceText>
                        </div>


                        <div style={review_sub_title}>Hesitation</div>
                        <div>
                          <BalanceText style={align_left}>
                            <p>Bass. This starts with a very slow beat and a few distorted sounds - more silence than music
                            but then it gets funky - David Bowie sounding from the 70s. They sound like Blur when they
                            say "You say you say..."</p>
                            <p>Like in the earlier song "Compromise" there is a "hesitation to disconnecct from your desire."
                            In fact they are wanting approval for caving in: "Authorize my abandon."</p>
                          </BalanceText>
                        </div>



                        <div><i>Released: 15 May, 2020</i></div>


                      </Card.Text>
                    </Card.Body>
                  </Card>
              </Col>
            </Row>

          </Col>
    )
  }
}

const getNewsItem = (title, link) => {
  return (
    <a href = {link}><div class="newslinks">{title}</div></a>
  )
}

const mapStateToProps = (state, ownProps) => {
  // let page_num = ownProps.match.params.page_num;
  return {
    posts: state.posts,
    // page_num: page_num,
  }
}

export default connect(mapStateToProps)(PublicPracticeGentleGrip)