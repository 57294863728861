export const HomeHelper = (posts) => {
  // if(posts.length == 0) {
  //   return 0;
  // }
  // let eddie = {}
  // eddie[2] = {}
  // eddie[2][1] = posts.length
  // let mylength = posts.length
  // let length2 = 512;
  // return mylength;
  // alert(mylength);
  // length2 = mylength;
  // for(let i=0; i < posts.length; i++){
  // for(let i=0; i< mylength; i++){
  // for(let i=0; i< length2; i++){
  // for(let i=0; i<512; i++){
  //   eddie[1] = {}
    // eddie[1][1] = posts.length
    // eddie[1][1] = mylength
  // }
  // alert(eddie[1][1])
  // return posts.length
  // return eddie[1][1]
  // return posts.length == 512 ? "yes" : "no"
  // return (
  //   //ajax stuff here
  //   "hey"
  // );
  return ("hey");
}

// export default HomeHelper;