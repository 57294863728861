import React, {Component} from 'react'
import {Link} from 'react-router-dom'
import {connect} from 'react-redux'
import axios from 'axios'
import {HomeHelper} from "./HomeHelper"
import {getNewsItems} from "./NewsItems";
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Pagination from 'react-bootstrap/Pagination'
import Dropdown from 'react-bootstrap/Dropdown'
import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'
import concert from '../concert.jpg'
import guitarcloseup from '../guitar-closeup.jpg'
import guitarcloseup_bw from '../guitar-closeup-bw.jpg'
import guitars_bw from '../guitars-bw.jpg'
import newspapers from '../newspapers.jpg'
import the_beatles from '../the-beatles.jpg'
import eobearth from '../eobearth.jpg'
import '../index.css'
import BalanceText from 'react-balance-text'


class EOBEarth extends Component {
  constructor() {
    super()
  }

  render() {
    const card_style = {
      // width: '18em',
      display: 'inline-block',
      margin: '1em',
    };

    const card_style_double = {
      // width: '38em',
      display: 'inline-block',
      margin: '1em',
    };

    const img_style = {
      height: '4em',
    };

    const img_style_double = {
      /* height: '8em', */
    };

    const home_button = {
      backgroundColor: '#555555',
    };

    const align_left = {
      textAlign: 'left',
    }

    const review_sub_title = {
      color: '#555555',
      fontWeight: 'bold',
      fontSize: '1em',
      margin: '15px 0px 3px 0px',
    }

    return (
          <Col sm={6}>
            <Row>
              <Col sm={12}>
                  <Card style={card_style_double}>
                    <Card.Img variant="top" src={eobearth} alt="EOB earth album cover" style={img_style_double}/>
                    <Card.Body style={align_left}>
                      <Card.Title style={align_left}>Review: <i>Earth</i> by EOB</Card.Title>
                      <Card.Text>
                        <BalanceText style={align_left}><b>Overall:</b>  Yes his Radiohead background definitely pokes through but he has his own unique sound as well. A few times throughout the album it sounds like U2's Pop, which was not one of my favorite albums at the time but it grew on me, and other times he's far from that and just playing acoustic like some Badly Drawn Boy songs - something you do not think of when you think of Radiohead. This is a pleasant album.</BalanceText>

                        <div style={review_sub_title}>Shangri-La</div>
                        <div>
                          <BalanceText style={align_left}>
                            <p>You can tell right off the bat he was in Radiohead. This song also sounds somewhere in between U2's Zooropa and Pop albums. It's a little repetitive but that's sometimes Radiohead's schtick - if it sounds pleasant keep it going. I'm fine with that.</p>
                            <p>His first few lines make it sound like he's done with Radiohead - when you have a solo album that starts with "I'm not going back in the old house, We'll burn it to the ground" it doesn't sound promising. But there is no sign of Radiohead breaking up so I guess that's a wrong interpretation. This may simply be a song about finding paradise eventually. Is he looking back after crossing a Styx-like river when he says "The other side peace will never...land"? He now has "no more loneliness" and "All the swans spread their wings and fly." But who is "she" that is safe from harm? Either way, he thought he was always fine until he learned what fine really is: "I didn't really know that I'd feel so cold until I found my Shangri-La". Or is Shangri-La cold? That would suck!</p>
                          </BalanceText>
                        </div>

                        <div style={review_sub_title}>Brasil</div>
                        <div>
                          <BalanceText style={align_left}>
                            <p>This one starts with just an acoustic guitar and mellow singing which is not a typical Radiohead sound. It's a very pretty song. When he sings "I feel the love falling..." it could be a Noel Gallagher's High Flying Birds song.</p>
                            <p>This is a breakup song but who broke it with who? Whoever he is singing to sounds like a wreck ("To see you smile and laugh again but you can't") so did he end it? But then he sings "Feeling like I'm on my knees" which would imply he's begging to get back. But "the flame is gone it's over now" so I guess not. Still he's remembering "golden nights spent with you" and he ends it by saying "feel the love again." Maybe they'll get back together. Why is it called Brasil - did he write it while he lived there? Is it about Brazil and not a relationship?</p>
                          </BalanceText>
                        </div>

                        <div style={review_sub_title}>Deep Days</div>
                        <div>
                          <BalanceText style={align_left}>
                            <p>The beginning sounds a little seventies - maybe Todd Rundgren. But it ends in more modern times - smooth.</p>
                            <p>I tried googling the phrase "stunts dropping" or "stunts dropped" in case it's some phrase I'm not aware of but all I found was a clip of a guy doing something weird on a stunt bag. "The stunts tonight are never to drop" must mean he had sex with someone and it's not a one night stand. The rest of the lyrics would support this: "Where you go, I will go. Where you stay, I will stay, etc." If it were a one night stand, that would be shallow, but this song is called "Deep Days."</p>
                          </BalanceText>
                        </div>

                        <div style={review_sub_title}>Long Time Coming</div>
                        <div>
                          <BalanceText style={align_left}>
                            <p>Another acoustic intro. I think he likes having the freedom to do some simple acoustics. It feels like a Badly Drawn Boy song.</p>
                            <p>Sometimes you may see a city girl by herself, maybe in a coffee shop. Is she depressed and lonely? "Will she ever find the life and love divine to make it all go away?" </p>
                          </BalanceText>
                        </div>

                        <div style={review_sub_title}>Mass</div>
                        <div>
                          <BalanceText style={align_left}>
                            <p>An eerie sounding song even if the lyrical content is not mystical. The guitar toward the end has a little hint of Steve Gunn about it - lonely American West.</p>
                            <p>Not much to analyze in these lyrics. Sometimes someone loves you and you hope they don't find out you're an idiot. This quote is pretty much the entire lyric: "Stay in love...I'm nothing." </p>
                          </BalanceText>
                        </div>

                        <div style={review_sub_title}>Banksters</div>
                        <div>
                          <BalanceText style={align_left}>
                            <p>This is back to a Radiohead sound. But also back to a song that could be on U2's Pop. I think the seeds of some of his songs were planted somewhere in the mid 90s.</p>
                            <p>Musicians often are the last to get the money from their music so that has to have inspired a chunk of the sentiment in this song, even if Ed is worth a boatload. "Wall Street devours, makes you trip and bidders give another chance" but Radiohead has done things at their own pace: "I know to say no, no, no, no..." Other bands have not been so fortunate: "Where did all the money go?" He doesn't have to worry: "We're breaking free."</p>
                          </BalanceText>
                        </div>

                        <div style={review_sub_title}>Sail On</div>
                        <div>
                          <BalanceText style={align_left}>
                            <p>Back to the trusty acoustic, pleasant opening that continues through the song. The singing is slow and dreamy and heavenly like the topic of the song.</p>
                            <p>This is not just a sailing song - it's a man past 50 (like Ben Watt and Morrissey whose albums I've reviewed recently) reviewing their lives and/or addressing death. "The ladder stand on the headlight...shining hope and light." 55% of Brits (and 81% of Americans) believe in heaven of some sort. "Just one more voyage we all must undergo, See the light reaching out to me." Some say being on a boat in calm weather is their version of heaven: "Sail on like a summer breeze." I would agree as long as someone else maintains the boat. </p>
                          </BalanceText>
                        </div>

                        <div style={review_sub_title}>Olympik</div>
                        <div>
                          <BalanceText style={align_left}>
                            <p>Not to sound like a broken record, but the first part of this song also sounds like something from U2 Pop. The outro about "I want to dream...I have to dream" reminds me of Poi Dog Pondering on their late 90s more electronic albums.</p>
                            <p>Life is confusing (a theme on Porridge Radio's <i>Ever Bad</i> album as well): "Kaleidoscope will start to spin Shaking up all the mess." But as the previous song indicates, he is thinking of heaven awaiting: "Waking up from the deepest sleep - I want this paradise." Does this thought keep him going? "I have to dream it all." Some say they need that thought or else life is pointless.</p>
                          </BalanceText>
                        </div>

                        <div style={review_sub_title}>Cloak of the night</div>
                        <div>
                          <BalanceText style={align_left}>
                            <p>A beatiful acoustic duet with Laura Marling. The guitar reminds me of "Easy Love" by Badly Drawn Boy.</p>
                            <p>A true love can make anything scary less scary - it's all unimportant when you are happily in love. "Trees crack, the earth does howl...no fear let love prevail."</p>
                          </BalanceText>
                        </div>


                        <div><i>Released: 17 April, 2020</i></div>


                      </Card.Text>
                    </Card.Body>
                  </Card>
              </Col>
            </Row>

          </Col>
    )
  }
}

const getNewsItem = (title, link) => {
  return (
    <a href = {link}><div class="newslinks">{title}</div></a>
  )
}

const mapStateToProps = (state, ownProps) => {
  // let page_num = ownProps.match.params.page_num;
  return {
    posts: state.posts,
    // page_num: page_num,
  }
}

export default connect(mapStateToProps)(EOBEarth)