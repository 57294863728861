import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import axios from 'axios'
import {HomeHelper} from "./HomeHelper"
import Pagination from 'react-bootstrap/Pagination'
import Dropdown from 'react-bootstrap/Dropdown'

class VenuePostCodes extends Component {
  constructor() {
    super()
    const list_datatype = "post_code"
    this.state = {
      posts: [],
      datatypes: [],
      // api_url: 'http://localhost/entities/',
      // api_url: 'http://localhost/venue/names/',

      api_url: 'http://localhost/list/rockandroll_venue/' + list_datatype + '/', // default is name
      list_datatype: list_datatype,

      // api_url: 'http://localhost/list/rockandroll_venue/post_code/',
      // list_datatype: "post_code",

      // api_url: 'http://localhost/list/allschooldata_school/',
      api_url2: 'http://localhost/datatypes/',
      list_value_type: "value_text",
      eddie: "eddie",
      entities_size: 0,
      page_size: 30,
      current_page: 1,
      pages: {},
    }
    this.handleSelect.bind(this)
    this.redisplayEntities.bind(this)
    this.regetDropdown.bind(this)
    // this.getPages.bind(this)
  }

  componentDidMount(){
    axios.all([axios.get(this.state.api_url), axios.get(this.state.api_url2)])
      .then(
        axios.spread((...responses) => {
          console.log("didMount")
          // console.dir(getPosts(JSON.parse(responses[0].data), JSON.parse(responses[1].data)))
          const get_posts = getPosts(JSON.parse(responses[0].data), getDatatypes(JSON.parse(responses[1].data)), this.state.list_value_type)
          const entities = get_posts[0]
          const sorted_entities = get_posts[1]
          console.log("json:")
          console.dir(JSON.parse(responses[0].data))
          console.log("sorted_entities:")
          console.dir(sorted_entities)
          console.log("entities:")
          console.dir(entities)
          const entities_size = Object.keys(entities).length
          const pages = getPages(entities, this.state.page_size, JSON.parse(responses[0].data))
          this.setState({
            posts: JSON.parse(responses[0].data),

          //   [
          //     {
          //       "model":"project2.myentity",
          //       "pk":109,
          //       "fields":{
          //         "entity":112,
          //         "entitytype_id":2,
          //         "datatype_id":7,
          //         "value_float":null,
          //         "value_text":"AB10 1JR",
          //         "dataload_id":4,
          //         "active":1
          //       }
          //     },
          // {
          //   "model":"project2.myentity",
          //   "pk":8,
          //   "fields":{
          //   "entity":11,
          //     "entitytype_id":2,
          //     "datatype_id":7,
          //     "value_float":null,
          //     "value_text":"AB10 1QS",
          //     "dataload_id":4,
          //     "active":1
          // }
          // },

            datatypes: JSON.parse(responses[1].data),
            displayedEntities: displayEntities(entities,pages,1, this.state.list_datatype),
            entities_size: entities_size,
            pages: pages,
            // dropDown: getDropdown(entities, pages, 1)
          })
        })
      )
      .catch(errors => {
        console.error(errors);
      });
  }

  regetDropdown(entities, pages, number){
    console.dir(pages)
    console.log("regetDropdown: " + number)
    this.setState({
      current_page: number,
    //   dropDown: (
    //   <Dropdown>
    //     <Dropdown.Toggle variant="success" id="dropdown-basic">
    //       Showing {number}
    //     </Dropdown.Toggle>
    //
    //     <Dropdown.Menu>
    //       {
    //         // (
    //         // pages.map((number) => {
    //         //   // console.log("keys: " + Object.keys(pages[number]))
    //         //   console.log("redropdown " + number )
    //         //   return <Dropdown.Item href="#/action-{number}">{number}</Dropdown.Item>
    //         // })
    //       // )
    //       }
    //       <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
    //       <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
    //       <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
    //     </Dropdown.Menu>
    //   </Dropdown>
    // )
    })
  }

  redisplayEntities (entities, pages, number) {
    this.setState({
      displayedEntities: (
        <div>
          {
            entities.isEmpty ? "emptyish" : (
              // Object.keys(entities).filter(entity => entity in pages[number]).map((entity) => {
              pages[number].map((entity) => {
                // console.log("keys: " + Object.keys(pages[number]))
                  console.log("redisplay " + number + " " + entities[entity][this.state.list_datatype])
                  return <div key={entities[entity][this.state.list_datatype]}>{getEntity(entities[entity], this.state.list_datatype)}</div>
                }
              )
            )
          }
        </div>
      )
    })
  }

  displayEntities(entities) {
//   this.setState({
//     displayedEntities: (
    return this.state.displayedEntities == null ? (
        // return (
        <div>
          {
            entities.isEmpty ? "empty" : (
              Object.keys(entities).map((entity) => {
                  console.log(entities[entity][this.state.list_datatype])
                  return <div key={entities[entity][this.state.list_datatype]}>{getEntity(entities[entity])}</div>
                }
              )
            )
          }
        </div>
      )
      : "empty"
    // })
  }

  handleSelect(number, entities, pages){
    console.log(number + " was selected")
    this.setState({eddie: "eddie" + number})
    console.dir(entities);
    this.redisplayEntities(entities, pages, number)
    this.regetDropdown(entities, pages, number)
    // console.dir(this.state)
    // console.dir(number)
  }

  render(){
    const { posts, datatypes, entities_size, pages } = this.state
    const my_datatypes = getDatatypes(datatypes)
    const get_posts = getPosts(posts, my_datatypes, this.state.list_value_type)
    const entities = get_posts[0]
    const sorted_entities = get_posts[1]
    // const dropDown = getDropdown(entities, pages, 1)
    console.log("render")
    console.dir(pages)
    console.log(Object.keys(pages).length)
    console.dir(entities)
    const postNext = (
      <Link to={'/blah/'}>
        <span className="brown-text">Next</span>
      </Link>
    )

    const {isFetching} = this.state;


    let active = 1;
    let items = [];
    let droplines = []
    for (let number = 1; number <= Object.keys(pages).length; number++) {
      items.push(
        <Pagination.Item key={number} active={number === active} onClick={() => this.handleSelect(number, entities, pages)}>
          {number}
        </Pagination.Item>,
      );
      droplines.push(
      <Dropdown.Item href="#/{number}" key={number} onClick={() => this.handleSelect(number, entities, pages)}>{entities[pages[number][0]][this.state.list_datatype].substring(0,5) + " through " + entities[pages[number][pages[number].length - 1]][this.state.list_datatype].substring(0,5)}</Dropdown.Item>
      )
    }

    //        <Pagination size="lg">{items}</Pagination>
    const paginationBasic = (
      <Pagination style={{justifyContent: "center"}}>{items}</Pagination>
    );

    //{entities[pages[this.state.current_page][0]]["name"]}
    console.log("rendering dropdown")
    if(Object.keys(entities).length > 0) {
      console.log(Object.keys(entities).length)
      // console.log(entities[pages[this.state.current_page][pages[this.state.current_page].length - 1]][this.state.list_datatype])
      console.log(pages[this.state.current_page][0])
    }
    const dropDown = (
      <Dropdown>
        <Dropdown.Toggle variant="success" id="dropdown-basic">
          Showing {Object.keys(entities).length > 0 ? entities[pages[this.state.current_page][0]][this.state.list_datatype] + " through " + entities[pages[this.state.current_page][pages[this.state.current_page].length - 1]][this.state.list_datatype]: ""}
        </Dropdown.Toggle>

        <Dropdown.Menu>
          {droplines}
        </Dropdown.Menu>
      </Dropdown>
    );

    // this.displayEntities(entities)
    return (
      <div>
        {dropDown}
        <b>{pages.length}</b>
        {
        isFetching ? <div>Loading...</div> : (
          <div>
            {this.state.displayedEntities}
          </div>
        )
        }
      </div>
    )
  }
}
// {paginationBasic}
// {this.state.eddie}
// {entities_size}
//{this.state.displayedEntities}
//{this.displayEntities(entities)}

const getPages = (entities, page_size, posts) => {
  // let total_pages = Math.round(Object.keys(entities).length/page_size)
  let pages = {}
  let current_page = 1
  pages[current_page] = []

  console.dir(entities)
  console.log("getPages:" + Object.keys(entities).length)
  const entity_keys = Object.keys(entities)
  if(Object.keys(entities).length > 0){
    for(let i = 0; i < entity_keys.length; i++){
      if(pages[current_page].length == page_size){
        current_page++
        pages[current_page] = []
      }
      // console.log("current_page: " + current_page + ", entity: " + entities[entity_keys[i]]["name"])
      // pages[current_page].push(entities[entity_keys[i]]["name"])
      // console.log("current_page: " + current_page + ", entity: " + entity_keys[i])
      pages[current_page].push(entity_keys[i])
      // const entity = entities[i]
      // if(entities.fields.entity in pages == false) {
      //   pages[entities.fields.entity] = {}
      // }
      // // console.log("datatype in posts: " + datatypes[post.fields.datatype_id]["datatype"])
      // pages[entities.fields.entity][datatypes[entities.fields.datatype_id]["datatype"]] = entities.fields.value_text
    }
  }

  let sorted_pages = {}
  current_page = 1
  sorted_pages[current_page] = []

  if(posts.length > 0) {
    for (let i = 0; i < posts.length; i++) {
      const post = posts[i]
      if(sorted_pages[current_page].length == page_size){
        current_page++
        sorted_pages[current_page] = []
      }
      sorted_pages[current_page].push(post.fields.entity)
  //     // if (post.fields.entity in grid == false) {
  //     //   grid[post.fields.entity] = {}
  //     // }
    }
  }

  return sorted_pages
}


const getEntity = (entity, list_datatype) => {
  // address: 100 Wardour Street
  // address_2: Soho
  // local_authority: Westminster
  // region: London
  // county: Greater London
  // country: England
  // post_code: W1F 0TN

  // phone: +44(0)20 3732 0232
  // website: www.100wardourst.com

  // type: Bar
  // capacity: 421

  // latitude: 51.513675
  // longitude: -0.133952
  console.log("getEntity")
  console.dir(entity)
  var linebreaks = ["phone", "website", "type", "capacity"];
  var labels = ["type", "capacity"];
  const regex = /^http/;
   return (
    <div className="card">
      <div className="card-body">
        <h5 className="card-title">{entity[list_datatype]}</h5>
      {
        // Object.keys(entity).filter(datatype => datatype != "name").map((datatype) => {
        //   ["address", "address_2", "local_authority", "region", "county", "country", "post_code", "phone", "website", "type", "capacity"].filter(datatype => datatype in entity).map((datatype) => {
        //     let linebreak = linebreaks.includes(datatype) ? <br/> : "";
        //     let label = labels.includes(datatype) ? datatype + ": " : "";
        //     let value = entity[datatype]
        //     if(datatype == "website") {
        //       let linkvalue = value
        //       if(!value.match(regex)){
        //         linkvalue = "http://" + value
        //       }
        //       value = <a href={linkvalue} target="_blank">{value}</a>
        //     }
        //     return <span key={datatype}>{linebreak} {label} {value}<br/></span>
        //   }
        // )
      }
      </div>
    </div>
  )
}

// const getDropdown = (entities, pages, page_num) => {
//   console.log("getdrop" + page_num)
//   console.dir(pages)
//   return (
//   <Dropdown>
//     <Dropdown.Toggle variant="success" id="dropdown-basic">
//       Showing {page_num}
//     </Dropdown.Toggle>
//
//     <Dropdown.Menu>
//       {
//         (
//         Object.keys(pages).map((number) => {
//           // console.log("keys: " + Object.keys(pages[number]))
//           console.log("redropdown " + number )
//           return <Dropdown.Item href="#/{number}" key={number} onClick={() => this.handleSelect(number, entities, pages)}>{number}</Dropdown.Item>
//         })
//         )
//       }
//       <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
//       <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
//       <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
//     </Dropdown.Menu>
//   </Dropdown>
//   )
// }


const displayEntities = (entities, pages, page_num, list_datatype) => {
// displayEntities (entities) {
//   this.setState({
//     displayedEntities: (
//   return this.state.displayedEntities == null ? (
  console.log("entities:")
  console.dir(entities)
  return (
      <div>
        {
          entities.isEmpty ? "empty" : (
            // Object.keys(entities).filter(entity => entity in pages[page_num]).map((entity) => {
            pages[page_num].map((entity) => {
                // console.log(entities[entity][list_datatype])
              // return <div key={entities[entity][list_datatype]}>{getEntity(entities[entity], list_datatype)}</div>
              return <div key={entity}>{getEntity(entities[entity], list_datatype)}</div>
              }
            )
          )
        }
      </div>
    )
    // : ""
  // })
}

const getPosts = (posts, datatypes, value_type) => {
  let grid = {};
  let sorted_grid = {};
  console.log("posts_length: " + posts.length)
  if(posts.length > 0){
    for(let i = 0; i < posts.length; i++){
      const post = posts[i]
      if(post.fields.entity in grid == false) {
        grid[post.fields.entity] = {}
      }
      if(post.fields[value_type] in sorted_grid == false) {
        sorted_grid[post.fields[value_type]] = {}
      }
      // console.log("datatype in posts: " + datatypes[post.fields.datatype_id]["datatype"])
      // grid[post.fields.entity][datatypes[post.fields.datatype_id]["datatype"]] = post.fields.value_text
      grid[post.fields.entity][datatypes[post.fields.datatype_id]["datatype"]] = post.fields[value_type]
      sorted_grid[post.fields[value_type]][post.fields.entity] = post.fields[value_type]
    }
  }
  return [grid, sorted_grid]
}

const getDatatype = (datatype) => {
  return (
    <div>
      {
        Object.keys(datatype).map((field) => {
            return <span>{datatype[field]} </span>
          }
        )
      }
    </div>
  )
}

const displayDatatypes = (datatypes) => {
  return (
    <div>
      {
        datatypes.isEmpty ? "empty" : (
          Object.keys(datatypes).map( (datatype) => {
              // console.log("datatype: " + datatype)
              return <div key={datatype}>{getDatatype(datatypes[datatype])}</div>
            }
          )
        )
      }
    </div>
  )
}

const getDatatypes = (datatypes) => {
  let grid = {};
  if(datatypes.length > 0){
    for(let i = 0; i < datatypes.length; i++){
      const datatype = datatypes[i]
      if(datatype.pk in grid == false) {
        grid[datatype.pk] = {}
      }
      const clean_datatype = datatype.fields.datatype
      const regex = /^:-:|:-:$/g;
      // console.log(clean_datatype.replace(regex, ''));

      grid[datatype.pk]["datatype"] = clean_datatype.replace(regex, '')
      grid[datatype.pk]["fieldtype"] = datatype.fields.fieldtype
      // console.log("id" + datatype.pk + ", datatype: " + datatype.fields.datatype + ", fieldtype: " + datatype.fields.fieldtype)
    }
  }
  return grid
}

const mapStateToProps = (state, ownProps) => {
  // let page_num = ownProps.match.params.page_num;
  return {
    posts: state.posts,
    // page_num: page_num,
  }
}

export default connect(mapStateToProps)(VenuePostCodes)