import React, {Component} from 'react'
import {Link} from 'react-router-dom'
import {connect} from 'react-redux'
import axios from 'axios'
import {HomeHelper} from "./HomeHelper"
import {getNewsItems} from "./NewsItems";
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Pagination from 'react-bootstrap/Pagination'
import Dropdown from 'react-bootstrap/Dropdown'
import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'
import concert from '../concert.jpg'
import guitarcloseup from '../guitar-closeup.jpg'
import guitarcloseup_bw from '../guitar-closeup-bw.jpg'
import guitars_bw from '../guitars-bw.jpg'
import newspapers from '../newspapers.jpg'
import the_beatles from '../the-beatles.jpg'
import badlydrawnboybananaskinshoes from '../badlydrawnboybananaskinshoes.jpg'
import '../index.css'
import BalanceText from 'react-balance-text'


class BadlyDrawnBoyBananaSkinShoes extends Component {
  constructor() {
    super()
  }

  render() {
    const card_style = {
      // width: '18em',
      display: 'inline-block',
      margin: '1em',
    };

    const card_style_double = {
      // width: '38em',
      display: 'inline-block',
      margin: '1em',
    };

    const img_style = {
      height: '4em',
    };

    const img_style_double = {
      /* height: '8em', */
    };

    const home_button = {
      backgroundColor: '#555555',
    };

    const align_left = {
      textAlign: 'left',
    }

    const review_sub_title = {
      color: '#555555',
      fontWeight: 'bold',
      fontSize: '1em',
      margin: '15px 0px 3px 0px',
    }

    return (
          <Col sm={6}>
            <Row>
              <Col sm={12}>
                  <Card style={card_style_double}>
                    <Card.Img variant="top" src={badlydrawnboybananaskinshoes} alt="Badly Drawn Boy Banana Skin Shoes album cover" style={img_style_double}/>
                    <Card.Body style={align_left}>
                      <Card.Title style={align_left}>Review: <i>Banana Skin Shoes</i> by Badly Drawn Boy</Card.Title>
                      <Card.Text>
                        <BalanceText style={align_left}><b>Overall:</b>  The title song starts the album out with some fun and then styles from previous albums come back on later songs. He is always pleasant to listen to and this album is no different. There are always some melodies on his albums which stick in my head. He has cleaned up his act after getting remarried and ends by saying he wants to make his new wife happy. Hopefully he doesn't put pressure on himself and releases his next album sooner rather than later. </BalanceText>

                        <div style={review_sub_title}>Banana Skin Shoes</div>
                        <div>
                          <BalanceText style={align_left}>
                            <p>Right off the bat it hits you with a wail and some organized chaos but then it goes into a funky groove. A great way to return after being gone a long time. I like when he talks and imperceptibly morphs into singing.</p>
                            <p>You can tell by the initial sounds he's trying something new and he's not hiding it: "It's time to break free from this plaster cast and leave your past behind." He's not going to get in his own way anymore. Banana skins are slippery and you'll fall and you only have yourself to blame if you wear them as shoes - so stop doing that and "walk across town whistling the blues." I don't know what the "ammonia" is all about but I like it. He stopped drinking either completely or not as much and this is reflected in "Press stop - it's time to kick the habit." More on this in "I Just Wanna Wish You Happiness."</p>
                          </BalanceText>
                        </div>


                        <div style={review_sub_title}>Is This a Dream?</div>
                        <div>
                          <BalanceText style={align_left}>
                            <p>The quivering underwater sound at the beginning would have fit on <i>The Hour of Bewilderbeast</i>. Then it becomes a little faster paced like he would do on later albums. The chorus could almost be a Coldplay song. It has a fun Monty Python-ish video.</p>
                            <p>It's hard to find meaning (or THE meaning) in life - "you find it comical, not in a way that makes you laugh." Sometimes you need to just step away (or float away in a hot air balloon) and look at life from a distance. "It's like a disease we all catch in our sleep" is a pretty funny description of life. He was putting pressure on himself to put out a great album after such a long absence: "we gotta stop waiting for the apple to fall" - if you want to change something you have to take some sort of action and he has delivered an album at last. I love that he finds an old letter from Bono and it makes him feel good - I like finding old things from people and a letter from Bono would be pretty cool.</p>
                          </BalanceText>
                        </div>


                        <div style={review_sub_title}>I Just Wanna Wish You Happiness</div>
                        <div>
                          <BalanceText style={align_left}>
                            <p>Another opening few seconds which would fit on <i>Bewilderbeast</i> but then it turns into one of his pretty ballad-type songs from later albums. The songs of his that sound like this are sort of 70s. Very pleasant.</p>
                            <p>His wife left him because he drank too often yet he still wishes her happiness. "I don't remember falling out of love yet the bolt's undone." They have both moved on and based on Banana Skin Shoes it sounds like he has kicked the habit. Sometimes ending a relationship to avoid problems can lead to bigger pain of not having the person at all: "Be careful what you wish for."</p>
                          </BalanceText>
                        </div>


                        <div style={review_sub_title}>I'm Not Sure What It Is</div>
                        <div>
                          <BalanceText style={align_left}>
                            <p>Again he has an intro of a few seconds before going into the song - this time it's sort of psychedelic before he starts singing. A fun skippy drum beat goes along with the singing. He sort of sings like Jackson Browne on this sometimes. There's a short solo unlike anything I've heard - almost Wings-like.</p>
                            <p>This has some lines about his new wife - "I'm so blessed to have you by my side." But he covers other things as well. "Spent half my life sleeping and the second half awake." He said he used to dream of playing stadiums but was relieved it didn't happen because he may not have handled it. He's appreciating things more as he gets wiser: "There are days where I remember to exist." Earlier, on "Banana Skin Shoes" he hits Play, not rewind or fast forward - he's appreciating the "now" more.</p>
                          </BalanceText>
                        </div>


                        <div style={review_sub_title}>Tony Wilson Said</div>
                        <div>
                          <BalanceText style={align_left}>
                            <p>This has a disco beat as some of his songs have in the past. But then a melody sung on top of it so it's not just simple disco.</p>
                            <p>Tony Wilson was a cheerleader for Manchester culture - he was part owner of Factory Records and played by Steve Coogan in <i>24 Hour Party People</i>. He also started the Hacienda nightclub, was a TV presenter, and was the first to show the Sex Pistols on TV. Sadly Wilson could not get the medicine he needs covered for his renal cancer but friends from his music past helped. He succombed in 2007. I took a Google Maps tour of all the Manchester places named in this song (most pretty close together). I love when people specify places in songs - I wish people did this more - Ben Watt did it on his recent album.</p>
                          </BalanceText>
                        </div>


                        <div style={review_sub_title}>You and Me Against the World</div>
                        <div>
                          <BalanceText style={align_left}>
                            <p>This has a South American rhythm to it. It's a pretty melody that he sings. </p>
                            <p>He seems like he would be a good friend - "I want to know how you feel." It reminds me of "Easy Love" when he's happy a friend felt like he could ask of his time. The title says it all really.</p>
                          </BalanceText>
                        </div>


                        <div style={review_sub_title}>I Need Someone To Trust</div>
                        <div>
                          <BalanceText style={align_left}>
                            <p>This seems like it could be a Neil Finn song. That's a huge compliment. Another huge compliment would be this could be a Badly Drawn Boy song.</p>
                            <p>He's realizing a lot things about life on this album. He doesn't want too much - just some honesty and someone who will be there - and he'll be there for them: "Where the river bends/You know that you're bound to fall in/Well if this should happen/keep a grip of my hand." Again he's saying to live for the moment and something more people need to learn: "the best kind of self defense is when you learn to love yourself." Then nothing anybody says or does can really stress you out.</p>
                          </BalanceText>
                        </div>


                        <div style={review_sub_title}>Note To Self</div>
                        <div>
                          <BalanceText style={align_left}>
                            <p>It starts with a typewriter - he always has to have a few seconds of intro! Then a nice acoustic guitar comes in before he sings. Again this sounds 70s - then he goes into a beautiful minor key - I love it. It sounds a little like recent Belle and Sebastian at parts - and there's a hint of John Lennon in this one.</p>
                            <p>This sounds more like a note to his kids (he has 3 of them) than a note to himself. "Common sense and self-belief could get us halfway there/Maybe once in a while won't hurt to say a little prayer." Again he shows himself to be empathetic to others: "Always be good to the people we meet/When we have no clue what they're going through." Imagine the world if more people thought like this more often - the unnecessary squabbles that could be avoided!</p>
                          </BalanceText>
                        </div>


                        <div style={review_sub_title}>Colours</div>
                        <div>
                          <BalanceText style={align_left}>
                            <p>The opening music sounds 80s after he says "I see colours." Keyboards and horns and staccato sections like when he says "It melts before you get to the taste." There are parts of this that sound like Belle and Sebastian just like the previous song.</p>
                            <p>He doesn't feel the same as the other person - they are sometimes opposite - yet they combine to make something good. "Purple is the only child of blue and red." It almost sounds like their love is never going to happen though since he compares it to candy floss: "It melts before it gets to the taste." Maybe it will work out for him though.</p>
                          </BalanceText>
                        </div>


                        <div style={review_sub_title}>Funny Time of Year</div>
                        <div>
                          <BalanceText style={align_left}>
                            <p>The opening beat reminds me of "Kokomo." But then an About A Boy era sounding piano comes in. The way he says "This time of year" sounds like it could be a Christmas song. It's a pretty song.</p>
                            <p>Since it sounds Christmassy to me I'm thinking it's the holidays. Especially if he's reflecting on the past year: "at least we failed in style." As is the theme on this album (and other songs in his career) he wants to be a good friend: "I don't wanna let you down...not at this funny time of year." </p>
                          </BalanceText>
                        </div>

                        <div style={review_sub_title}>Fly on the Wall</div>
                        <div>
                          <BalanceText style={align_left}>
                            <p>A mellow bouncy keyboard starts this off and continues through the song - a little bit Richard Ashcroft solo music sound to it.</p>
                            <p>This must be a message to his kids - "you've got my genes...guess that means you're gonna be fine." He won't be a helicopter parent - but he'll be happy if they come around: "turn my grey skies back to sunshine." He'll be there to listen to any problems and not preach but he tells them not to "wait for a savior" - he'll just be a "fly on the wall."</p>
                          </BalanceText>
                        </div>

                        <div style={review_sub_title}>Never Change</div>
                        <div>
                          <BalanceText style={align_left}>
                            <p>The piano and string sounds again remind me of Richard Ashcroft. It also has hints of late 90s Poi Dog Pondering. It's a nice song.</p>
                            <p>The only time he says "never change" is when he says you can "never change" the past. Based on the title you would think that he's complaining about somebody who never changes but it's quite the opposite. "The future's there for you to change" and as he's letting you know in case you had any doubt from previous songs "I'll be walking alongside." If you don't take chances and do something to affect the future, you'll never be disappointed and you'll never fail but you won't do much: "If you never lived, you'll never die." It ends with him buying the first few rounds - I hope he doesn't go back to drinking to the point of not making albums again.</p>
                          </BalanceText>
                        </div>

                        <div style={review_sub_title}>Appletree Boulevard</div>
                        <div>
                          <BalanceText style={align_left}>
                            <p>This is one his flowing songs with acoustic guitar and steady beat which never gets too loud but other sounds are thrown in throughout the song. A nice piano solo in the middle.</p>
                            <p>A lot of themes through the album are brought up again in this song. He again refers to tears falling to the ground. There must have been some sadness in the many years since his last album. The fruit falling from the tree is another reference he repeats - perhaps alluding to having children and what he has to think about now. "The sands of time...blur the edges of our dreams." Again, what you may think you want now may not really be what reality has in store for you. </p>
                          </BalanceText>
                        </div>

                        <div style={review_sub_title}>I'll Do My Best</div>
                        <div>
                          <BalanceText style={align_left}>
                            <p>This has an island feel to it even though the previous song mentioned an "island breeze." He sings it like an older songwriter - the kind of song someone would sing at a piano bar.</p>
                            <p>He may have thought about himself too much earlier in his life but now he'll try harder. Hints of drinking causing past problems: "It's hard to start a fire when it rains" and "I've been a fool for chasing happiness when it was there all along." But he's not going to ignore his wife's happiness: "I've gotta try to make you happy."</p>
                          </BalanceText>
                        </div>



                        <div><i>Released: 22 May, 2020</i></div>


                      </Card.Text>
                    </Card.Body>
                  </Card>
              </Col>
            </Row>

          </Col>
    )
  }
}

const getNewsItem = (title, link) => {
  return (
    <a href = {link}><div class="newslinks">{title}</div></a>
  )
}

const mapStateToProps = (state, ownProps) => {
  // let page_num = ownProps.match.params.page_num;
  return {
    posts: state.posts,
    // page_num: page_num,
  }
}

export default connect(mapStateToProps)(BadlyDrawnBoyBananaSkinShoes)