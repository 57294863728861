import React, {Component} from 'react'
import {Link} from 'react-router-dom'
import {connect} from 'react-redux'
import axios from 'axios'
import {HomeHelper} from "./HomeHelper"
import {getNewsItems} from "./NewsItems";
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Pagination from 'react-bootstrap/Pagination'
import Dropdown from 'react-bootstrap/Dropdown'
import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'
import concert from '../concert.jpg'
import guitarcloseup from '../guitar-closeup.jpg'
import guitarcloseup_bw from '../guitar-closeup-bw.jpg'
import guitars_bw from '../guitars-bw.jpg'
import newspapers from '../newspapers.jpg'
import the_beatles from '../the-beatles.jpg'
import thebigmoonwalkinglikewedo from '../thebigmoonwalkinglikewedo.jpg'
import '../index.css'
import BalanceText from 'react-balance-text'


class TheBigMoonWalkingLikeWeDo extends Component {
  constructor() {
    super()
  }

  render() {
    const card_style = {
      // width: '18em',
      display: 'inline-block',
      margin: '1em',
    };

    const card_style_double = {
      // width: '38em',
      display: 'inline-block',
      margin: '1em',
    };

    const img_style = {
      height: '4em',
    };

    const img_style_double = {
      /* height: '8em', */
    };

    const home_button = {
      backgroundColor: '#555555',
    };

    const align_left = {
      textAlign: 'left',
    }

    const review_sub_title = {
      color: '#555555',
      fontWeight: 'bold',
      fontSize: '1em',
      margin: '15px 0px 3px 0px',
    }

    return (
          <Col sm={6}>
            <Row>
              <Col sm={12}>
                  <Card style={card_style_double}>
                    <Card.Img variant="top" src={thebigmoonwalkinglikewedo} alt="The Big Moon Walking Like We Do album cover" style={img_style_double}/>
                    <Card.Body style={align_left}>
                      <Card.Title style={align_left}>Review: <i>Walking Like We Do</i> by The Big Moon</Card.Title>
                      <Card.Text>
                        <BalanceText style={align_left}><b>Overall:</b>  Many songs end with repeating harmonies and little riffs - I like how the songs end. I hear hints of Beach House and Bird of Youth. There are other bands that pop up as well as occasional dips into the 80s. Pleasant album.</BalanceText>

                        <div style={review_sub_title}>It's Easy Then</div>
                        <div>
                          <BalanceText style={align_left}>
                            <p>There are nice dreamy harmonies here - they sound like they could be Swedish (hint of Abba) or from Baltimore like Beach House. </p>
                            <p>She's "bored of being capable" and wants to be "vulnerable." She may be bored with a secure love and goes after someone who makes her feel insecure: she takes "everything straight to the heart." She's nervous about what's going to happen next. A comedic sidenote: if you google "The Big Moon It's Easy Then lyrics" the default google page shows you lyrics that say "braced for an opening chattle to swallow me up" instead of an opening "trapdoor." In no sense would chattle make sense unless it's a misspelling of chattel which can be defined as a slave which would maybe make some sense if she's searching for someone to make her feel secure again by being subservient to her but I digress. Back to the real lyrics, it seems she's found someone to make her feel insecure - a rich person who can entertain a new girl all the time: "all the money you make...one weekend in the sun." </p>
                          </BalanceText>
                        </div>

                        <div style={review_sub_title}>Your Light</div>
                        <div>
                          <BalanceText style={align_left}>
                            <p>The chorus of "It's Easy Then" seemed 80s and this song starts out pretty 80s before the singing starts. So far the first two songs end with great harmonies.</p>
                            <p>This sounds like someone she may be bored of in the first song. The person says "sorry" and she asks "why do you have to take it out so hard on yourself?" But is she bored? The person gives her light - and she wishes "what you're doing to me...I could do it for you." </p>
                          </BalanceText>
                        </div>

                        <div style={review_sub_title}>Dog Eat Dog</div>
                        <div>
                          <BalanceText style={align_left}>
                            <p>The opening piano and singing sounds like it could be a Deerhunter song. Later it sounds like it could be Bird of Youth.</p>
                            <p>This could be an environmental song. "I guess tailored suits don't grow on trees." The trees are paying for the greed. People are "tryin' to get the things we need" but maybe some want more than that if they need tailored suits. And maybe it's not so obvious and little things add up. It's not dog eat dog, "It's more like pigeon eat fried chicken." London is a financial center and "this town walks all over you" and "there's always someone out there screwin' somebody." We shut our eyes to it and "only see the things we want to see." But ignore it long enough and it will bite you in the ass: "You only build bridges when the river wets your feet."</p>
                          </BalanceText>
                        </div>

                        <div style={review_sub_title}>Why</div>
                        <div>
                          <BalanceText style={align_left}>
                            <p>Sometimes it sounds 80s and sometimes like Beach House. When she ends a line going down she can sound like Liz Phair, like on the opening line when she ends it going down on the word "seaside."</p>
                            <p>It sounds like an awkward moment which should have been romantic. They're at the beach and she's "lookin' for the stars from the backseat of your car." But the person is being uncomfortably quiet: "When did you get shy?" Like people in "Dog Eat Dog" ignoring issues that will eventually cause them problems: "I just played dumb."</p>
                          </BalanceText>
                        </div>

                        <div style={review_sub_title}>Don't Think</div>
                        <div>
                          <BalanceText style={align_left}>
                            <p>This sometimes has a sound of Belle and Sebastian's more recent albums. It also can sound like Franz Ferdinand. So the song is Scottish.</p>
                            <p>She is singing about a person that ignores the subtle and goes after the cheap. Right off the bat: "avoiding your shy mates and traveling in the wake of the loud ones." This person is seeking approval from the wrong people and won't make a move from the heart. "Don't think about it now or your head will talk you out of it." The person can't be happy with just what they need - they seek more from the city and the loud opinionated people. "'cause the rest is everything." They'll never feel approved.</p>
                          </BalanceText>
                        </div>

                        <div style={review_sub_title}>Waves</div>
                        <div>
                          <BalanceText style={align_left}>
                            <p>The beginning sounds like the end of 99 Red Balloons. It's a pretty song. The melody sometimes sounds like it could be Travis song (another Scottish band!) </p>
                            <p>She was convinced the person was into her but didn't notice as the person slowly became colder and more distant. It's a change that is hard to notice but has big effects: "I never saw the tide comine, I only saw the waves." She finally realized the truth: "You had me going for a minute there." </p>
                          </BalanceText>
                        </div>

                        <div style={review_sub_title}>Holy Roller</div>
                        <div>
                          <BalanceText style={align_left}>
                            <p>The chorus sounds like something Elvis Costello would sing. The verses sound like John Lennon solo.</p>
                            <p>Is religion taking advantage of the poor? "Payday loans will build an altar." How about the naive and confused? This new religion will go after "all the kids with no future." Is there anyone else we can convince? "Our data trails, long, will never die." Our religion can find patterns of people based on data and we can convince them we know what they need. What will we promise them? "Our paradise is golden lit like porno sites and contour kits." I didn't know what a contour kit was - it's makeup.</p>
                          </BalanceText>
                        </div>

                        <div style={review_sub_title}>Take a Piece</div>
                        <div>
                          <BalanceText style={align_left}>
                            <p>This sounds different from other songs on the album. It sounds like a pop song on the radio or maybe something you'd here in a clothing store.</p>
                            <p>In this one she is clearly unsure of herself. She is in a relationship that she thinks is everything but the other person sees it as just another relationship: "Put me on the pages of your life story and I'll give you everything until there's nothing left." She's the only one who is unaware of her situation: "Everybody knows the deal - they watch and I burn." This time the other person is her light again, but this time she's a little more pathetic than in "Your Light."</p>
                          </BalanceText>
                        </div>

                        <div style={review_sub_title}>Barcelona</div>
                        <div>
                          <BalanceText style={align_left}>
                            <p>The drum beat sounds like early 90s Manchester sound and the guitar a little bit. I like harmonies at the end as usual.</p>
                            <p>She's at a friend's going away party - the friend is off to Barcelona, ready to have a kid. The friend has a beautiful house with a pond as a result of Bitcoin finally paying off. This is the first song I've heard referring to cryptocurrency. Sometimes if someone else advances you may feel a little left behind: "Will I toast her future and drink heavy for mine?"</p>
                          </BalanceText>
                        </div>

                        <div style={review_sub_title}>A Hundred Ways to Land</div>
                        <div>
                          <BalanceText style={align_left}>
                            <p>This has hints of Bird of Youth. They add some Beach Boys woo-ooo's in there too. Nice harmonies and this time some horns added at the end.</p>
                            <p>Life is not smooth sailing - when you fall "there's a hundred ways to land." What keeps you going when the summer is over? "When the leaves drop down it doesn' mean the trees are dead." It sounds like a song about the end of a relationship: "You're alone again." Is she feeling pressure as she gets older and more stressed with each breakup? "I don't care if it's snowing even though it's almost June." Just keep going. This has been a theme of some songs I've reviewed lately. It doesn't hurt to repeat this message once in a while.</p>
                          </BalanceText>
                        </div>

                        <div style={review_sub_title}>ADHD</div>
                        <div>
                          <BalanceText style={align_left}>
                            <p>A nice ballad which, on the slower parts, sounds like it could be on Modern Life Is Rubbish. The horns and woo-ooo's also sounds Blur-ish. </p>
                            <p>A ballad about ADHD? Perhaps a misdiagnosis because the grownups didn't want to deal with other issues? "I was feeling focused way before you does us up on Ritalin." The fact that it's plural makes it sound like it was a blanket solution to any problems with kids. A bad home life causing problems and pointing the finger at the kids' ADHD? "Is it you or is it me? I don't have to think - I know."</p>
                          </BalanceText>
                        </div>



                        <div><i>Released: 10 Jan, 2020</i></div>


                      </Card.Text>
                    </Card.Body>
                  </Card>
              </Col>
            </Row>

          </Col>
    )
  }
}

const getNewsItem = (title, link) => {
  return (
    <a href = {link}><div class="newslinks">{title}</div></a>
  )
}

const mapStateToProps = (state, ownProps) => {
  // let page_num = ownProps.match.params.page_num;
  return {
    posts: state.posts,
    // page_num: page_num,
  }
}

export default connect(mapStateToProps)(TheBigMoonWalkingLikeWeDo)