import React, {Component} from 'react'
import {Link} from 'react-router-dom'
import {connect} from 'react-redux'
import axios from 'axios'
import {HomeHelper} from "./HomeHelper"
import {getNewsItems} from "./NewsItems";
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Pagination from 'react-bootstrap/Pagination'
import Dropdown from 'react-bootstrap/Dropdown'
import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'
import concert from '../concert.jpg'
import guitarcloseup from '../guitar-closeup.jpg'
import guitarcloseup_bw from '../guitar-closeup-bw.jpg'
import guitars_bw from '../guitars-bw.jpg'
import newspapers from '../newspapers.jpg'
import the_beatles from '../the-beatles.jpg'
import porridgeradioeverybad from '../porridgeradioeverybad.jpg'
import '../index.css'
import BalanceText from 'react-balance-text'


class PorridgeRadioEveryBad extends Component {
  constructor() {
    super()
  }

  render() {
    const card_style = {
      // width: '18em',
      display: 'inline-block',
      margin: '1em',
    };

    const card_style_double = {
      // width: '38em',
      display: 'inline-block',
      margin: '1em',
    };

    const img_style = {
      height: '4em',
    };

    const img_style_double = {
      /* height: '8em', */
    };

    const home_button = {
      backgroundColor: '#555555',
    };

    const align_left = {
      textAlign: 'left',
    }

    const review_sub_title = {
      color: '#555555',
      fontWeight: 'bold',
      fontSize: '1em',
      margin: '15px 0px 3px 0px',
    }

    return (
          <Col sm={6}>
            <Row>
              <Col sm={12}>
                  <Card style={card_style_double}>
                    <Card.Img variant="top" src={porridgeradioeverybad} alt="porridge radio every bad album cover" style={img_style_double}/>
                    <Card.Body style={align_left}>
                      <Card.Title style={align_left}>Review: <i>Ever Bad</i> by Porridge Radio</Card.Title>
                      <Card.Text>
                        <BalanceText style={align_left}><b>Overall:</b>  Dana Margolin is confused and lets us know - and I don't think enough people admit they were "born confused." Nobody knows what's coming next - things are complicated. This is a great album and I hear a lot of Robert Smith when I hear her sing, but there are other influences and many original sounds.</BalanceText>

                        <div style={review_sub_title}>Born Confused</div>
                        <div>
                          <BalanceText style={align_left}>
                            <p>Right off the bat Dana sounds like a female Car Seat Headrest. Then the chorus really sounds like The Cure and it even seems like something Robert Smith would say: "Thank you for leaving me, Thank you for making me happy."</p>
                            <p>She got dumped and "everyone I know is down in the dumps about you now...but I'm not." She's the only one not sad about it. Conclusion: "maybe I was born confused." Not enough people admit they don't know how the world works nor why they feel the way they do. Sometimes it's best to just feel happy even if you don't know why and be thankful that you are.</p>
                          </BalanceText>
                        </div>

                        <div style={review_sub_title}>Sweet</div>
                        <div>
                          <BalanceText style={align_left}>
                            <p>A lot of bands play off the Pixies, Nirvana, and Radiohead in going from quiet to loud, but here the loud parts are  short loud bursts in between verses - it seems so simple but I've never heard it done quite like that before. Dana reminds me of the Violent Femmes when she sings the "I am charming I am sweet" section.</p>
                            <p>Is her child self talking? "I am just a child writing letters to myself." But then she says "Wishing out loud you were dead" - uh oh that can't be good. Is she replying to her child self saying "You will like me when you meet me"? I've read some lyrics lately about people trying to escape their childhood exeriences - is she? "I slowly move away from everything I knew about you." But she concludes that "She will love me when she meets me I am charming I am sweet" so maybe she turned out ok after all.</p>
                          </BalanceText>
                        </div>

                        <div style={review_sub_title}>Don't Ask Me Twice</div>
                        <div>
                          <BalanceText style={align_left}>
                            <p>Drums at the beginning sound like Matt Cameron on pre-<i>Louder Than Love</i> Soundgarden albums. She's a little like Kim Gordon on this one. The refrain ("It's been a long, long, long time...") is very pleasant. The screaming in the third verse takes a page from the Pixies. Outro is similar to Born Confused's Cure outro.</p>
                            <p>Sounds like she's not over a relationship - "I'm still waiting for you to get out of my mind." Simple and effective description of loneliness: "I woke up, felt alone, I counted hours in my head." Again I have to respect admitting not knowing and she makes that very clear in the outro.</p>
                          </BalanceText>
                        </div>

                        <div style={review_sub_title}>Long</div>
                        <div>
                          <BalanceText style={align_left}>
                            <p>This song is straight from the 80s. The good 80s music. Teardrop Explodes? Duran Duran's first album? Early U2? I don't know but I love this song. </p>
                            <p>First her time is being wasted. It's no good having your time wasted by someone - maybe she was in a relationship and the person didn't care and it was all a waste of time and now she's on the other end and likes it. She's wasting the other person's time and "I'm glad it's not me."</p>
                          </BalanceText>
                        </div>

                        <div style={review_sub_title}>Nephews</div>
                        <div>
                          <BalanceText style={align_left}>
                            <p>The opening guitar sounds like it could be off <i>Modern Life is Rubbish</i>. She sounds like Robert Smith singing over late Beatles music in the chorus and outro.</p>
                            <p>Why two nephews? I guess you picture two children - you don't think of nephews as adults. And nephews are better because you are not responsible for them - so all the innocence and none of the stress - that's what she's shooting for - sounds like she wants to escape the problems of the world down deep in the sea "where your head might explode and the water's so dark that you can't fee your hear as it sinks." </p>
                          </BalanceText>
                        </div>

                        <div style={review_sub_title}>Pop Song</div>
                        <div>
                          <BalanceText style={align_left}>
                            <p>The guitar sounds like a simple Nick Cave song at the beginning and later has the sound of Pavement. Sometime she reminds me of PJ Harvey when she sings?</p>
                            <p>Dana doesn't want to come (go?) home. She's looking for somewhere safe again. Away from her thoughts? "I'm ugly deep inside...the waves keep rolling in." She wants to learn - what makes you feel safe?</p>
                          </BalanceText>
                        </div>

                        <div style={review_sub_title}>Give/Take</div>
                        <div>
                          <BalanceText style={align_left}>
                            <p>The guitar has that pleasant sound like Dinosaur Jr's "Feel The Pain". Here Dana sings a little like The Strokes, a little like Franz Ferdinand.</p>
                            <p>Is she talking from the other person's point of view when she says "I want want want and I always get what I need" since later she asks "how do I say no without sounding like a little bitch?" She's looking for more: "I like you and you like me but I've got other things that make me happy."</p>
                          </BalanceText>
                        </div>

                        <div style={review_sub_title}>Lilac</div>
                        <div>
                          <BalanceText style={align_left}>
                            <p>This song has a Throwing Muses sound. The little explosion at "I can never seem to find it" has a Radiohead sound to it.</p>
                            <p>She wants to be a good person. "I want you to be comfortable." But it's never easy. "I want to make you better but I don't know how to find it." To avoid failure, it's always good to keep your eye on the prize. Just repeat this a bunch of times: "I don't want to get bitter. I want us to get better. I want us to be kinder to ourselves and to each other."</p>
                          </BalanceText>
                        </div>

                        <div style={review_sub_title}>Circling</div>
                        <div>
                          <BalanceText style={align_left}>
                            <p>Dana reminds me of Bird of Youth on this one. </p>
                            <p>We want to escape reality and say that "we're all okay, all of the time" - we want to tell ourselves that. Maybe we go on vacation - maybe we just go in the sea if we live in Brighton. We try to be positive - "nothing is wrong, all of the time" and we keep saying it but then it hits us at the end "I am okay, all of the time, I am okay, some of the time."</p>
                          </BalanceText>
                        </div>

                        <div style={review_sub_title}>(Something)</div>
                        <div>
                          <BalanceText style={align_left}>
                            <p>I don't think I've ever heard a voice box used over an electronic hum without beats before.</p>
                            <p>Dana's partner sounds like they are patient with her - based on earlier songs it sounds like she can sometimes feel a touch down. "Sometimes I forget to love" is not a good sign. She's confused again and not sure what to do: "I tell you that I love you Even when I don't feel anything at all." </p>
                          </BalanceText>
                        </div>

                        <div style={review_sub_title}>Homecoming Song</div>
                        <div>
                          <BalanceText style={align_left}>
                            <p>Another 80s sounding song at the beginning but that ends when a louder drum comes in as she says she needs to go.</p>
                            <p>Earlier she said she's not coming home and now she says she's coming home. I love her confusion. She wanted to go to the bottom of the sea in "Nephews", wanted to to in the sea in "Circling", and is a sinking ship with nothing inside in this one. I think she has something inside - these songs are good - in the end she says "I'm coming home" - maybe she will find a safe place after all.</p>
                            <p>blah</p>
                          </BalanceText>
                        </div>

                        <div><i>Released: 13 March, 2020</i></div>



                      </Card.Text>
                    </Card.Body>
                  </Card>
              </Col>
            </Row>

          </Col>
    )
  }
}

const getNewsItem = (title, link) => {
  return (
    <a href = {link}><div class="newslinks">{title}</div></a>
  )
}

const mapStateToProps = (state, ownProps) => {
  // let page_num = ownProps.match.params.page_num;
  return {
    posts: state.posts,
    // page_num: page_num,
  }
}

export default connect(mapStateToProps)(PorridgeRadioEveryBad)