import React, {Component} from 'react'
import {Link} from 'react-router-dom'
import {connect} from 'react-redux'
import axios from 'axios'
import Pagination from 'react-bootstrap/Pagination'
import Dropdown from 'react-bootstrap/Dropdown'
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Card from "react-bootstrap/Card";
import the_beatles from "../the-beatles.jpg";

class Reviews extends Component {
  constructor() {
    super()
    const list_datatype = "name"
    const api_host = 'http://localhost' //'https://api.rockandroll.co.uk'
    this.state = {
      api_url2: api_host + '/datatypes/',
      current_page: 1,
      datatypes: [],
      eddie: "eddie",
      entities_size: 0,
      entity_url: api_host + '/details/rockandroll_venue/',
      list_datatype: list_datatype,
      list_url: api_host + '/list/rockandroll_venue/' + list_datatype + '/', // default is name
      list_value_type: "value_text",
      page_size: 30,
      pages: {},
      posts: [],
    }
    this.handleSelect.bind(this)
    this.redisplayEntities.bind(this)
    this.regetDropdown.bind(this)
  }

  componentDidMount() {
    const {params} = this.props.match
    if (params.reviewId) {

      axios.all([axios.get(this.state.entity_url + params.reviewId), axios.get(this.state.api_url2)])
        .then(
          axios.spread((...responses) => {
            const get_posts = getPosts(JSON.parse(responses[0].data), getDatatypes(JSON.parse(responses[1].data)), this.state.list_value_type)
            const entities = get_posts[0]
            const entities_size = Object.keys(entities).length
            const pages = getPages(entities, this.state.page_size, JSON.parse(responses[0].data))
            this.setState({
              posts: JSON.parse(responses[0].data),
              datatypes: JSON.parse(responses[1].data),
              displayedEntities: displayEntity(entities, params.reviewId),
              entities_size: entities_size,
              pages: pages,
            })
          })
        )
        .catch(errors => {
          console.error(errors);
        });

    } else {
      axios.all([axios.get(this.state.list_url), axios.get(this.state.api_url2)])
        .then(
          axios.spread((...responses) => {
            const get_posts = getPosts(JSON.parse(responses[0].data), getDatatypes(JSON.parse(responses[1].data)), this.state.list_value_type)
            const entities = get_posts[0]
            const entities_size = Object.keys(entities).length
            const pages = getPages(entities, this.state.page_size, JSON.parse(responses[0].data))
            this.setState({
              posts: JSON.parse(responses[0].data),
              datatypes: JSON.parse(responses[1].data),
              displayedEntities: displayEntities(entities, pages, 1, this.state.list_datatype),
              entities_size: entities_size,
              pages: pages,
            })
          })
        )
        .catch(errors => {
          console.error(errors);
        });
    }
  }

  regetDropdown(entities, pages, number) {
    this.setState({
      current_page: number,
    })
  }

  redisplayEntities(entities, pages, number) {
    this.setState({
      displayedEntities: (
        <div>
          {
            entities.isEmpty ? "emptyish" : (
              pages[number].map((entity) => {
                  return <div
                    key={entities[entity][this.state.list_datatype]}>{getEntity(entities[entity], this.state.list_datatype, entity)}</div>
                }
              )
            )
          }
        </div>
      )
    })
  }

  handleSelect(number, entities, pages) {
    this.setState({eddie: "eddie" + number})
    this.redisplayEntities(entities, pages, number)
    this.regetDropdown(entities, pages, number)
  }

  render() {
    const {params} = this.props.match
    const {posts, datatypes, entities_size, pages} = this.state
    const my_datatypes = getDatatypes(datatypes)
    const get_posts = getPosts(posts, my_datatypes, this.state.list_value_type)
    const entities = get_posts[0]
    const sorted_entities = get_posts[1]
    const {isFetching} = this.state;

    let active = 1;
    let items = [];
    let droplines = []

    for (let number = 1; number <= Object.keys(pages).length; number++) {
      items.push(
        <Pagination.Item key={number} active={number === active}
                         onClick={() => this.handleSelect(number, entities, pages)}>
          {number}
        </Pagination.Item>,
      );
      droplines.push(
        <Dropdown.Item href="#/{number}" key={number}
                       onClick={() => this.handleSelect(number, entities, pages)}>{entities[pages[number][0]][this.state.list_datatype].substring(0, 5) + " through " + entities[pages[number][pages[number].length - 1]][this.state.list_datatype].substring(0, 5)}</Dropdown.Item>
      )
    }

    const onFocus = () => {
      this.setState({
        boxShadow: 'white'
      })
    }
    const dropDown = (
      <Dropdown>
        <Dropdown.Toggle variant="secondary" id="dropdown-basic" onFocus={() => onFocus()}>
          Showing {Object.keys(entities).length > 0 ? entities[pages[this.state.current_page][0]][this.state.list_datatype] + " through " + entities[pages[this.state.current_page][pages[this.state.current_page].length - 1]][this.state.list_datatype] : ""}
        </Dropdown.Toggle>

        <Dropdown.Menu>
          {droplines}
        </Dropdown.Menu>
      </Dropdown>
    );

    // this.displayEntities(entities)
    const card_style_double = {
      width: '38em',
      display: 'inline-block',
      margin: '1em',
    };

    const entityList = {
      padding: '1.2em 0',
    }

    const img_style_double = {
      height: '8em',
    };

    const align_left = {
      textAlign: 'left',
    }


    return params.reviewId && entities[params.reviewId] != undefined ? (
      <Col sm={6}>
        <Col sm={6}>
          <Row>
            <Col sm={12}>
              <Card style={card_style_double}>
                <Card.Img variant="top" src={the_beatles} alt="concert" style={img_style_double}/>
                <Card.Body style={align_left}>
                  <Card.Title style={align_left}>{entities[params.reviewId]["name"]}</Card.Title>
                  <Card.Text>
                    {this.state.displayedEntities}
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>

        </Col>

      </Col>
    ) : (
      <Col sm={6}>
        <Row>
          <Col sm={12}>
            <Card style={card_style_double}>
              {dropDown}
              <b>{pages.length}</b>
              {
                isFetching ? <div>Loading...</div> : (
                  <div style={entityList}>
                    {this.state.displayedEntities}
                  </div>
                )
              }
            </Card>
          </Col>
        </Row>
      </Col>
    )
  }
}

const getPages = (entities, page_size, posts) => {
  let pages = {}
  let current_page = 1
  pages[current_page] = []
  const entity_keys = Object.keys(entities)
  if (Object.keys(entities).length > 0) {
    for (let i = 0; i < entity_keys.length; i++) {
      if (pages[current_page].length == page_size) {
        current_page++
        pages[current_page] = []
      }
      pages[current_page].push(entity_keys[i])
    }
  }

  let sorted_pages = {}
  current_page = 1
  sorted_pages[current_page] = []

  if (posts.length > 0) {
    for (let i = 0; i < posts.length; i++) {
      const post = posts[i]
      if (sorted_pages[current_page].length == page_size) {
        current_page++
        sorted_pages[current_page] = []
      }
      sorted_pages[current_page].push(post.fields.entity)
    }
  }

  return sorted_pages
}


const getEntity = (entity, list_datatype, entity_num) => {
  var linebreaks = ["phone", "website", "type", "capacity"];
  var labels = ["type", "capacity"];
  const regex = /^http/;

  const list_item = {
    color: 'black',
    margin: '1.1em',
  }
  const list_item_div = {
    textAlign: 'left',
  }

  const space_regex = /\s+/gi;
  let url_name = entity["name"].toLowerCase().replace(space_regex, '-')

  const href_value = "/reviews/" + entity_num + '/' + url_name
  return (<div style={list_item_div}><a href={href_value} style={list_item}>{entity[list_datatype]}</a></div>)
  return (
    <div className="card">
      <div className="card-body">
        <h5 className="card-title"><a href="/home2">{entity[list_datatype]}</a></h5>
      </div>
    </div>
  )
}

const getEntity2 = (entity) => {
  var linebreaks = ["phone", "website", "type", "capacity"];
  var labels = ["type", "capacity"];
  const regex = /^http/;

  return (
    <div className="card">
      <div className="card-body">
        <h5 className="card-title">{entity["name"]}</h5>
        {
          ["address", "address_2", "local_authority", "region", "county", "country", "post_code", "phone", "website", "type", "capacity"].filter(datatype => datatype in entity).map((datatype) => {
              let linebreak = linebreaks.includes(datatype) ? <br/> : "";
              let label = labels.includes(datatype) ? datatype + ": " : "";
              let value = entity[datatype]
              if (datatype == "website") {
                let linkvalue = value
                if (!value.match(regex)) {
                  linkvalue = "http://" + value
                }
                value = <a href={linkvalue} target="_blank">{value}</a>
              }
              return <span key={datatype}>{linebreak} {label} {value}<br/></span>
            }
          )
        }
      </div>
    </div>
  )
}


const displayEntity = (entities, entity) => {
  return (
    <div>
      {
        entities.isEmpty ? "empty" : (
          <div key={entity}>{getEntity2(entities[entity])}</div>
        )
      }
    </div>
  )
}


const displayEntities = (entities, pages, page_num, list_datatype) => {
  return (
    <div>
      {
        entities.isEmpty ? "empty" : (
          pages[page_num].map((entity) => {
              return <div key={entity}>{getEntity(entities[entity], list_datatype, entity)}</div>
            }
          )
        )
      }
    </div>
  )
}

const getPosts = (posts, datatypes, value_type) => {
  let grid = {};
  let sorted_grid = {};
  if (posts.length > 0) {
    for (let i = 0; i < posts.length; i++) {
      const post = posts[i]
      if (post.fields.entity in grid == false) {
        grid[post.fields.entity] = {}
      }
      if (post.fields[value_type] in sorted_grid == false) {
        sorted_grid[post.fields[value_type]] = {}
      }
      grid[post.fields.entity][datatypes[post.fields.datatype_id]["datatype"]] = post.fields[value_type]
      sorted_grid[post.fields[value_type]][post.fields.entity] = post.fields[value_type]
    }
  }
  return [grid, sorted_grid]
}

const getDatatype = (datatype) => {
  return (
    <div>
      {
        Object.keys(datatype).map((field) => {
            return <span>{datatype[field]} </span>
          }
        )
      }
    </div>
  )
}

const getDatatypes = (datatypes) => {
  let grid = {};
  if (datatypes.length > 0) {
    for (let i = 0; i < datatypes.length; i++) {
      const datatype = datatypes[i]
      if (datatype.pk in grid == false) {
        grid[datatype.pk] = {}
      }
      const clean_datatype = datatype.fields.datatype
      const regex = /^:-:|:-:$/g;
      grid[datatype.pk]["datatype"] = clean_datatype.replace(regex, '')
      grid[datatype.pk]["fieldtype"] = datatype.fields.fieldtype
    }
  }
  return grid
}

const mapStateToProps = (state, ownProps) => {
  return {
    posts: state.posts,
  }
}

export default connect(mapStateToProps)(Reviews)