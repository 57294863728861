import React, {Component} from 'react'
import {Link} from 'react-router-dom'
import {connect} from 'react-redux'
import axios from 'axios'
import {HomeHelper} from "./HomeHelper"
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Pagination from 'react-bootstrap/Pagination'
import Dropdown from 'react-bootstrap/Dropdown'
import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'
import concert from '../concert.jpg'
import guitarcloseup from '../guitar-closeup.jpg'
import guitarcloseup_bw from '../guitar-closeup-bw.jpg'
import guitars_bw from '../guitars-bw.jpg'
import newspapers from '../newspapers.jpg'
import '../index.css'
import BalanceText from 'react-balance-text'
import { TwitterTimelineEmbed, TwitterShareButton, TwitterFollowButton, TwitterHashtagButton, TwitterMentionButton, TwitterTweetEmbed, TwitterMomentShare, TwitterDMButton, TwitterVideoEmbed, TwitterOnAirButton } from 'react-twitter-embed';
import {getNewsItems, getNewsItemContent, getNewsItemTitle, getNewsItemLink} from "./NewsItems";


class News extends Component {
  constructor() {
    super()
  }

  render() {
    const { params } = this.props.match

    const card_style = {
      // width: '18em',
      display: 'inline-block',
      margin: '1em',
    };

    const card_style_double = {
      // width: '38em',
      display: 'inline-block',
      margin: '1em',
    };

    const img_style = {
      height: '4em',
    };

    const img_style_double = {
      height: '8em',
    };

    const home_button = {
      backgroundColor: '#555555',
    };

    const align_left = {
      textAlign: 'left',
    }

    return (
      <Row className="justify-content-md-center">
        <Col sm={3}>
          {getNewsItems()}
        </Col>

        <Col sm={6}>
              <Row>
                <Col sm={12}>
                  <Card style={card_style_double}>
                    <Card.Img variant="top" src={concert} alt="concert" style={img_style_double}/>
                    <Card.Body style={align_left}>
                      <Card.Title style={align_left}>{getNewsItemTitle(params.newsId)}</Card.Title>
                      {getNewsItemContent(params.newsId)}
                    </Card.Body>
                  </Card>
                </Col>
              </Row>

            </Col>
      </Row>
    )
  }
}
//                     <Card.Title style={align_left}><a href={getNewsItemLink(params.newsId)}>{getNewsItemTitle(params.newsId)}</a></Card.Title>
// // {getIframe(window.location.href.substring(window.location.href.lastIndexOf('/') + 1))}
// //                     {getIframe(params.newsId)}
// const getNewsItem = (title) => {
//   return (
//     <a href = ""><div class="newslinks">{title}</div></a>
//   )
// }
//
// const align_left = {
//   textAlign: 'left',
// }
//
// const getIframe = (fed) => {
//   const iframe_style = {
//     border: 'none',
//     overflow: 'hidden'
//   }
//   return (
//     <Card.Text>
//       <BalanceText style={align_left}>
//         Among other things, Madonna said COVID-19 doesn't care if you are old, but that may not be entirely accurate.
//         It seems like it does care based on the current statistics. It treats older people much more rudely.
//         It bullies people with asthma and other lung diseases more than others. It may not be The Great
//         Equalizer and Madonna apparently rethought it. Instead she played it a little safer Voguing about fried fish:
//       </BalanceText>
//       <br/>
//       <TwitterTweetEmbed
//         tweetId={'1241035131554615298'}
//       />
//     </Card.Text>
//   )
// }

const mapStateToProps = (state, ownProps) => {
  // let page_num = ownProps.match.params.page_num;
  return {
    posts: state.posts,
    // page_num: page_num,
  }
}

export default connect(mapStateToProps)(News)