import React, {Component} from 'react'
import {Link} from 'react-router-dom'
import {connect} from 'react-redux'
import axios from 'axios'
import {HomeHelper} from "./HomeHelper"
import {getNewsItems} from "./NewsItems";
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Pagination from 'react-bootstrap/Pagination'
import Dropdown from 'react-bootstrap/Dropdown'
import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'
import concert from '../concert.jpg'
import guitarcloseup from '../guitar-closeup.jpg'
import guitarcloseup_bw from '../guitar-closeup-bw.jpg'
import guitars_bw from '../guitars-bw.jpg'
import newspapers from '../newspapers.jpg'
import the_beatles from '../the-beatles.jpg'
import bccamplightshortlyaftertakeoff from '../bccamplightshortlyaftertakeoff.jpg'
import '../index.css'
import BalanceText from 'react-balance-text'


class BCCamplightShortlyAfterTakeoff extends Component {
  constructor() {
    super()
  }

  render() {
    const card_style = {
      // width: '18em',
      display: 'inline-block',
      margin: '1em',
    };

    const card_style_double = {
      // width: '38em',
      display: 'inline-block',
      margin: '1em',
    };

    const img_style = {
      height: '4em',
    };

    const img_style_double = {
      /* height: '8em', */
    };

    const home_button = {
      backgroundColor: '#555555',
    };

    const align_left = {
      textAlign: 'left',
    }

    const review_sub_title = {
      color: '#555555',
      fontWeight: 'bold',
      fontSize: '1em',
      margin: '15px 0px 3px 0px',
    }

    const blurry_text = {
      color: 'transparent',
      textShadow: '0 0 5px rgba(0,0,0,0.5)',
    }

    return (
          <Col sm={6}>
            <Row>
              <Col sm={12}>
                  <Card style={card_style_double}>
                    <Card.Img variant="top" src={bccamplightshortlyaftertakeoff} alt="BC Camplight Shortly After Takeoff album cover" style={img_style_double}/>
                    <Card.Body style={align_left}>
                      <Card.Title style={align_left}>Review: <i>Shortly After Takeoff</i> by BC Camplight</Card.Title>
                      <Card.Text>
                        <BalanceText style={align_left}><b>Overall:</b>  A very pleasant album with a variety of sounds. I hear bits of Sufjan Stevens, Ariel Pink, Yo La Tengo, Father John Misty and others. He can be funny but he also addresses some sadness instead of running away from it. He also talks about everyday things like shopping malls, Scottish soda and radiators.</BalanceText>

                        <div style={review_sub_title}>I Only Drink When I'm Drunk</div>
                        <div>
                          <BalanceText style={align_left}>
                            <p>This song tells me this album is going to sound pretty unique. Who would make a song like this? Maybe Sufjan Stevens. It starts slow and mellow with the American West ghostly guitar but takes many turns. The chorus picks up a bit and eventually come some nice horns. </p>
                            <p>He sounds like he may be funny - and a high ratio of funny musicians are good. And the music is all over the place. Being American I had to Google what Buckfast is (a caffeinated fortified wine made in Devon, England.) Picturing him dancing around his "kitchen singing Ace of Base" is ridiculous. If you do the logic, "I only drink when I'm drunk" means he's drunk all the time. It's confining him to his home. His mental health sometimes takes over his everyday thoughts. He can go from talking about trivial everyday things to deep questions and I'm thinking he's referring to depression as a black hole when he sings: "I called my friend to talk about kung fu and Irn Bru and if a black hole makes you better when it pulls you through." Again I had some Googling to do: Irn Bru is a Scottish soft drink.</p>
                          </BalanceText>
                        </div>

                        <div style={review_sub_title}>Ghosthunting</div>
                        <div>
                          <BalanceText style={align_left}>
                            <p>This one starts out like "True Happiness This Way Lies" by The The or "Bored in the USA" by Father John Misty with people laughing at sad things the singer is saying, but at least in this instance you find out he is laughing along with the crowd. Did the crowd really go quiet at the mention of being mentally ill or was that editing? Either way he's making the point people are uncomfortable merely at the mention of it. The chorus of "Ghosthunting" could be from a later Blur album. Some Ariel Pink type sounds emerge here as well.</p>
                            <p>Being a Chicagoan, I had to google Rachel Riley. She's a TV presenter and mathematician who married her dancing partner from a British celebrity dancing show. I found a YouTube clip of her talking about lending bras to (in the words of Dave Barry, I'm not making this up) lexicographer and etymologist Susie Dent. I looked at pictures of Arndale Center in Manchester hoping to see the smoothie shop he refers to. Poor guy never wants to smell mango as it reminds him of being told of a death in the family (his father based on some further reading.) At takeoff he regrets that in the past he "was a little shit to everyone there." The album is named Shortly After Takeoff so those regrets must weigh heavy on him. Does he really have a cousin who asked him if his music makes people dance? Is he metaphorically hunting ghosts of his past or did he really see a ghost since he says he has mental illness?</p>
                            <p>I could not find these lyrics online. So I'm writing what I heard here:</p>
                            <pre style={blurry_text}>{`
I thought I saw a ghost
It looked like Pavarotti
And moved like a caterpillar
And smiled like Rachel Riley
And when I told my mom what I saw
She said it probably was the cat

Friday I went to look at some cars
To further Laura's business plan
And we stopped off for a tropical drink
At the Arndale Center smoothie stand
When the phone rang my sister
She gave me the news
Now I never want to smell mango

Ghosthunting
Ghosthunting

Saturday I borrowed money
To fly to Philadelphia
When we took off
I remember that I
Was a little shit to everyone there

Ghosthunting
Ghosthunting

At the funeral my cousin
He asked me in small talk
Are you making the people dance?
I said sure
And thought to myself
Who does he think I am?
Tame Impala?

Ghosthunting
Ghosthunting
Ghosthunting
Ghosthunting
Ghosthunting
                            `}</pre>
                          </BalanceText>
                        </div>

                        <div style={review_sub_title}>Back to Work</div>
                        <div>
                          <BalanceText style={align_left}>
                            <p>Funny enough after the last song this starts off almost as a dance song. But then it turns into a more melodic song. Sometimes the music sounds a little Jayhawks in between the techno "Back to Work" chorus and the Southern Cal harmonies are Byrds-like and like early Who.</p>
                            <p>He is confined to his home again, watching Die Hard 2 for the 38th time, the sort of thing that makes you feel like you are not making the best use of your time. He needs to get "back to work." He wants to handle stress better and be "normal" although it's questionable that a normal person would "say some clever shit" when they're about to die. I don't know why the devil's wife is named Denise, but the devil and his wife worship him, perhaps because an idle mind is the devil's playground (too much time watching Die Hard 2.) "People scream in my ear...but the things I say to myself are even worse." That's true of a lot of people: sometimes criticism from others doesn't hurt as much as knowing your own insecurities. He tells his mom he wants to kill himself but she says he's 40 and it's "time to stop that shit." He tells her "I'm 41 and I've just begun." He's only just begun to kill himself? I hope this isn't a Purple Mountains situation - I hope he finds peace. </p>
                          </BalanceText>
                        </div>

                        <div style={review_sub_title}>Cemetery Lifestyle</div>
                        <div>
                          <BalanceText style={align_left}>
                            <p>The music has hints of late 70s/early 80s alt bands like Kraftwerk, Devo or Talking Heads. The chorus sounds like the Beach Boys but is hardly the content of what they would sing.</p>
                            <p>He made a deal with god - what deal did he make that makes him wake up in a Nando's parking lot in a banana suit he doesn't own? He's severely hungover (I'm guessing) and feeling like "death is following him around." He gives more hints that he's not making much of his life: he has tea and "it gets to be a quarter to three" and he's "about to go broke." Quick summary of the video: He leaves his basement, goes to a bar and wins alcohol and money from people by beating them at rock paper scissors. Amadeus version of death follows him around and when he returns to his basement death beats him at rock paper scissors (with actual big shears).</p>
                          </BalanceText>
                        </div>

                        <div style={review_sub_title}>I Want to Be in the Mafia</div>
                        <div>
                          <BalanceText style={align_left}>
                            <p>Partially a pretty piano ballad - one of the prettier ballads about the Mafia. When he sing-talks in his low voice he has hints of Ira Kaplan of Yo La Tengo - it's a pleasant sound. When he raises his voice it reminds me of Guster.</p>
                            <p>He's feeling pathetic and alone in the world - the Mafia is a metaphor for love since he says "Love is being connected." He's unsure if he would really fit into the Mafia - "Wonder if I'm a rat" - and maybe he is unsure if he would fit into a loving relationship. He also wants some security - "Get the boss on the phone." He's upset at his own self-doubt and wants to "put a hit out on my brain."  He dreams of being secure but realistically he would ask a bully "Should I make your bed?" - he's "just in between." Does he think he can't ever succeed? That if he does he must be hallucinating in an asylum? "If I ever get rich I know a henchman inside who'll get to buzz me out." All he really wants is the feeling someone loves him - a pinky ring has a reputation for symbolizing acceptance in the Mafia.</p>
                            <p>I could not find these lyrics online. So I'm writing what I heard here:</p>
                            <pre style={blurry_text}>{`
Wonder if I would crack under fire
Wonder if I'm a rat
Love is being connected
I want to feel like that

My crew and I would hang on the steps
Outside a chippy shop in Oldham
I'd make mistakes
They'd clean up the mess
We'll play Texas Hold'em
And talk about religion

It's a wonder how I try
So little in my life
I still could be insane
But I do it in style

No one would ever talk to me that way
Get the boss on the phone
Some Jabroni's in my face
He thinks he's the man

I want to be in the Mafia
I want to push everybody down down down
I want to put a hit out on my brain
And live another day in the Mafia

I met a guy at Belmont Psychiatric
Didn't like the look of his room
He told me he was taking mine
And I said Should I make your bed?
Do you tuck your sheets in?

My mind is over that way
My soul is over there somewhere
I'm just in between
Saying "What do you want from me?"

I know I'll never be untouchable
But if I ever get rich
I know a henchman inside 
Who'll get to buzz me out

I want to be in the Mafia
I want to push everybody down down down
I want to put a hit out on my brain
And say "What do I look like? An asshole?"
I want to be Ray Liotta
And live another day in the Mafia

And in the evening I'd take off my sweatpants
Adjust my chain and put on some other sweatpants
Look at my pinkie ring
This little thing means somebody loves me
I'm beginning to give up
There's nothing in my cup
I'm hoping it's a phase
I'm longing for the days in the Mafia
                            `}</pre>
                          </BalanceText>
                        </div>

                        <div style={review_sub_title}>Shortly After Takeoff</div>
                        <div>
                          <BalanceText style={align_left}>
                            <p>When he sings quietly it has that soothing sound of Iron and Wine when they are quiet. And then it jumps into a more upbeat section during the chorus. He doesn't stick to one sound for a song - which again harkens Father John Misty or Ariel Pink.</p>
                            <p>He has a fear of flying which will soon be over since he is coming down to land over the Pennines - I'm guessing he's returning from America - he wouldn't be coming back from Scotland if he's afraid to fly. The only way he can overcome this long distance fear is to be drugged up. Something to knock him out - ironically phrasing it "you'll find me crashing out." And please don't wake him ("don't pick up the pieces.")  The drug initially had the opposite effect by making the pilot look like an alien. There are nice people ("I'm holding an old lady's hand") and there are jerks - a guy jokes that he smells smoke. Again this is probably a metaphor for life . He says "Consider this proof of living - a flight deck recorder of life" - at least he left the world something that will be here after he dies. I've reviewed albums lately addressing thoughts about childhood and death from older people (Ben Watt, Ed O'Brien and Morrissey) but Brian is just about to hit 40. That's a little young for such thoughts but he does let you know pretty openly he has had troubles with depression. He seems like a good guy - I hope he feels better.</p>
                          </BalanceText>
                        </div>

                        <div style={review_sub_title}>Arm Around Your Sadness</div>
                        <div>
                          <BalanceText style={align_left}>
                            <p>Right off the bat, in the first few seconds, it changes. You think in the first two seconds it'll crescendo into a loud song, but it pretty much stays quiet immediately after that. He's comfortable abruptly changing within a song.</p>
                            <p>There's a stigma to depression - often people run from any odd feelings and don't want to address them since it's "abnormal." It's better to address sadness - it's part of life - put your arm around it. He's holed up again. Getting a theme? It's obvious to notice that this album was made for coronavirus times and he didn't even know it. I laugh thinking about what the delivery guy is thinking of Brian, home in the middle of the day, possibly in pajamas, possibly with Judge Judy on in the background. "He knows I'm lost again." I've been there!</p>
                            <p>I could not find these lyrics online. So I'm writing what I heard here:</p>
                            <pre style={blurry_text}>{`
Keep your arm around your sadness
He's no enemy
We've been hiding inside
Watching daytime TV
He thinks that dad is Kevin
I think that it's Joe
We've been talking about this shit 
Since I was seven years old

We must have ordered something
There's a man at the door
With some vegetable peeler thing
I don't recall ordering
I signed my name in a straight line
And gave back the pen
You can tell by the way
He said "Good day"
He knows I'm lost again

Arm around your sadness
Arm around your madness

Talking to the chemist
I made up some lie
That I required no prescription
Because I'm American
I said I'd phone the doctor
But I went and got pissed
My buddy looked at me and said
At least we don't exist

Arm around your sadness
Arm around your madness

We went to bed together
And dreamt of my death
Kings of the night
Pawns of the dawn
Coming down for breakfast
There's not much to enjoy
But we can't wait to see Judge Judy
Crush this little boy
                            `}</pre>
                          </BalanceText>
                        </div>

                        <div style={review_sub_title}>Born to Cruise</div>
                        <div>
                          <BalanceText style={align_left}>
                            <p>With the female singing in the background and the bouncy tempo it sounds like it could be a New Pornographers song.</p>
                            <p>I have no idea what this song means but I love it. I tried my best to write the lyrics correctly but a couple I'm not getting so I put question marks by them. Is his radiator filled with drugs or did I mishear? This is a fun song although it sounds like he's driving a clunker and he can't resist bringing death into it - "I wanna bury my ashes under Oldham Street." </p>
                            <p>I could not find these lyrics online. So I'm writing what I heard here:</p>
                            <pre style={blurry_text}>{`
I've had my indicator on since leaving Crewe
That explains the gestures in my rearview
I get my tires from the wrong side of town
Nobody said I had to write this shit down
Nobody told me my mirror was broke in two
My radiator's mostly full of poppers(?)
I sprung a leak last week and it's getting worse
Give me a monkey wrench
Give me it stat
Nobody helps me when I get a flat
Nobody tells me how awesome I am

Don't waste your time
Don't believe your friends
They're alien too(?)
I'll see you when I see you babe
That's all
Baby I'm Born to Cruise

I got pulled over leaving Chester Zoo
They found a small koala bear in my boot
I wanna crash
I wanna streak
I wanna bury my ashes under Oldham Street
Nobody told me how darling I am

Don't waste your time
Don't believe your friends
They're alien too
I'll see you when I see you babe
That's all
Baby I'm Born to Cruise

Don't waste your time
Don't believe your friends
They're alien too

Don't waste your time
Don't believe your friends
They're alien too

I'll see you when I see you babe
That's all

I'll see you when I see you babe
That's all
Baby I'm Born to Cruise
Baby I'm Born to Cruise
                            `}</pre>
                          </BalanceText>
                        </div>

                        <div style={review_sub_title}>Angelo</div>
                        <div>
                          <BalanceText style={align_left}>
                            <p>A short, pretty song. His dad passed away and this sounds appropriate for a church service.</p>
                            <p>Angelo is his dad and he wanted to include his dad's name on an album.</p>
                          </BalanceText>
                        </div>



                        <div><i>Released: 24 April, 2020</i></div>


                      </Card.Text>
                    </Card.Body>
                  </Card>
              </Col>
            </Row>

          </Col>
    )
  }
}

const getNewsItem = (title, link) => {
  return (
    <a href = {link}><div class="newslinks">{title}</div></a>
  )
}

const mapStateToProps = (state, ownProps) => {
  // let page_num = ownProps.match.params.page_num;
  return {
    posts: state.posts,
    // page_num: page_num,
  }
}

export default connect(mapStateToProps)(BCCamplightShortlyAfterTakeoff)