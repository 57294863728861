import Card from "react-bootstrap/Card";
import newspapers from "../newspapers.jpg";
import socialmedia from "../socialmedia.jpg";
import Col from "react-bootstrap/Col";
import React from "react";
import BalanceText from "react-balance-text";
import {TwitterTweetEmbed} from "react-twitter-embed";
import InstagramEmbed from 'react-instagram-embed';

export const getNewsItems = () => {
  const card_style = {
    // width: '18em',
    display: 'inline-block',
    margin: '1em',
  };

  const img_style = {
    // height: '4em',
  };
  return (
    <Card style={card_style}>
      <Card.Img variant="top" src={socialmedia} alt="picture of phone with social media icons" style={img_style}/>
      <Card.Body>
        <Card.Title>News</Card.Title>
        <Card.Text>
          {getNewsLinks()}
        </Card.Text>
      </Card.Body>
    </Card>
  );
}

const getNewsItem = (title, link) => {
  return (
    <a href = {link}><div class="newslinks">{title}</div></a>
  )
}

// {getNewsItem("Rock and Roll Hall of Fame Temporarily Closed", "/news/1")}
// {getNewsItem("Kenny Rogers Dies at Age 81", "/news/2")}
// {getNewsItem("Liverpool Rock 'n' Roll Half Marathon Postponed", "/news/3")}
// {getNewsItem("Rock The Lockdown Creates Virtual Festival", "/news/4")}
// {getNewsItem("Liam Gallagher Suggests Oasis Charity Concert", "/news/5")}
// {getNewsItem("Pearl Jam To Release New Album Gigaton", "/news/6")}
// {getNewsItem("Madonna Takes Down Tweet Calling Coronavirus The Great Equalizer", "/news/7")}

const align_left = {
  textAlign: 'left',
}

const iframe_style = {
  border: 'none',
  overflow: 'hidden'
}

const NewsItems = [
  {
    title: "Lana Del Rey has social media problems",
    content: (
      <Card.Text>
        <BalanceText style={align_left}>
          <p>Lana Del Rey has a PR team. They may need to filter her social media posts. I don't know exactly what her point
          was in listing the first group of women and then referring to "women who look and act like me" but it did
          not come off well! She did include Ariana Grande in the list, who is 100% Italian, so that would protect her
            if anyone says she is talking about other races.</p>
          <p>Ariana Grande's color caused its own controversy
          after a Vogue cover - a ridiculous controversy, but a controversy none the less. Some complained she appeared
            too dark, which leads to two questions:</p>
          <ol>
            <li> What's wrong with appearing dark? It's not like she was in blackface. </li>
            <li> Was she really that much darker than normal? </li>
          </ol>
          <p>The moral of all these stories? Sometimes it would suck to be famous. You WILL piss somebody off. But get a
            PR team and listen to them. While Ariana Grande's cover was fine and you can't help gettin some stupid flak,
          Lana Del Rey's post needed serious rework to not piss off so many people.</p>
        </BalanceText>
        <br/>

        <InstagramEmbed
          url='https://instagr.am/p/CAcQPuBJdir/'
          hideCaption={false}
          containerTagName='div'
          protocol=''
          injectScript
          onLoading={() => {}}
          onSuccess={() => {}}
          onAfterRender={() => {}}
          onFailure={() => {}}
        />
      </Card.Text>
    ),
  },
  {
    title: "Little Richard dies at 87",
    content: (
      <Card.Text>
        <BalanceText style={align_left}>
          Little Richard (Richard Penniman) has died of a heart attack at 87. The song Tutti Frutti took us from the 40s
          to rock and roll and rock and roll never looked back. Amazing that song is from 1955. It's still a rocker.
        </BalanceText>
        <br/>
        <TwitterTweetEmbed
          tweetId={'1259144925104521224'}
        />
      </Card.Text>
    ),
  },
  {
    title: "Madonna Takes Down Tweet Calling Coronavirus The Great Equalizer",
    content: (
      <Card.Text>
        <BalanceText style={align_left}>
          Among other things, Madonna said COVID-19 doesn't care if you are old, but now she may have second thoughts.
          It seems like it does actually care based on the current statistics. It treats older people much more rudely.
          It also bullies people with asthma and other lung diseases. It may not be The Great
          Equalizer and Madonna apparently rethought it. Instead she played it a little safer Voguing about fried fish:
        </BalanceText>
        <br/>
        <TwitterTweetEmbed
          tweetId={'1241035131554615298'}
        />
      </Card.Text>
    ),
  },
  {
    title: "Kenny Rogers Dies at Age 81",
    content: (
      <Card.Text>
        <BalanceText style={align_left}>
          Kenny Rogers died March 20 at age 81. Below are suggestions of where to donate in his name.
        </BalanceText>
        <br/>
        <iframe
          src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2FKennyRogersOfficial%2Fposts%2F3189104754453244&width=500"
          width="500" height="449" style={iframe_style} scrolling="no" frameBorder="0"
          allowTransparency="true" allow="encrypted-media"></iframe>
      </Card.Text>
    ),
  },
  {
    title: "Liverpool Rock 'n' Roll Half Marathon Postponed",
    content: (
      <Card.Text>
        <BalanceText style={align_left}>
          The Liverpool Rock 'n' Roll Half Marathon has been rescheduled for the weekend of October 24-25, 2020 due to the coronavirus.
          It was originally scheduled for May 23-24, 2020.
        </BalanceText>
        <br/>
        <iframe
          src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2FRunRocknRoll%2Fposts%2F10163342233200494&width=500"
          width="500" height="293" style={iframe_style} scrolling="no" frameBorder="0"
          allowTransparency="true" allow="encrypted-media"></iframe>      </Card.Text>
    ),
  },
  {
    title: "Rock The Lockdown Creates Virtual Festival",
    content: (
      <Card.Text>
        <BalanceText style={align_left}>
          Locked in and no large gatherings allowed due to coronavirus? No problem! Ollie Hughes and Lucy Pardoe created
          a virtual festival with performers from all over the world over the weekend of March 21, 2020. The performers streamed
          on Facebook. Many people have posted videos to the site since the fest. &nbsp;
          <a href="https://www.facebook.com/groups/1066664150378119/videos/">See videos here</a>!
        </BalanceText>
      </Card.Text>
    ),
  },
  {
    title: "Rock and Roll Hall of Fame Temporarily Closed",
    content: (
      <Card.Text>
        <BalanceText style={align_left}>
          The Rock and Roll Hall of Fame is temporarily closed due to the coronavirus.
        </BalanceText>
        <br/>
        <iframe
          src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Frockandrollhalloffame%2Fposts%2F10157325547714107&width=500"
          width="500" height="740" style={iframe_style} scrolling="no" frameBorder="0"
          allowTransparency="true"
          allow="encrypted-media"></iframe>
      </Card.Text>
    ),
  },
  {
    title: "Liam Gallagher Suggests Oasis Charity Concert",
    content: (
      <Card.Text>
        <BalanceText style={align_left}>
          Besides altering various Oasis songs to make them related to washing hands as he washes his hands,
          Liam Gallagher tweets to Noel that <br/>A. Liam is good looking <br/>B. they should reunite for charity after
          "this" is put to bed, where "this" is either their feud or coronavirus:
        </BalanceText>
        <br/>
        <blockquote className="twitter-tweet"><p lang="en" dir="ltr">Listen seriously a lot of people think I’m a c--- and I
          am a good looking c--- but once this is put to bed we need to get oasis back for a 1 of gig rite for charity c’mon
          Noel we can then go back to our amazing solo careers c’mon you know LG x</p>&mdash; Liam Gallagher
          (@liamgallagher) <a href="https://twitter.com/liamgallagher/status/1240709406733602816?ref_src=twsrc%5Etfw">March
            19, 2020</a></blockquote>
      </Card.Text>
    ),
  },
  {
    title: "Pearl Jam To Release New Album Gigaton",
    content: (
      <Card.Text>
        <BalanceText style={align_left}>
          Pearl Jam will release their eleventh studio album, Gigaton, on March 27, 2020 in the US.
          They won a Grammy for best album cover for their last album, Lightning Bolt.
          Here's some artwork for their new album:
        </BalanceText>
        <br/>
        <iframe
          src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2FPearlJam%2Fposts%2F10157819635912485&width=500"
          width="500" height="329" style={iframe_style} scrolling="no" frameBorder="0"
          allowTransparency="true" allow="encrypted-media"></iframe>

      </Card.Text>
    ),
  },
]

const getNewsLinks = () => {
  return NewsItems.map( (item, index) => {
    return getNewsItemLink(index + 1)
    // const link_number = '/news/' + (index + 1)
    // return (
    //   <a href = {link_number}><div class="newslinks">{item['title']}</div></a>
    // )
  })
}

export const getNewsItemContent = (item_number) => {
  const index = item_number - 1
  return NewsItems[index]['content']
}

export const getNewsItemTitle = (item_number) => {
  const index = item_number - 1
  return NewsItems[index]['title']
}

export const getNewsItemLink = (item_number) => {
  const link_number = '/news/' + item_number
  return (
    <a href = {link_number}><div class="newslinks">{getNewsItemTitle(item_number)}</div></a>
  )

}


// export default HomeHelper;