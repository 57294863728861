import React, {Component} from 'react'
import {Link} from 'react-router-dom'
import {connect} from 'react-redux'
import axios from 'axios'
import {HomeHelper} from "./HomeHelper"
import {getNewsItems} from "./NewsItems";
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Pagination from 'react-bootstrap/Pagination'
import Dropdown from 'react-bootstrap/Dropdown'
import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'
import concert from '../concert.jpg'
import guitarcloseup from '../guitar-closeup.jpg'
import guitarcloseup_bw from '../guitar-closeup-bw.jpg'
import guitars_bw from '../guitars-bw.jpg'
import newspapers from '../newspapers.jpg'
import the_beatles from '../the-beatles.jpg'
import thewantscontainer from '../thewantscontainer.jpg'
import '../index.css'
import BalanceText from 'react-balance-text'


class TheWantsContainer extends Component {
  constructor() {
    super()
  }

  render() {
    const card_style = {
      // width: '18em',
      display: 'inline-block',
      margin: '1em',
    };

    const card_style_double = {
      // width: '38em',
      display: 'inline-block',
      margin: '1em',
    };

    const img_style = {
      height: '4em',
    };

    const img_style_double = {
      /* height: '8em', */
    };

    const home_button = {
      backgroundColor: '#555555',
    };

    const align_left = {
      textAlign: 'left',
    }

    const review_sub_title = {
      color: '#555555',
      fontWeight: 'bold',
      fontSize: '1em',
      margin: '15px 0px 3px 0px',
    }

    return (
          <Col sm={6}>
            <Row>
              <Col sm={12}>
                  <Card style={card_style_double}>
                    <Card.Img variant="top" src={thewantscontainer} alt="The Wants Container album cover" style={img_style_double}/>
                    <Card.Body style={align_left}>
                      <Card.Title style={align_left}>Review: <i>Container</i> by The Wants</Card.Title>
                      <Card.Text>
                        <BalanceText style={align_left}><b>Overall:</b>  This album takes you through a trip into a factory perhaps in the future - a lot of songs give an industrial feeling with no lyrics - other songs harken to Talking Heads and other post punk bands - a lot of 80s influence. They have a good feel for coming up with grooves that sound pleasant. </BalanceText>

                        <div style={review_sub_title}>Ramp</div>
                        <div>
                          <BalanceText style={align_left}>
                            <p>It starts out like Franz Ferdinand and then goes into a Rush sounding synthesizer groove and then settles into an early Talking Heads sound.</p>
                            <p>There are no lyrics except at the end they say "ramp" as though they are ramping up to start the album.</p>
                          </BalanceText>
                        </div>


                        <div style={review_sub_title}>Container</div>
                        <div>
                          <BalanceText style={align_left}>
                            <p>The way he speaks/sings is like Parquet Courts - the guitar and synthesizer go with the simple drum beat to keep a steady sound going and it keeps building.</p>
                            <p>People can do anything they want to something but they try to put it in a container and "patch it into time honored labels." This band would hate my method of reviewing albums song by song and trying to "label" what artist(s) each song reminds me of.  No one wants to be your friend past 7:30 - is that the cutoff time for a serious friend or a romantic friend? If you're just hanging with someone to be nice or just to get something from them you don't stay past 7:30. But what if this thing you are just trying to label is something quite different - something that "turns out to be your savior?"</p>
                          </BalanceText>
                        </div>


                        <div style={review_sub_title}>Machine Room</div>
                        <div>
                          <BalanceText style={align_left}>
                            <p>It starts with industrial sounds and then a beat comes in. The percussion could almost be a pile driver.</p>
                            <p>No lyrics - just a drone to go with the beats - it does in fact sound like a machine room.</p>
                          </BalanceText>
                        </div>


                        <div style={review_sub_title}>Fear My Society</div>
                        <div>
                          <BalanceText style={align_left}>
                            <p>It starts with a late 70s early 80s post punk synthesizer - Kraftwerk or Devo. For some reason the drums sound 21st century while the rest sound 80s. The guitar sounds like some early U2 songs. </p>
                            <p>He's feeling the pressure of society. He's afraid he won't be loved if he's a failure. He fears it will continue so in the future as he tells his son to "man up." He knows to give up when he sees "the droves." Although huge groups of people do the same thing, there are 330 million people in the US and lots are weird (for instance I met a man in a white suit in Oakland who said he tells people he hates Stan Getz just to see their reaction) so he should take solace in that - there are even weirdos in their home town of Madison - one of whom is walking around with a cool coat (mine was not in the pile when I left a bar there about ten years ago.) </p>
                          </BalanceText>
                        </div>


                        <div style={review_sub_title}>The Motor</div>
                        <div>
                          <BalanceText style={align_left}>
                            <p>It starts with what could be a motor revving and then into a Talking Heads guitar sound. I think I'm spotting an influence here. It picks up more after she says "Let's go fast" - it's a fun jam - like how Ambulance Ltd started their album 15 years ago. He sings like Pet Shop Boys for a bit when he sings low. </p>
                            <p>"When my heart beats fast as the motor I'm calm." That's pretty much the only lyric. I'm not an adrenaline junkie so I can't relate - maybe he's being sarcastic or maybe he's not. </p>
                          </BalanceText>
                        </div>


                        <div style={review_sub_title}>Aluminum</div>
                        <div>
                          <BalanceText style={align_left}>
                            <p>More industrial sounds to start with but this time there's a hint of the beginning of Sgt Pepper's when the band is warming up - but then it just keeps continuing instead of bursting into "It was twenty years ago today!" It starts to sound like 60s sci-fi movies. This is not something to jog to. I'm picturing someone trying to figure out what to do with their life as they toil in some aluminum processing plant in the future. The person has left the factory and is now flying to Africa to go trap apes. He's there now - hovering over them in a helicopter and tranquilizing one. He goes down to put a collar on it before it wakes up - this is all a precursor to the next song.</p>
                            <p>There are no lyrics but the title and the next title make me picture what I do.</p>
                          </BalanceText>
                        </div>


                        <div style={review_sub_title}>Ape Trap</div>
                        <div>
                          <BalanceText style={align_left}>
                            <p>Another healthy dose of synthesizer to start - and when she sings with him they touch the B52's for a couple seconds - but we are now definitely in the mid-80s instead of early 80s.</p>
                            <p>Ah but it's not a literal ape trap - the ape is the human and the trap are his four walls and the confinement (or "Container") he finds himself in - whether put there by society or his own decisions. "Craving science fiction so I'll no longer do your dishes." We all want an escape from the humdrum. Thus all the sci-fi sounds I hear! "Medicine and arms" - at first I'm thinking heroin because it is shot into the arm - but then I'm thinking arms like weapons. Being trapped seems more of a heroin thing so I'm going with that - it gives him "twenty-twenty visions."</p>
                          </BalanceText>
                        </div>


                        <div style={review_sub_title}>Waiting Room</div>
                        <div>
                          <BalanceText style={align_left}>
                            <p>They continue to create an industrial aura. It could be an early 90s Chicago band - someone that may have hung out with Revolting Cocks but was more mellow than them.</p>
                            <p>I do not get a waiting room feel from this song so I do not understand the title - unless it's a waiting room with people coming in from the rain - there seems to be a lot going on.</p>
                          </BalanceText>
                        </div>


                        <div style={review_sub_title}>Clearly a Crisis</div>
                        <div>
                          <BalanceText style={align_left}>
                            <p>At the start, this song would have been comfortable on Miami Vice.  This could be a Teardrop Explodes song for a good chunk of it.</p>
                            <p>He is scared silly of loving someone. A woman needs a man to protect her but how can he if he is scared himself? "This attraction's inescapable so I hide in myself."</p>
                          </BalanceText>
                        </div>


                        <div style={review_sub_title}>Nuclear Party</div>
                        <div>
                          <BalanceText style={align_left}>
                            <p>A fun Kraftwerk beginning. He voice sounds like Frank Orrall on a lot of this song - and it's also their early 80s style - Buggles?</p>
                            <p>"Kiss my bombs and I'll kiss your bones." This could be an attack on Trump supporters - a criticism of them is they are all for someone who has policies that screw them over - they are kissing the bombs that will turn them into bones. Strangely we don't really care about nuclear bombs anymore - we care about terrorists and guns - they are going all out in their 80s theme by talking "nuclear."</p>
                          </BalanceText>
                        </div>


                        <div style={review_sub_title}>Hydra</div>
                        <div>
                          <BalanceText style={align_left}>
                            <p>The music could have fit on the second side of Duran Duran's first album. The singing comes back a little to Parquet Courts again.</p>
                            <p>Someone he's talking to is very confident and not going to be easy to change - "Happy who you are? Carve it in stone." I thought the last song talked about 80s themes but this one is called Hydra which is a word they kept using right after 9/11. They said you can't kill the leader of al-Qaeda - you kill one and two more will take his place. So they're in the 21st century - but they shoot right past 9/11 into the current climate - I think he's going after Trump again: "You're the sour creed with insatiable greed." Anytime a singer says anything about "greed" I'm thinking Trump. And the religious right probably too: "You're the beast in the God I can't breach."</p>
                          </BalanceText>
                        </div>


                        <div style={review_sub_title}>Voltage</div>
                        <div>
                          <BalanceText style={align_left}>
                            <p>Let's finish it with another trip to an industrial zone. This factory is huge - and it has glass windows you can look into from the main floor - and there are people in hazmat suits in clean rooms - but there is an industrial scale of production on the main floor cranking out in huge quantities the items those people are cooking up!</p>
                            <p>No lyrics but the place is running up a huge electric bill. No wonder I keep wanting to call them The Watts.</p>
                          </BalanceText>
                        </div>



                        <div><i>Released: 13 March, 2020</i></div>


                      </Card.Text>
                    </Card.Body>
                  </Card>
              </Col>
            </Row>

          </Col>
    )
  }
}

const getNewsItem = (title, link) => {
  return (
    <a href = {link}><div class="newslinks">{title}</div></a>
  )
}

const mapStateToProps = (state, ownProps) => {
  // let page_num = ownProps.match.params.page_num;
  return {
    posts: state.posts,
    // page_num: page_num,
  }
}

export default connect(mapStateToProps)(TheWantsContainer)