// import React from 'react';
import React, { Component } from 'react';
// import logo from './logo.svg';
import './App.css';
// import axios from 'axios'
import { Route, BrowserRouter, Switch } from 'react-router-dom'
// import Navbar from "../../../react-rockandroll/rockandroll/src/components/Navbar";
import Home from './components/Home'
import News from './components/News'

import VenuePostCodes from './components/VenuePostCodes'
import Artists from './components/Artists'
import Reviews from './components/Reviews'
import Reviews2 from './components/Reviews2'
import Reviews3 from './components/Reviews3'

import BadlyDrawnBoyBananaSkinShoes from "./components/BadlyDrawnBoyBananaSkinShoes";
import BCCamplightShortlyAfterTakeoff from './components/BCCamplightShortlyAfterTakeoff'
import BenWattStormDamage from './components/BenWattStormDamage'
import EOBEarth from './components/EOBEarth'
import HMLTDWestOfEden from './components/HMLTDWestOfEden'
import LauraMarlingSongForOurDaughter from './components/LauraMarlingSongForOurDaughter'
import MorrisseyIAmNotADogOnAChain from './components/MorrisseyIAmNotADogOnAChain'
import PorridgeRadioEveryBad from './components/PorridgeRadioEveryBad'
import PublicPracticeGentleGrip from './components/PublicPracticeGentleGrip'
import Sorry925 from './components/Sorry925'
import SparksASteadyDripDripDrip from './components/SparksASteadyDripDripDrip'
import Template from './components/Template'
import TheBigMoonWalkingLikeWeDo from './components/TheBigMoonWalkingLikeWeDo'
import TheWantsContainer from "./components/TheWantsContainer";
import WaxahatcheeSaintCloud from './components/WaxahatcheeSaintCloud'
import Venues from './components/Venues'
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import Form from "react-bootstrap/Form";
import FormControl from "react-bootstrap/FormControl";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {getNewsItems} from "./components/NewsItems";
import Card from "react-bootstrap/Card";
import concert from "./concert.jpg";
import BalanceText from "react-balance-text";
import { TwitterTimelineEmbed, TwitterShareButton, TwitterFollowButton, TwitterHashtagButton, TwitterMentionButton, TwitterTweetEmbed, TwitterMomentShare, TwitterDMButton, TwitterVideoEmbed, TwitterOnAirButton } from 'react-twitter-embed';
// import Home2 from './components/Home2'
// import About from "../../../react-rockandroll/rockandroll/src/components/About";
// import Contact from "../../../react-rockandroll/rockandroll/src/components/Contact";
// import Post from "../../../react-rockandroll/rockandroll/src/components/Post";
import Feature from './components/Feature';

class App extends Component {
  render() {

    const card_style = {
      // width: '18em',
      display: 'inline-block',
      margin: '1em',
    };

    const card_style_double = {
      // width: '38em',
      display: 'inline-block',
      margin: '1em',
    };

    const img_style = {
      height: '4em',
    };

    const img_style_double = {
      height: '8em',
    };

    const home_button = {
      backgroundColor: '#555555',
    };

    const align_left = {
      textAlign: 'left',
    }

    const sticky_nav = {
      position: 'sticky',
      top: '0'
    }

//function App() {
    /*
    Put this right before </Navbar.Collapse> when ready
                <Form inline style={{justifyContent: "center"}}>
              <FormControl type="text" placeholder="Search" className="mr-sm-2" />
              <Button variant="outline-success">Search</Button>
            </Form>

     */
    return (


    <BrowserRouter>
      <div className="App">

        <Navbar bg="light" expand="lg" fixed="top" style={sticky_nav}>
          <Navbar.Brand href="/">rockandroll.uk</Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mr-auto">
              <Nav.Link href="/">Home</Nav.Link>
              <Nav.Link href="/news/1">News</Nav.Link>
              <Nav.Link href="/reviews">Reviews</Nav.Link>
              <Nav.Link href="/venues">Venues</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Navbar>

        <Container>
          <Row className="justify-content-md-center">

            <Switch>
              <Route exact path='/' component={Home}/>
              <Route exact path='/venues' component={Venues}/>
              <Route exact path='/venues/:venueId' component={Venues}/>
              <Route exact path='/venues/:venueId/:venueName' component={Venues}/>
              <Route exact path='/artists' component={Artists}/>
              <Route exact path='/reviews' component={Reviews3}/>

              <Route exact path='/reviews/badly-drawn-boy-banana-skin-shoes' component={BadlyDrawnBoyBananaSkinShoes}/>
              <Route exact path='/reviews/bc-camplight-shortly-after-takeoff' component={BCCamplightShortlyAfterTakeoff}/>
              <Route exact path='/reviews/ben-watt-storm-damage' component={BenWattStormDamage}/>
              <Route exact path='/reviews/eob-earth' component={EOBEarth}/>
              <Route exact path='/reviews/hmltd-west-of-eden' component={HMLTDWestOfEden}/>
              <Route exact path='/reviews/laura-marling-song-for-our-daughter' component={LauraMarlingSongForOurDaughter}/>
              <Route exact path='/reviews/morrissey-i-am-not-a-dog-on-a-chain' component={MorrisseyIAmNotADogOnAChain}/>
              <Route exact path='/reviews/porridge-radio-every-bad' component={PorridgeRadioEveryBad}/>
              <Route exact path='/reviews/public-practice-gentle-grip' component={PublicPracticeGentleGrip}/>
              <Route exact path='/reviews/sorry-925' component={Sorry925}/>
              <Route exact path='/reviews/sparks-a-steady-drip-drip-drip' component={SparksASteadyDripDripDrip}/>
              <Route exact path='/reviews/template' component={Template}/>
              <Route exact path='/reviews/the-big-moon-walking-like-we-do' component={TheBigMoonWalkingLikeWeDo}/>
              <Route exact path='/reviews/the-wants-container' component={TheWantsContainer}/>
              <Route exact path='/reviews/waxahatchee-saint-cloud' component={WaxahatcheeSaintCloud}/>


              <Route exact path='/feature' component={Feature}/>
              <Route exact path='/news/:newsId' component={News}/>
            </Switch>

            <Col sm={3}></Col>

          </Row>

        </Container>


      </div>
    </BrowserRouter>


  );
  }
}
{/*<Route exact path='/reviews/:reviewId' component={Venues}/>*/}
{/*<Route exact path='/reviews/:reviewId/:reviewName' component={Venues}/>*/}
{/*<Route exact path='/reviews2' component={Reviews2}/>*/}

{/*<Route exact path='/news/1' component={News1}/>*/}
{/*<Route exact path='/news/2' component={News2}/>*/}
{/*<Route exact path='/news/3' component={News3}/>*/}
{/*<Route exact path='/news/4' component={News4}/>*/}
{/*<Route exact path='/news/5' component={News5}/>*/}
{/*<Route exact path='/news/6' component={News6}/>*/}

const getIframe = () => {
  const iframe_style = {
    border: 'none',
    overflow: 'hidden'
  }
  return (
    <TwitterTweetEmbed
      tweetId={'1241035131554615298'}
    />
  )
}



export default App;
