import React, {Component} from 'react'
import {Link} from 'react-router-dom'
import {connect} from 'react-redux'
import axios from 'axios'
import {HomeHelper} from "./HomeHelper"
import {getNewsItems} from "./NewsItems";
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Pagination from 'react-bootstrap/Pagination'
import Dropdown from 'react-bootstrap/Dropdown'
import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'
import concert from '../concert.jpg'
import guitarcloseup from '../guitar-closeup.jpg'
import guitarcloseup_bw from '../guitar-closeup-bw.jpg'
import guitars_bw from '../guitars-bw.jpg'
import newspapers from '../newspapers.jpg'
import the_beatles from '../the-beatles.jpg'
import sorry925 from '../sorry925.jpg'
import '../index.css'
import BalanceText from 'react-balance-text'


class Sorry925 extends Component {
  constructor() {
    super()
  }

  render() {
    const card_style = {
      // width: '18em',
      display: 'inline-block',
      margin: '1em',
    };

    const card_style_double = {
      // width: '38em',
      display: 'inline-block',
      margin: '1em',
    };

    const img_style = {
      height: '4em',
    };

    const img_style_double = {
      /* height: '8em', */
    };

    const home_button = {
      backgroundColor: '#555555',
    };

    const align_left = {
      textAlign: 'left',
    }

    const review_sub_title = {
      color: '#555555',
      fontWeight: 'bold',
      fontSize: '1em',
      margin: '15px 0px 3px 0px',
    }

    return (
          <Col sm={6}>
            <Row>
              <Col sm={12}>
                  <Card style={card_style_double}>
                    <Card.Img variant="top" src={sorry925} alt="sorry 925 album cover" style={img_style_double}/>
                    <Card.Body style={align_left}>
                      <Card.Title style={align_left}>Review: <i>925</i> by Sorry</Card.Title>
                      <Card.Text>
                        <BalanceText style={align_left}><b>Overall:</b> Asha Lorenz and Louis O'Bryen say they are influenced by Elliott Smith and Radiohead and to some degree Nirvana, and I think Bettie Serveert is their soulmate, but still their combinations are unique and they have a great sound. Their lyrics often revolve around being the lover instead of the loved, but I love this album, so they are loved after all. </BalanceText>

                        <div style={review_sub_title}>Right Round the Clock</div>
                        <div>
                          <BalanceText style={align_left}>
                            <p>It starts simply with a fun rhythm with sax and piano then it changes a lot. It's a hodgepodge of sounds which go from one to the other but it's fun. A little like Cibo Matto. They also pay tribute to the Tears For Fears song Mad World with "the dreams in which we're famous are the best I've ever had."</p>
                            <p>She (the singer) is in love with a bartender. Or so I thought. Or he (the singer) is because a guy starts singing too. But in the video the girl isn't flashing her eyes from "behind the bar" as the lyrics say - she is a customer. So she's not a bartender. That would help explain that she wants to get out of her head - she's there to drink. She wasn't "dressed like a movie star" in the video, nor "with an entourage."</p>
                            <p>There are a couple ideas in this song which point out how painful love can be. The first is that some people can be downright mean: "you know she'll be there to see how hung up you can be." She could avoid tormenting you but she gets a kick out of it. The second is when someone doesn't care about you when they are all you think about: "what's the lowest thing of all is she'll never even know just how you feel."</p>
                          </BalanceText>
                        </div>

                        <div style={review_sub_title}>In Unison</div>
                        <div>
                          <BalanceText style={align_left}>
                            <p>The transition from the melancholy "everybody dreams part" to the heavier "when will you get round..." harkens back to 90s Radiohead.</p>
                            <p>There is a pointlessness to life that some people find hard to accept: "it makes me cry one day we're here one day we die." In the end it's just a dream we are all having: "Everybody dreams alone...in unison." People want to avoid this feeling. It can make it hard to motivate yourself to do anything if you think of how unimportant it is in the big picture: "when will you get round to doing what you're supposed to?" Some have said religion has been used to motivate the workers to keep doing their work: there is a reward if you keep doing what you're supposed to.</p>
                          </BalanceText>
                        </div>

                        <div style={review_sub_title}>Snakes</div>
                        <div>
                          <BalanceText style={align_left}>
                            <p>She sings a little like Nico sometimes - like at the beginning of this song. Then it starts sounding like Bettie Serveert.</p>
                            <p>This is a pretty unique love song. It's about a past love which didn't go too far: "I think about the only kiss we ever sunk." Yet "snakes didn't even scare me quite like you did." The person had "loose screws" but she still respected their mind because "I never thought about you in your underwear" and "I'm not a fair-weather friend."</p>
                          </BalanceText>
                        </div>

                        <div style={review_sub_title}>Starstruck</div>
                        <div>
                          <BalanceText style={align_left}>
                            <p>I wish she would do more of the lilting singing like when she sings "I am just your muse...nothing more than a four digit score." That sounds awesome - she just does it once - I like the rest of the song but that part needs to be repeated.</p>
                            <p>It sounds like she screwed an entertainer (musician perhaps because "pressure, poets...cutting edge entertainment" - a regular poet doesn't become a cutting edge star) and regrets it: "you did it again and I'm starstruck." She feels like nothing compared to this person: "you're the looker, liquor, sugar and I'm the rotten apple of your eye." A sentiment reinforced by the line in the first paragraph.</p>
                          </BalanceText>
                        </div>

                        <div style={review_sub_title}>Rosie</div>
                        <div>
                          <BalanceText style={align_left}>
                            <p>Great intro with a mystical sax - then sounds like Creep for a little bit.</p>
                            <p>She dreams of dying of cancer and people "stole things from my sole" so she is not in a good state. Also she says "my eyes sweat, sweat and salt" - has anyone ever said that before? It's a fun(?) way to describe crying. It's Rosie's fault - perhaps Rosie is the one who didn't care about her in Right Round the Clock. She later dreams of a field of green which "represented my jealousy." Asha needs some better partners to lift up her feelings of self-worth.</p>
                          </BalanceText>
                        </div>

                        <div style={review_sub_title}>Perfect</div>
                        <div>
                          <BalanceText style={align_left}>
                            <p>The beginning sounds like it could be a Courtney Barnett song. Then it has a New Pornographers style male and female back and forth. But as usual the song has combinations unique to Sorry.</p>
                            <p>Another relationship which isn't "perfect." In many relationships there is one member who is on top and can treat the other as they feel and they can not be the bad guy by saying "it's your choice you know where the door is...just know I adore you." Again the lyrics reflect someone who is the weakling of the relationship: "Nothing is left of my self esteem 'cause you're perfect."</p>
                          </BalanceText>
                        </div>

                        <div style={review_sub_title}>As the Sun Sets</div>
                        <div>
                          <BalanceText style={align_left}>
                            <p>The opening simple guitar on its own with barely use of a pedal has a 90s feel to it - Pavement or lesser known lo-fi bands played on college radio. They lead to something a little more upbeat and also make you think of Elliott Smith or Radiohead, two artists that Louis says influenced him. He says this song was written on a "sultry summer's day" in this <a href="https://www.abc.net.au/triplej/programs/kingsmill/sorry-podcast-iv/12152842?fbclid=IwAR30BsesufGa7V70d2Q1pJRYR7xhXjkzwgCHTdGA0Y8BV0yMqfb2lcrPKms">interview</a>.</p>
                            <p>The end of a wonderful day is like looking back on a relationship and thinking of the good parts and ignoring the bad parts: "I wanna be blind...and flood myself in the light." However, the singer is not blind: "I guess that I'm glad that you're both in my past." Was there a cheating incident - why are they both in the past?</p>
                          </BalanceText>
                        </div>

                        <div style={review_sub_title}>Wolf</div>
                        <div>
                          <BalanceText style={align_left}>
                            <p>Louis sings some of these songs in a low-voiced talking way like Iggy Pop. Asha sometimes sounds delicate like Nico.</p>
                            <p>Again the submissive one in the relationship - "Go on take a bite" she sings to the wolf. Is she a wolf also? No: "You're an eager fool to love this fox." A fox is no match for a wolf and the wolf has all the power: "These knots are tied with feeble hands, the slightest tug pulls them apart, and you can play whatever part you like."</p>
                          </BalanceText>
                        </div>

                        <div style={review_sub_title}>Rock 'n' Roll Star</div>
                        <div>
                          <BalanceText style={align_left}>
                            <p>I like when women sing low like when she says "I'd do anything for you." The way she sings "any" in that line is taken from Liz Phair's playbook.</p>
                            <p>This time she doesn't sound as weak as other songs since the rock star told her up front he plays games with girls by whispering "anything they want to know about love" and then proceeding to do that to her. So she knew what she was getting into and doesn't sound to hurt. What hasn't changed from the other songs is the guy does not care about her. I like this simple description of sex: "'Cause I, Then he, Yeah we." They also hint at the definition of 925 in this one: it's the bare minimum (92.5%) of silver in sterling silver. The self esteem is build up a little here, calling themselves sterling silver, but not too high since it's the bare minimum to qualify.</p>
                          </BalanceText>
                        </div>

                        <div style={review_sub_title}>Heather</div>
                        <div>
                          <BalanceText style={align_left}>
                            <p>Louis sounds like Jarvis Cocker in this one.</p>
                            <p>How serious is the relationship? Right off the bat they ask "What am I to you?" and they finish with that question. In between is described a relationship and the questions that go on during it. "I suppose we still have some time to make up these lousy minds." As opposed to looking back on a past relationship, this shows that these questions linger during relationships.</p>
                          </BalanceText>
                        </div>

                        <div style={review_sub_title}>More</div>
                        <div>
                          <BalanceText style={align_left}>
                            <p>The distorted vocals and slightly heavyish music - PJ Harvey sometimes, the Breeders? I can't put my thumb on it.</p>
                            <p>She doesn't want too much from a relationship - "don't give me too much, just give me enough." If she gets too much suddenly she is vulnerable to the dependent feelings expressed in earlier songs.</p>
                          </BalanceText>
                        </div>

                        <div style={review_sub_title}>Ode to Boy</div>
                        <div>
                          <BalanceText style={align_left}>
                            <p>She sounds like a younger girl here and the chorus sounds like children. </p>
                            <p>Ode to Boy lets you know it's not a song to a man. Here is where their sarcasm comes through, acknowledging some innocent faith kids have in their elders: "Can we talk it through like adults, Adults seem to do things that work." This is from the point of view of a pretty deep kid: "Maybe one day I'll call you round when I'm not so upside down." But she still has to sneak in the feeling that the other has the power: "I'd like to still grow up with you, please." It's either a song about young love or she's regressing to a childish state in her adulthood having given up on the grown-up feelings.</p>
                          </BalanceText>
                        </div>

                        <div style={review_sub_title}>Lies</div>
                        <div>
                          <BalanceText style={align_left}>
                            <p>I like the slow heavy deliberate feel to this song. A nice way to end the album.</p>
                            <p>The title lets you know we're dealing with a jaded person. Is she the low man on the totem pole again? "I'm ten feet down and temperamental." She's temperamental - there's no consistency - she "can't keep it together" and "life feels like it's just based on the weather." Like In Unison, she's thinking about death, but maybe now she has found some meaning or purpose: "Heaven's waiting, That's what I've been praying."</p>
                          </BalanceText>
                        </div>

                        <div><i>Released: 27 March, 2020</i></div>


                      </Card.Text>
                    </Card.Body>
                  </Card>
              </Col>
            </Row>

          </Col>
    )
  }
}

const getNewsItem = (title, link) => {
  return (
    <a href = {link}><div class="newslinks">{title}</div></a>
  )
}

const mapStateToProps = (state, ownProps) => {
  // let page_num = ownProps.match.params.page_num;
  return {
    posts: state.posts,
    // page_num: page_num,
  }
}

export default connect(mapStateToProps)(Sorry925)