import React, {Component} from 'react'
import {Link} from 'react-router-dom'
import {connect} from 'react-redux'
import axios from 'axios'
import {HomeHelper} from "./HomeHelper"
import {getNewsItems} from "./NewsItems";
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Pagination from 'react-bootstrap/Pagination'
import Dropdown from 'react-bootstrap/Dropdown'
import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'
import concert from '../concert.jpg'
import guitarcloseup from '../guitar-closeup.jpg'
import guitarcloseup_bw from '../guitar-closeup-bw.jpg'
import guitars_bw from '../guitars-bw.jpg'
import newspapers from '../newspapers.jpg'
import the_beatles from '../the-beatles.jpg'
import sorry925 from '../sorry.925.jpg'
import morrisseyiamnotadogonachain from '../morrisseyiamnotadogonachain.jpg'
import '../index.css'
import BalanceText from 'react-balance-text'


class MorrisseyIAmNotADogOnAChain extends Component {
  constructor() {
    super()
  }

  render() {
    const card_style = {
      // width: '18em',
      display: 'inline-block',
      margin: '1em',
    };

    const card_style_double = {
      // width: '38em',
      display: 'inline-block',
      margin: '1em',
    };

    const img_style = {
      height: '4em',
    };

    const img_style_double = {
      /* height: '8em', */
    };

    const home_button = {
      backgroundColor: '#555555',
    };

    const align_left = {
      textAlign: 'left',
    }

    const review_sub_title = {
      color: '#555555',
      fontWeight: 'bold',
      fontSize: '1em',
      margin: '15px 0px 3px 0px',
    }

    return (
          <Col sm={6}>
            <Row>
              <Col sm={12}>
                  <Card style={card_style_double}>
                    <Card.Img variant="top" src={morrisseyiamnotadogonachain} alt="morrissey i am not a dog on a chain album cover" style={img_style_double}/>
                    <Card.Body style={align_left}>
                      <Card.Title style={align_left}>Review: <i>I Am Not a Dog on a Chain</i> by Morrissey</Card.Title>
                      <Card.Text>
                        <BalanceText style={align_left}><b>Overall:</b> My response to any political opinion is: "I'd rather they say it than keep it to themselves." I'm married to an immigrant so I probably don't agree with everything he stands for, but I don't ignore a pile of garbage if it's got diamonds in it. I like this album.</BalanceText>

                        <div style={review_sub_title}>Jim Jim Falls</div>
                        <div>
                          <BalanceText style={align_left}>
                            <p>The album starts out like <i>Some Friendly</i>. But he starts singing and you know it's him.</p>
                            <p>Morrissey's schtick has often been to sing morbid themes over catchy or beautiful melodies - no exception here. He's saying don't just talk the talk, whethere it's positive ("If you're going to sing then sing" or, of course, negative: "If you're gonna kill yourself the for God's sake just kill yourself." I'm getting the feeling he does not subscribe to the idea that a famous person's words can affect the listeners so he should be careful what he says. Jim Jim Falls is in Australia - if you go there, please don't jump no matter what Morrissey says.</p>
                          </BalanceText>
                        </div>

                        <div style={review_sub_title}>Love Is on Its Way Out</div>
                        <div>
                          <BalanceText style={align_left}>
                            <p>Ths music sounds a little like Moby, especially the part after "elephants and lions."</p>
                            <p>He gives examples of people who are not showing love: "nerve gassed children crying" and "the sad rich hunting down, shooting down elephants and lions." But before all the love in the world is completely gone he wants to know what it's like even though he himself doesn't think he deserves it: "Be mine and gaze with fondness on the wrong one." He shows compassion for nerve gassed children - that usually isn't an English problem, so he apparently has at least some commpassion for others.</p>
                          </BalanceText>
                        </div>

                        <div style={review_sub_title}>Bobby, Don't You Think They Know?</div>
                        <div>
                          <BalanceText style={align_left}>
                            <p>This sounds like Depeche Mode at first. The accompaniment of Thelma Houston's singing works with the rhythm of the song. </p>
                            <p>Based on the title and if you didn't pay attention you would think he's talking about a guy who is in the closet: "Aren't you tired of pretending? I know you're tortured down below." But look at the words he's throwing in there between such sentiments: "Ice snow spikes blow", "Scag shack", "Mexican mud", and "white mosquito." The guy is hiding his drug addiction.</p>
                          </BalanceText>
                        </div>

                        <div style={review_sub_title}>I Am Not a Dog on a Chain</div>
                        <div>
                          <BalanceText style={align_left}>
                            <p>The little opening sounds pretty Smiths. He sings like Badly Drawn Boy during the verses.</p>
                            <p>Based on his outspoken views we know the meaning of this song and the album title. The media in general does not agree with his views, thus he sings "I use my own brain, I do not read newspapers, they are troublemakers." You could see this coming - but he ends by mentioning his more liberal case against Canada Goose, who he has asked not to use fur or down. They hurt animals, but at least when the animals are dead "they can't be overcharged" and "at least they won't be going twice." Some reviewers have panned Morrissey for predictable lyrics, but he's stating his honest views and the songs on this album are catchy. I guess I'm a sucker.</p>
                          </BalanceText>
                        </div>

                        <div style={review_sub_title}>What Kind of People Live in These Houses?</div>
                        <div>
                          <BalanceText style={align_left}>
                            <p>The melody and singing and music sound like Crowded House on their last couple albums.</p>
                            <p>The song name is funny. You know he's not going to be praising these people. "They vote the way they vote, They don't know how to change" applies to both sides of the political divide. "Which carpet chewer lights up this sewer?" Sewer makes me think he's not looking at nice houses. I never heard the phrase "carpet chewer" before but it means someone who has lost emotional control - here he reminds me of Jack White who is also good at incorporating lesser used idioms into song. "They look at television thinking it's their window to the world. That's got to hurt." Sorry, that's a funny line. Is he being condescending? Perhaps the final line will answer the question: "Who cares what people live in these houses?" I do not share his sentiments but it doesn't stop me from laughing.</p>
                          </BalanceText>
                        </div>

                        <div style={review_sub_title}>Knockabout World</div>
                        <div>
                          <BalanceText style={align_left}>
                            <p>Everything in this song screams the 80s.</p>
                            <p>He's feeling sorry for himself. "They tried to turn you into a public target." But he still has self esteem and he throws in his usual specific along with it: "You're okay by me with your handsome teeth." This is the sort of thing critics will pan him for - playing the victim - but again the song sounds nice.</p>
                          </BalanceText>
                        </div>

                        <div style={review_sub_title}>Darling, I Hug a Pillow</div>
                        <div>
                          <BalanceText style={align_left}>
                            <p>The horn melody sounds a little Belle and Sebastian. The whole song sounds a little Belle and Sebastian.</p>
                            <p>This is the tale of someone who has the type of love which is the opposite of a respectless sex booty call: "Everything else is in place, except physical love." Based on everything we are told about love and how important it is to find real qualities in a partner, it almost seems secondary and unimportant to want physical love.</p>
                          </BalanceText>
                        </div>

                        <div style={review_sub_title}>Once I Saw the River Clean</div>
                        <div>
                          <BalanceText style={align_left}>
                            <p>Echo and the Bunnymen? Pet Shop Boys? Depeche Mode again? It's a catchy sound. A little Irish fiddle before mentioning Dublin.</p>
                            <p>The river clean may be his childhood innocence before things get polluted. "Childish mind anticipates, Grown up mind consummates." He relives walks with his grandmother - before he had much money - "With her fifty-two new pence...and my forty-five pence too." Nobody can ever go back, including his grandmother: "She would never be again a Dublin dancer, free and young." When people give street names I go to Google Maps to check them out. These streets are around Old Trafford. He mentions the money when walking down Chester Road which is a major road with shops. Talbot is more residential and he walks "in the gardens by the graves" but I see no cemetery on this road. Perhaps the gardens are gardens in front of the houses (graves for young souls!) </p>
                          </BalanceText>
                        </div>

                        <div style={review_sub_title}>The Truth About Ruth</div>
                        <div>
                          <BalanceText style={align_left}>
                            <p>A haunting piano (and harpsichord?) and a generally haunting sound. </p>
                            <p>"Ruth is John" tells me John doesn't want to be a man. "Some people fight just to take root in a world ill-equipped for the truth about Ruth." I guess transvestites hide their bulge somehow because "Your groin and your face, they slip right into place." Eventually he'll be accepted: "Sooner or later we are all calmed down." This seems very liberal to me despite his sometimes conservative stances. It's almost as though people can be multidimensional.</p>
                          </BalanceText>
                        </div>

                        <div style={review_sub_title}>The Secret of Music</div>
                        <div>
                          <BalanceText style={align_left}>
                            <p>This is slow, quiet, melodic song. A little experimental - it could be a later Blur song.</p>
                            <p>Suggestions of instruments in an orchestra are given to ease his troubled soul ("Pan pipes save a life"), but it sounds like he's down and music can not overcome it: "No angelic flute can F hold a harping soul?"</p>
                          </BalanceText>
                        </div>

                        <div style={review_sub_title}>My Hurling Days Are Done</div>
                        <div>
                          <BalanceText style={align_left}>
                            <p>A quiet song with a nice melody - the quiet parts sound like it could be a quiet early U2 song. A children's choir brings out the themes of reflecting on his earliest memories.</p>
                            <p>Like Ben Watt, who I reviewed earlier, he is getting older and he is not a fan of time. He is reflecting on childhood again (like he did on Once I Saw the River Clean): "Mama and teddy bear were the first full spectrum of time." Sometimes you look at your age and wonder if you've done enough with your life. Time doesn't come and grab you, you put pressure on yourself: "Time places no pressure on you but soon it explains what it means by the Funky Alfonso." Whut? I found a Facebook page about Funky Alfonso's French Alps Ski Resort - doesn't look like the page lasted very long and it's not on Google Maps. Frank Zappa mentioned the phrase in a song. Joe Chiccarelli produced this album and he produced some Frank Zappa albums but not the one mentioning Funky Alfonso. So I dunno - let's just agree that time itself places know pressure on you and forget about the Funky Alfonso thing.</p>
                          </BalanceText>
                        </div>


                        <div><i>Released: 20 March, 2020</i></div>


                      </Card.Text>
                    </Card.Body>
                  </Card>
              </Col>
            </Row>

          </Col>
    )
  }
}

const getNewsItem = (title, link) => {
  return (
    <a href = {link}><div class="newslinks">{title}</div></a>
  )
}

const mapStateToProps = (state, ownProps) => {
  // let page_num = ownProps.match.params.page_num;
  return {
    posts: state.posts,
    // page_num: page_num,
  }
}

export default connect(mapStateToProps)(MorrisseyIAmNotADogOnAChain)