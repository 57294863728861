import React, {Component} from 'react'
import {Link, Route, Switch} from 'react-router-dom'
import {connect} from 'react-redux'
import axios from 'axios'
import {HomeHelper} from "./HomeHelper"
import {getNewsItems} from "./NewsItems";
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Pagination from 'react-bootstrap/Pagination'
import Dropdown from 'react-bootstrap/Dropdown'
import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'
import concert from '../concert.jpg'
import guitarcloseup from '../guitar-closeup.jpg'
import guitarcloseup_bw from '../guitar-closeup-bw.jpg'
import guitars_bw from '../guitars-bw.jpg'
import newspapers from '../newspapers.jpg'
import the_beatles from '../the-beatles.jpg'
import benwattstormdamage from '../benwattstormdamage.jpg'
import '../index.css'
import BalanceText from 'react-balance-text'
import bccamplightshortlyaftertakeoff from "../bccamplightshortlyaftertakeoff.jpg";
import eobearth from "../eobearth.jpg";
import hmltdwestofeden from "../hmltdwestofeden.jpg";
import lauramarlingsongforourdaughter from "../lauramarlingsongforourdaughter.jpg";
import porridgeradioeverybad from "../porridgeradioeverybad.jpg";
import sparksasteadydripdripdrip from "../sparksasteadydripdripdrip.jpg";
import waxahatcheesaintcloud from "../waxahatcheesaintcloud.jpg";
import morrisseyiamnotadogonachain from "../morrisseyiamnotadogonachain.jpg";
import sorry925 from "../sorry925.jpg";
import thebigmoonwalkinglikewedo from "../thebigmoonwalkinglikewedo.jpg";
import badlydrawnboybananaskinshoes from "../badlydrawnboybananaskinshoes.jpg";
import thewantscontainer from "../thewantscontainer.jpg";
import PublicPracticeGentleGrip from "./PublicPracticeGentleGrip";
import publicpracticegentlegrip from "../publicpracticegentlegrip.jpg";


class Reviews3 extends Component {
  constructor() {
    super()
  }

  render() {
    const card_style = {
      // width: '18em',
      display: 'inline-block',
      margin: '1em',
    };

    const card_style_double = {
      // width: '38em',
      display: 'inline-block',
      margin: '1em',
    };

    const img_style = {
      height: '4em',
    };

    const img_style_double = {
      /* height: '8em', */
    };

    const home_button = {
      backgroundColor: '#555555',
    };

    const align_left = {
      textAlign: 'left',
    }

    const review_sub_title = {
      color: '#555555',
      fontWeight: 'bold',
      fontSize: '1em',
      margin: '15px 0px 3px 0px',
    }

    const card_link = {
      color: '#555555',
    }

    const readfull_button = {
      borderColor: '#777777',
      backgroundColor: '#777777',
    }

    return (
      <Row className="justify-content-md-center">
      <Col sm={12}>

        <Row>

          <Col sm={6}>
            <p>
              <a href="/reviews/badly-drawn-boy-banana-skin-shoes" style={card_link}>
                <Card style={card_style}>
                  <Card.Img variant="top" src={badlydrawnboybananaskinshoes} alt="Badly Drawn Boy Banana Skin Shoes album cover" style={img_style_double}/>
                  <Card.Body style={align_left}>
                    <Card.Title style={align_left}>Review: <i>Banana Skin Shoes</i> by Badly Drawn Boy</Card.Title>
                    <p><Button style={readfull_button}>Read Full Review</Button></p>
                    <Card.Text>
                      <BalanceText style={align_left}><b>Overall:</b>  The title song starts the album out with some fun and then styles from previous albums come back on later songs. He is always pleasant to listen to and this album is no different. There are always some melodies on his albums which stick in my head. He has cleaned up his act after getting remarried and ends by saying he wants to make his new wife happy. Hopefully he doesn't put pressure on himself and releases his next album sooner rather than later. </BalanceText>
                    </Card.Text>
                  </Card.Body>
                </Card>
              </a>
            </p>

            <p>
              <a href="/reviews/ben-watt-storm-damage" style={card_link}>
                <Card style={card_style}>
                  <Card.Img variant="top" src={benwattstormdamage} alt="ben watt storm damage album cover"
                            style={img_style_double}/>
                  <Card.Body style={align_left}>
                    <Card.Title style={align_left}><i>Storm Damage</i> by Ben Watt</Card.Title>
                    <p><Button style={readfull_button}>Read Full Review</Button></p>
                    <Card.Text>
                      <BalanceText style={align_left}><b>Overall:</b> Sounds like 21st century Neil Finn and Thom Yorke. He's getting close to 60 and covers distant memories, getting old and love changing over time. Despite those normally sad topics, I love it. Here's a description of the songs.</BalanceText>
                    </Card.Text>
                  </Card.Body>
                </Card>
              </a>
            </p>

            <p>
              <a href="/reviews/hmltd-west-of-eden" style={card_link}>
                <Card style={card_style}>
                  <Card.Img variant="top" src={hmltdwestofeden} alt="HMLTD west of eden album cover" style={img_style_double}/>
                  <Card.Body style={align_left}>
                    <Card.Title style={align_left}>Review: <i>West of Eden</i> by HMLTD</Card.Title>
                    <p><Button style={readfull_button}>Read Full Review</Button></p>
                    <Card.Text>
                      <BalanceText style={align_left}><b>Overall:</b> Henry Spychalski, in an interview with <a href="/reviewshttps://www.youtube.com/watch?v=v5gLdZkNNtc">Leeds Student Television</a> says that the band is just expressing themselves and not playing characters when they dress differently. If it's true they caught some slack for being heterosexual and appropriating LGBTQ culture, he's probably saying this to address such criticism.  I mention this because after watching the video for The West is Dead and Loaded and after looking at some press photos, my question is: These guys are heterosexual? God bless them.  If you want to be offended I have no problem with that. If you like it, that is fine too. Everyone can do what they want. This is a good album - seems greatly influenced by Franz Ferdinand. They look like Spiders From Mars Bowie or Roxy Music but don't sound like them.</BalanceText>
                    </Card.Text>
                  </Card.Body>
                </Card>
              </a>
            </p>


            <p>
              <a href="/reviews/morrissey-i-am-not-a-dog-on-a-chain" style={card_link}>
                <Card style={card_style}>
                  <Card.Img variant="top" src={morrisseyiamnotadogonachain} alt="morrissey i am not a dog on a chain album cover" style={img_style_double}/>
                  <Card.Body style={align_left}>
                    <Card.Title style={align_left}>Review: <i>I Am Not a Dog on a Chain</i> by Morrissey</Card.Title>
                    <p><Button style={readfull_button}>Read Full Review</Button></p>
                    <Card.Text>
                      <BalanceText style={align_left}><b>Overall:</b> My response to any political opinion is: "I'd rather they say it than keep it to themselves." I'm married to an immigrant so I probably don't agree with everything he stands for, but I don't ignore a pile of garbage if it's got diamonds in it. I like this album.</BalanceText>
                    </Card.Text>
                  </Card.Body>
                </Card>
              </a>
            </p>
            <Route exact path='/reviews/public-practice-gentle-grip' component={PublicPracticeGentleGrip}/>

            <p>
              <a href="/reviews/public-practice-gentle-grip" style={card_link}>
                <Card style={card_style}>
                  <Card.Img variant="top" src={publicpracticegentlegrip} alt="Public Practice Gentle Grip album cover" style={img_style_double}/>
                  <Card.Body style={align_left}>
                    <Card.Title style={align_left}>Review: <i>Gentle Grip</i> by Public Practice</Card.Title>
                    <p><Button style={readfull_button}>Read Full Review</Button></p>
                    <Card.Text>
                      <BalanceText style={align_left}><b>Overall:</b>  Bass - the bass is prevalent. I couldn't keep writing
                        about the bass so I just started out each review with "Bass" toward the end. These people are big
                        Talking Heads fans based on this album and the fact they are from NYC. But they do other styles too.
                        I dig this album. And I dig the bass.</BalanceText>
                    </Card.Text>
                  </Card.Body>
                </Card>
              </a>
            </p>

            <p>
              <a href="/reviews/sparks-a-steady-drip-drip-drip" style={card_link}>
                <Card style={card_style}>
                  <Card.Img variant="top" src={sparksasteadydripdripdrip} alt="Sparks A Steady Drip Drip Drip album cover" style={img_style_double}/>
                  <Card.Body style={align_left}>
                    <Card.Title style={align_left}>Review: <i>A Steady Drip Drip Drip</i> by Sparks</Card.Title>
                    <p><Button style={readfull_button}>Read Full Review</Button></p>
                    <Card.Text>
                      <BalanceText style={align_left}><b>Overall:</b>  This band has been around since the 60s and it shows - you can hear styles from the different decades on this. I hear a lot of Paul McCartney 70s music here and I hear a lot of 70s-80s art rock which Sparks themselves helped shape back then. Really there are a lot of different styles on this album. It ends with a pretty song about saving the environment for kids. </BalanceText>
                    </Card.Text>
                  </Card.Body>
                </Card>
              </a>
            </p>

            <p>
              <a href="/reviews/waxahatchee-saint-cloud" style={card_link}>
                <Card style={card_style}>
                  <Card.Img variant="top" src={waxahatcheesaintcloud} alt="Waxahatchee Saint Cloud album cover" style={img_style_double}/>
                  <Card.Body style={align_left}>
                    <Card.Title style={align_left}>Review: <i>Saint Cloud</i> by Waxahatchee</Card.Title>
                    <p><Button style={readfull_button}>Read Full Review</Button></p>
                    <Card.Text>
                      <BalanceText style={align_left}><b>Overall:</b>  She says she is influenced by Lucinda Williams and it shows but I hear other elements as well. This is on the whole a country album, but there are aspects that remind me of bands from the 90s. I feel like if Tanya Donnelly were from the South she may make an album like this.</BalanceText>
                    </Card.Text>
                  </Card.Body>
                </Card>
              </a>
            </p>


          </Col>






          <Col sm={6}>
            <p>
              <a href="/reviews/bc-camplight-shortly-after-takeoff" style={card_link}>
                <Card style={card_style}>
                  <Card.Img variant="top" src={bccamplightshortlyaftertakeoff}
                            alt="BC Camplight Shortly After Takeoff album cover" style={img_style_double}/>
                  <Card.Body style={align_left}>
                    <Card.Title style={align_left}><i>Shortly After Takeoff</i> by BC Camplight</Card.Title>
                    <p><Button style={readfull_button}>Read Full Review</Button></p>
                    <Card.Text>
                      <BalanceText style={align_left}><b>Overall:</b> A very pleasant album with a variety of sounds. I
                        hear bits of Sufjan Stevens, Ariel Pink, Yo La Tengo, Father John Misty and others. He can be
                        funny but he also addresses some sadness instead of running away from it. He also talks about
                        everyday things like shopping malls, Scottish soda and radiators.</BalanceText>
                    </Card.Text>
                  </Card.Body>
                </Card>
              </a>
            </p>

            <p>
              <a href="/reviews/eob-earth" style={card_link}>
                <Card style={card_style}>
                  <Card.Img variant="top" src={eobearth} alt="EOB earth album cover" style={img_style_double}/>
                  <Card.Body style={align_left}>
                    <Card.Title style={align_left}>Review: <i>Earth</i> by EOB</Card.Title>
                    <p><Button style={readfull_button}>Read Full Review</Button></p>
                    <Card.Text>
                      <BalanceText style={align_left}><b>Overall:</b>  Yes his Radiohead background definitely pokes through but he has his own unique sound as well. A few times throughout the album it sounds like U2's Pop, which was not one of my favorite albums at the time but it grew on me, and other times he's far from that and just playing acoustic like some Badly Drawn Boy songs - something you do not think of when you think of Radiohead. This is a pleasant album.</BalanceText>
                    </Card.Text>
                  </Card.Body>
                </Card>
              </a>
            </p>

            <p>
              <a href="/reviews/laura-marling-song-for-our-daughter" style={card_link}>
                <Card style={card_style}>
                  <Card.Img variant="top" src={lauramarlingsongforourdaughter} alt="Song For Our Daughter by Laura Marling album cover" style={img_style_double}/>
                  <Card.Body style={align_left}>
                    <Card.Title style={align_left}>Review: <i>Song For Our Daughter</i> by Laura Marling</Card.Title>
                    <p><Button style={readfull_button}>Read Full Review</Button></p>
                    <Card.Text>
                      <BalanceText style={align_left}><b>Overall:</b>  While not a mother herself, she is singing to an idea of a young person, like Maya Angelou writing to a ficticious daughter in "Letters to My Daughter." The songs throughout the album are pleasant and catchy and she reminds me of many American singers (Amy Mann, Chrissy Hynde, Lucinda Williams, Michelle Shocked). Some of these songs strangely could easily be Travis (the Scottish band, not country singer) songs.</BalanceText>
                    </Card.Text>
                  </Card.Body>
                </Card>
              </a>
            </p>

            <p>
              <a href="/reviews/porridge-radio-every-bad" style={card_link}>
                <Card style={card_style}>
                  <Card.Img variant="top" src={porridgeradioeverybad} alt="porridge radio every bad album cover" style={img_style_double}/>
                  <Card.Body style={align_left}>
                    <Card.Title style={align_left}>Review: <i>Ever Bad</i> by Porridge Radio</Card.Title>
                    <p><Button style={readfull_button}>Read Full Review</Button></p>
                    <Card.Text>
                      <BalanceText style={align_left}><b>Overall:</b>  Dana Margolin is confused and lets us know - and I don't think enough people admit they were "born confused." Nobody knows what's coming next - things are complicated. This is a great album and I hear a lot of Robert Smith when I hear her sing, but there are other influences and many original sounds.</BalanceText>
                    </Card.Text>
                  </Card.Body>
                </Card>
              </a>
            </p>

            <p>
              <a href="/reviews/sorry-925" style={card_link}>
                <Card style={card_style}>
                  <Card.Img variant="top" src={sorry925} alt="sorry 925 album cover" style={img_style_double}/>
                  <Card.Body style={align_left}>
                    <Card.Title style={align_left}>Review: <i>925</i> by Sorry</Card.Title>
                    <p><Button style={readfull_button}>Read Full Review</Button></p>
                    <Card.Text>
                      <BalanceText style={align_left}><b>Overall:</b> Asha Lorenz and Louis O'Bryen say they are influenced by Elliott Smith and Radiohead and to some degree Nirvana, and I think Bettie Serveert is their soulmate, but still their combinations are unique and they have a great sound. Their lyrics often revolve around being the lover instead of the loved, but I love this album, so they are loved after all. </BalanceText>
                    </Card.Text>
                  </Card.Body>
                </Card>
              </a>
            </p>

            <p>
              <a href="/reviews/the-big-moon-walking-like-we-do" style={card_link}>
                <Card style={card_style}>
                  <Card.Img variant="top" src={thebigmoonwalkinglikewedo} alt="The Big Moon Walking Like We Do album cover" style={img_style_double}/>
                  <Card.Body style={align_left}>
                    <Card.Title style={align_left}>Review: <i>Walking Like We Do</i> by The Big Moon</Card.Title>
                    <p><Button style={readfull_button}>Read Full Review</Button></p>
                    <Card.Text>
                      <BalanceText style={align_left}><b>Overall:</b>  Many songs end with repeating harmonies and little riffs - I like how the songs end. I hear hints of Beach House and Bird of Youth. There are other bands that pop up as well as occasional dips into the 80s. Pleasant album.</BalanceText>
                    </Card.Text>
                  </Card.Body>
                </Card>
              </a>
            </p>

            <p>
              <a href="/reviews/the-wants-container" style={card_link}>
                <Card style={card_style}>
                  <Card.Img variant="top" src={thewantscontainer} alt="The Wants Container album cover" style={img_style_double}/>
                  <Card.Body style={align_left}>
                    <Card.Title style={align_left}>Review: <i>Container</i> by The Wants</Card.Title>
                    <p><Button style={readfull_button}>Read Full Review</Button></p>
                    <Card.Text>
                      <BalanceText style={align_left}><b>Overall:</b>  This album takes you through a trip into a factory perhaps in the future - a lot of songs give an industrial feeling with no lyrics - other songs harken to Talking Heads and other post punk bands - a lot of 80s influence. They have a good feel for coming up with grooves that sound pleasant. </BalanceText>
                    </Card.Text>
                  </Card.Body>
                </Card>
              </a>
            </p>



          </Col>








         </Row>
      </Col>
      </Row>
    )
  }
}

const getNewsItem = (title, link) => {
  return (
    <a href = {link}><div class="newslinks">{title}</div></a>
  )
}

const mapStateToProps = (state, ownProps) => {
  // let page_num = ownProps.match.params.page_num;
  return {
    posts: state.posts,
    // page_num: page_num,
  }
}

export default connect(mapStateToProps)(Reviews3)